import React, { Component } from 'react';

// React-table library requires to import css as well:
import ReactTable from 'react-table';
import 'react-table/react-table.css'; // eslint-disable-next-line

import Container from '@material-ui/core/Container';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import HighlightOff from '@material-ui/icons/HighlightOff';

import SpinnerComponent from '../../../../components/SpinnerComponent';

import PropTypes from 'prop-types';
import theme from '../../../../theme.js';
import { CancelRounded } from '@material-ui/icons';

const styles = {
    container: {
        maxWidth: '800px',
        //minHeight: '400px' //da rivedere
    },
    gridcontainer: {
        padding: '20px 0px'
    },
    accordioncontainer: {
        padding: '0px 0px 10px 0px',
    },
    tablecontainer: {
        padding: '20px 0px'
    },
    inputfield: {
        marginBottom: '20px',
        width: '276px'
    },
    controllicause_container: {
        border: '1px solid lightgray',
        borderRadius: '4px',
        margin: '4px 0px',
        padding: '8px 0px'
    }
}

export default class ControlliNonConformita extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            disableButtonControlloCausa: true,
            disableButtonAzioneCorrettiva: true,
            //expandedAccordion: false,
            nuovoControlloNonConformita: {
                id: null,
                controlloConformitaId: null,
                limite: null,
                controlliCause: [
                    {
                        limite: null,
                        limiteValidationError: '',
                        azioniCorrettive: [
                            {
                                descrizione: null,
                                descrizioneValidationError: ''
                            }
                        ]
                    }
                ]
            },
            validationErrors: {
                limite: ''
            }
        }
    }

    componentDidMount() {
        
        if (this.props.controlloConformitaId !== null) {
            let nuovoControlloNonConformita = this.state.nuovoControlloNonConformita;
            nuovoControlloNonConformita.controlloConformitaId = this.props.controlloConformitaId;
            this.setState({
                nuovoControlloNonConformita
            });
        }
        // } else {
            
        //     const arrayTabella = this.createTableArray(this.props.controlliNonConformita);
        //     this.setState({
        //         arrayTabella
        //     });


        // }
        
    }

    removeNullAzioniCorrettive = (controlloNonConformita) => {

        let controllicause = controlloNonConformita.controlliCause;
        for (let i=0; i<controllicause.length; i++) {

            const azioni = controllicause[i].azioniCorrettive;
            const result = azioni.filter(azione => azione.descrizione !== null && azione.descrizione.length > 0);
            controllicause[i].azioniCorrettive = result;

        }
        //controlloNonConformita.controlliCause = controllicause;
        return controlloNonConformita;

    }

    removeNullControlliCause = (controlloNonConformita) => {

        let controlli = controlloNonConformita.controlliCause;
        const result = controlli.filter(controllo => controllo.azioniCorrettive.length >0 || (controllo.limite !== null && controllo.limite.length >0));
        controlloNonConformita.controlliCause = result;
        return controlloNonConformita;

    }

    validateField = (key, value) => {
        let validationErrors = this.state.validationErrors;
        switch (key) {
            case "limite":
                if (value === null || value.length === 0) {
                    validationErrors.limite = "Inserire almeno un carattere";
                } else {
                    const tableRecords = this.props.controlliNonConformita;
                    let samelimite = false;
                    for (let i=0; i<tableRecords.length; i++) {
                        if (tableRecords[i].limite === value) {
                            samelimite = true;
                            break;
                        }
                    }
                    if (samelimite) {
                        validationErrors.limite = "Esiste già un controllo di non conformità con lo stesso limite.";
                    } else {
                        validationErrors.limite = "";
                    }
                }
                break;
            default:
                break;
        }
        this.setState({
            validationErrors
        });
    }
    
    addControlloNonConformita = () => {
    
        let nuovoControlloNonConformita = this.state.nuovoControlloNonConformita;
        this.validateField('limite', nuovoControlloNonConformita.limite);

        let valid = true;
        let validationErrors = this.state.validationErrors;
        Object.values(validationErrors).forEach(val => {
            val.length > 0 && (valid = false);
        });

        if (valid) {

            this.resetNuovoControlloNonConformita();
            
            let controllononconformita = this.removeNullAzioniCorrettive(nuovoControlloNonConformita);
            controllononconformita = this.removeNullControlliCause(controllononconformita);
            this.props.onAggiungiButtonClicked(controllononconformita);
            
        }
    
    }

    resetNuovoControlloNonConformita = () => {
        
        const nuovoControlloNonConformita = {
            id: null,
            controlloConformitaId: null,
            limite: null,
            controlliCause: [
                {
                    limite: null,
                    limiteValidationError: '',
                    azioniCorrettive: [
                        {
                            descrizione: null,
                            descrizioneValidationError: ''
                        }
                    ]
                }
            ]
        };

        this.setState({
            nuovoControlloNonConformita
        });

    }

    handleChangeControlloNonConformita = (event) => {

        let nuovoControlloNonConformita = this.state.nuovoControlloNonConformita;
        nuovoControlloNonConformita[event.target.name] = event.target.value;
        this.setState({
            nuovoControlloNonConformita
        });
        this.validateField(event.target.name, event.target.value);

    } 

    addControlloCausa = () => {
        
        let nuovoControlloNonConformita = this.state.nuovoControlloNonConformita;
        let controllicause = nuovoControlloNonConformita.controlliCause;
        const controllocausa = {
            limite: null,
            limiteValidationError: '',
            azioniCorrettive: [
                {
                    descrizione: null,
                    descrizioneValidationError: ''
                }
            ]
        }
        controllicause.push(controllocausa);
        nuovoControlloNonConformita.controlliCause = controllicause;
        this.setState({
            nuovoControlloNonConformita,
            disableButtonControlloCausa: true
        });

    }

    addAzioneCorrettiva = (event, indexControlloCausa) => {

        let nuovoControlloNonConformita = this.state.nuovoControlloNonConformita;
        let controllicause = nuovoControlloNonConformita.controlliCause;
        for (let i=0; i<controllicause.length; i++) {
            if (i === indexControlloCausa) {
                const nuovaAzione =  {
                    descrizione: '',
                    descrizioneValidationError: ''
                };
                let azioni = controllicause[i].azioniCorrettive;
                azioni.push(nuovaAzione);
                controllicause[i].azioniCorrettive = azioni
                break;
            }
        }
        nuovoControlloNonConformita.controlliCause = controllicause;
        this.setState({
            nuovoControlloNonConformita
        });

    }

    handleChangeControlloCausa = (event, index) => {

        let nuovoControlloNonConformita = this.state.nuovoControlloNonConformita;
        let value = event.target.value;
        nuovoControlloNonConformita.controlliCause[index].limite = value;
        this.setState({
            nuovoControlloNonConformita
        });
        this.validateControlliCause();

    }

    validateControlliCause = () => {

        let isValid = true;
        let nuovoControlloNonConformita = this.state.nuovoControlloNonConformita;
        let controlli = nuovoControlloNonConformita.controlliCause;
        for (let i=0; i<controlli.length; i++) {
            if (controlli[i].limite === null || controlli[i].limite === '' || controlli[i].limite.trim().length === 0) {
                controlli[i].limiteValidationError = 'Inserire almeno un carattere';
                isValid = false;
            } else {
                controlli[i].limiteValidationError = '';
            }
        }
        nuovoControlloNonConformita.controlliCause = controlli;
        this.setState({
            nuovoControlloNonConformita,
            disableButtonControlloCausa: !isValid
        });

    }

    handleChangeAzioneCorrettiva = (event, indexControlloCausa, indexAzioneCorrettiva) => {

        let nuovoControlloNonConformita = this.state.nuovoControlloNonConformita;
        let controlliCause = nuovoControlloNonConformita.controlliCause;
        for (let i=0; i<controlliCause.length; i++) {
            if (i === indexControlloCausa) {
                let azioni = controlliCause[i].azioniCorrettive;
                for (let j=0; j<azioni.length; j++) {
                    if (j === indexAzioneCorrettiva) {
                        azioni[j].descrizione = event.target.value;
                        break;
                    }
                }
                controlliCause[i].azioniCorrettive = azioni;
                break;
            }
        }
        nuovoControlloNonConformita.controlliCause = controlliCause;
        this.setState({
            nuovoControlloNonConformita
        });
        
        this.validateAzioniCorrettive();

    }

    validateAzioniCorrettive = () => {

        let isValid = true;
        let nuovoControlloNonConformita = this.state.nuovoControlloNonConformita;
        let controlli = nuovoControlloNonConformita.controlliCause;
        for (let i=0; i<controlli.length; i++) {

            let azioni = controlli[i].azioniCorrettive;
            for (let j=0; j<azioni.length; j++) {
                if (azioni[j].descrizione === null || azioni[j].descrizione === '' || azioni[j].descrizione.trim().length === 0) {
                    azioni[j].descrizioneValidationError = 'Inserire almeno un carattere';
                    isValid = false;
                } else {
                    azioni[j].descrizioneValidationError = '';
                }
            }
            controlli[i].azioniCorrettive = azioni;

        }
        nuovoControlloNonConformita.controlliCause = controlli;
        this.setState({
            nuovoControlloNonConformita,
            disableButtonAzioneCorrettiva: !isValid
        });

    }

    render () {

        const columns = [
            {
                Header: 'Indice',
                id: 'indice',
                accessor: d => d.indice,
                show: false
            },
            {
                Header: 'Id',
                id: 'id',
                accessor: d => d.id,
                show: false
            },
            {
                Header: 'Limite di non conformità',
                id: 'limite',
                accessor: d => d.limite,
                minWidth: 140,
                Cell: props =>
                    props.value === null ? '': props.value 
            },
            {
                Header: 'Controllo causa',
                id: 'limiteCausa',
                accessor: d => d.limiteCausa,
                minWidth: 140,
                Cell: props =>
                    props.value === null ? '': props.value 
            },
            {
                Header: 'Azione correttiva',
                id: 'descrizioneAzione',
                accessor: d => d.descrizioneAzione,
                minWidth: 140,
                Cell: props =>
                    props.value === null ? '': props.value 
            },
            {
                id: 'eliminaBtn',
                Header: 'Elimina',
                accessor: row => row,
                width: 80,
                Cell: props =>
                    <IconButton
                        size="small" 
                        disabled = {false}
                        style={{ 
                            color: 'red',
                        }}
                        onClick={() => {this.props.onDeleteButtonClicked(props.value)}}
                    >
                        <CancelRounded/>
                    </IconButton>
            }
        ];
        return(
            <Container style={styles.container}>
                { this.state.loading ?
                    <SpinnerComponent size={32} />
                    : 
                    <Grid container justify="center" style={styles.gridcontainer} >
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.accordioncontainer}>
                            <Accordion expanded={this.props.expandedAccordion} onChange={this.props.onChangeAccordion} >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    id="accordion-header"
                                >
                                    <div>
                                        <Typography variant='body1' >Nuovo controllo di non conformità</Typography>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container justify="center" alignItems='center' >
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <TextField
                                                label='Limite non conformità'
                                                value={this.state.nuovoControlloNonConformita.limite === null ? '': this.state.nuovoControlloNonConformita.limite}
                                                name='limite'
                                                //variant="outlined"
                                                style={styles.inputfield}
                                                onChange={this.handleChangeControlloNonConformita}
                                                helperText={this.state.validationErrors.limite}
                                                error={this.state.validationErrors.limite.length > 0 ? true : false}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        { this.state.nuovoControlloNonConformita.controlliCause.map((value, index) => (
                                            <Grid container justify="center" alignItems='center' key={index} style={styles.controllicause_container} >
                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <TextField
                                                        label='Causa di non conformità'
                                                        value={value.limite === null ? '': value.limite}
                                                        name='limite'
                                                        //variant="outlined"
                                                        style={styles.inputfield}
                                                        onChange={(event) => { this.handleChangeControlloCausa(event, index) }}
                                                        // helperText={value.limiteValidationError}
                                                        // error={value.limiteValidationError.length > 0 ? true : false}
                                                    />
                                                </Grid>
                                                { value.azioniCorrettive.map((azioneCorrettiva, indexAzione) => (
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={indexAzione} index={indexAzione}>
                                                        <TextField
                                                            label='Azione correttiva'
                                                            value={azioneCorrettiva.descrizione === null ? '': azioneCorrettiva.descrizione}
                                                            name='descrizione'
                                                            //variant="outlined"
                                                            style={styles.inputfield}
                                                            onChange={(event) => { this.handleChangeAzioneCorrettiva(event, index, indexAzione) }}
                                                        />
                                                    </Grid>
                                                ))
                                                }
                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <Button 
                                                        variant="contained"
                                                        size="small"
                                                        disabled={this.state.disableButtonAzioneCorrettiva}
                                                        style={{
                                                            color: this.state.disableButtonAzioneCorrettiva? '#e0e0e0' : '#194074', 
                                                            margin: '10px',
                                                            borderRadius:30,
                                                            backgroundColor: 'white'
                                                        }}
                                                        onClick={(event) => this.addAzioneCorrettiva(event, index)}
                                                    >
                                                        Aggiungi azione correttiva
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                            ))
                                        }
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Button 
                                                variant="contained"
                                                size="small"
                                                disabled={this.state.disableButtonControlloCausa}
                                                style={{
                                                    color: theme.palette.secondary.main,
                                                    margin: '10px',
                                                    borderRadius:30,
                                                    backgroundColor: this.state.disableButtonControlloCausa? theme.palette.disabled.main :  theme.palette.utilPalette.primary,
                                                }}
                                                onClick={() => this.addControlloCausa()}
                                            >
                                                Aggiungi controllo causa
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                                <Divider />
                                <AccordionActions>
                                    <Button 
                                        size="small"
                                        onClick={this.addControlloNonConformita}
                                        variant="contained"
                                        disabled={false}
                                        style={{
                                            color: theme.palette.secondary.main,
                                            margin: '10px',
                                            borderRadius:30,
                                            backgroundColor: theme.palette.utilPalette.primary,
                                        }}
                                    >
                                        Aggiungi
                                    </Button>
                                </AccordionActions>
                            </Accordion>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.tablecontainer}>
                            <ReactTable
                                filterable={false}
                                resizable={true}
                                showPageSizeOptions={true}
                                showPageJump={true}
                                defaultPageSize={5}
                                //pages={this.state.pages}
                                data={this.props.controlliNonConformita}
                                columns={columns}
                                //manual // informs React Table that you'll be handling sorting and pagination server-side
                                //onFetchData={ (state, instance) => { this.fetchData(state.page, state.pageSize) } }
                                previousText='Precedente'
                                nextText='Successivo'
                                noDataText='Nessun record'
                                pageText='Pagina'
                                ofText='di'
                                rowsText='righe'
                                pageJumpText='Vai a pagina'
                                rowsSelectorText='righe per pagina'
                            />
                        </Grid>
                    </Grid>                        
                }
            </Container>
        );
    }

}

ControlliNonConformita.propTypes = {
    controlliNonConformita: PropTypes.array.isRequired,
    controlloConformitaId: PropTypes.number,
    expandedAccordion: PropTypes.bool.isRequired,
    onChangeAccordion: PropTypes.func.isRequired,
    onAggiungiButtonClicked: PropTypes.func.isRequired,
    onDeleteButtonClicked: PropTypes.func.isRequired
}