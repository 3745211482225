import React from 'react';
import Button from "@material-ui/core/Button";
import PropTypes from 'prop-types';
import theme from '../theme.js';

export default function ActionButton(props) {
    return (
        <Button
            type="button"
            onClick={props.onClick}
            variant="contained"
            size="medium"
            disabled={props.disabled}
            style={{
                color: theme.palette.secondary.main, 
                margin: '10px',
                float:props.isRight ? 'right' : 'none',
                borderRadius:30,
                backgroundColor: props.disabled ? 
                theme.palette.disabled.main
                :
                (props.grayVersion? theme.palette.secondary.other : props.isGreen ? theme.palette.utilPalette.secondary : theme.palette.utilPalette.primary)
            }}
        >
        {props.label}
        </Button>
    );
}

ActionButton.propTypes = {
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    grayVersion: PropTypes.bool.isRequired,
}
