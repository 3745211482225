import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import PropTypes from 'prop-types';
import theme from '../../../theme.js';

const DialogTitle = withStyles(theme => ({
    root: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
  }))(props => {
    const { children, classes, onClose } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={onClose}
          >
            {" "}
            <CloseIcon />{" "}
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
});
  
const DialogContent = withStyles(theme => ({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    }
  }))(MuiDialogContent);
  
const DialogActions = withStyles(theme => ({
    root: {
      borderTop: `1px solid ${theme.palette.divider}`,
      margin: 0,
      padding: theme.spacing(2)
    }
  }))(MuiDialogActions);


export default function UserActivationDialog (props) {

  return(
    <Dialog
        open={ props.isOpen }
        onClose={ () => { props.onButtonClicked(false) } }
    >
      <DialogTitle>{props.isActivatingUser? "Attivazione utente" : "Disattivazione utente"}</DialogTitle>
      <DialogContent>
          <DialogContentText >
            { props.isActivatingUser ? 
              "Abilitare l'utente ad accedere al servizio BS-UTILITY?"
              :
              "Disattivare l'utente?  Attenzione: cliccando su OK l'utente non sarà più autorizzato ad accedere al servizio BS-UTILITY."
            }
          </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={ () => { props.onButtonClicked(true)} } style={{ color: theme.palette.utilPalette.primary }}>
          OK
        </Button>
        <Button onClick={ () => { props.onButtonClicked(false)} } style={{ color: theme.palette.utilPalette.secondary }}>
          Indietro
        </Button>
      </DialogActions>
    </Dialog>
  );

}

UserActivationDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isActivatingUser: PropTypes.bool.isRequired,
  onButtonClicked: PropTypes.func.isRequired
}