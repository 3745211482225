import React, { Fragment } from "react";
import { getManuale } from "../../../utils/api/modelli_and_manuali_api";
import { Box, Button, Checkbox, Collapse, Grid, Icon, IconButton, List, ListItem, TableContainer, TableHead, TextField, Typography } from "@material-ui/core";
import { Add, ArrowDownward, ArrowUpward, ChevronRight, Close, ControlPoint, Delete, Edit, ExpandLess, ExpandMore } from "@material-ui/icons";
import { Table, TableBody, TableCell, TableRow } from "@material-ui/core"
import moment from "moment";
import { retrieveToken, clearToken } from "../../../utils/storage";
import { deletePartiManuale, getIndice, getPartiManuale, postAggiuntaParteManuale, putPartiManuale, putSortingIndice } from "../../../utils/api/parti_api";
import theme from "../../../theme.js";
import ActionButton from "../../../components/ActionButton";
import ErrorDialog from "../../../components/ErrorDialog";
import SpinnerComponent from "../../../components/SpinnerComponent";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { deleteCapitoli, postCreaCapitoli, putCapitoli } from "../../../utils/api/capitoli_api";
import { deleteParagrafoFromManualeConParti, deleteSottoparagrafoFromManualeConParti, postAggiuntaParagrafoManualeConParti, postAggiuntaSottoparagrafoManualeConParti } from "../../../utils/api/paragrafi_api";

const styles = {
    mainContainer: {
      textAlign: "center",
      color: "black",
      padding: "26px 10px",
      minHeight: "90vh",
    },
}

function createDataForCapitoli(numero,titolo,base,sv,ul,area1,area2,daEditare,data,button) {
    return { numero,titolo,base,sv,ul,area1,area2,daEditare,data,button };
}
function createDataForParagrafi(numero,titolo,base,sv,ul,area1,area2,daEditare,data,button){
    return { numero,titolo,base,sv,ul,area1,area2,daEditare,data,button}
}
function createDataForSottoparagrafi(numero,titolo,base,sv,ul,area1,area2,daEditare,data,button){
    return { numero,titolo,base,sv,ul,area1,area2,daEditare,data,button}
}
function swapElements(arr, i1, i2) {
  let temp = arr[i1];

  arr[i1] = arr[i2];

  arr[i2] = temp;
}
function sortAllElements(arr){
  arr.map((element,index) => {
    element.posizione = index+1;
  })
}
function sortAllElementsForSottoparagrafi(arr){
  arr.map((element,index) => {
    element.posizione = index+1;
  })
}
export const Component = withRouter(({ history, location }) =>{
});


class CreazionePartiNuovoManuale extends React.Component{
    constructor(props){
        super(props);
        this.state={
            manualeData: {
                id:0,
                parti:[{
                  id:0,
                  posizione:0,
                  titolo:"",
                  manualeId:this.props.id,
                  isTitoloEditable:false,
                  capitoli:[],
                  isSelectedForPDF:false,
                }],
                titolo:"",
                isTabOpen:false,
            },
            firstRefresh:true,
            errorDialogVisible: false,
            errorDialogMessage: "",
            errorDialogTitle: null,
            activateMovement:false,
            loading:true,
            loadingChapters:false,
            loadingParagraph:false,
            loadingSubParagraph:false,
        }
    }

    componentDidMount() {
        this.getManualeData();
        if (this.state.firstRefresh) {
            this.handleRefresh();
          }
    }

    handleRefresh = () => {
        this.setState({ firstRefresh: false });
    };

    handleInvalidToken = () => {
      this.handleError(
        true,
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi."
      );
      clearToken();
      window.setTimeout(function () {
        window.location.href = "/";
      }, 4000);
    };
    handleError = (showModal, errorMessage) => {
      this.setState({
        errorDialogVisible: showModal,
        errorDialogMessage: errorMessage,
      });
    };
    closeErrorDialog = () => {
      this.setState({
        errorDialogVisible: false,
      });
    };

    saveParts = () => {
      let statusOfIndex = this.state.manualeData;
      let bodyToSave = {
        manualeId: this.props.manualeId,
        parti:[],
      };
      statusOfIndex.parti.map((parte,index1) => {
        let parteToPush = {
          id:parte.id,
          capitoli:[],
        };
        parte.capitoli.map((capitolo,index2) => {
          let capitoloToPush = {
            id:capitolo.id,
            paragrafi:[],
          };
          capitolo.paragrafi.map((paragrafo,index3) => {
            let paragrafoToPush = {
              id:paragrafo.id,
              componenti:[],
            };
            paragrafo.sottoparagrafi.map((sottoparagrafo,index4) => {
              paragrafoToPush.componenti.push(sottoparagrafo.id);
            })
            capitoloToPush.paragrafi.push(paragrafoToPush);
          })
          parteToPush.capitoli.push(capitoloToPush);
        })
        bodyToSave.parti.push(parteToPush);
      })
      putSortingIndice(bodyToSave).then((response) => {
        this.handleMovementActivation();
      }).catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.handleError(true, error.message);
        }
      })
      
    //this.getManualeData();
    }
  handleMovementActivation = () => {
    this.setState({
      activateMovement:!this.state.activateMovement
    })
  }

    getManualeData = () => {
      let manualeData = {};
        getIndice(this.props.manualeId).then((response) => {
          manualeData = {
            id:response.manualeId,
            parti:[],
            titolo:response.titolo,
            isTabOpen:false,
          }
          response.parti.map((parteToInsert,index) => {
            let parte = {
              id:parteToInsert.id,
              posizione:parteToInsert.indiceOrdinamento,
              titolo:parteToInsert.titolo,
              manualeId:parteToInsert.manualeId,
              isTitoloEditable:false,
              capitoli:[],
              isSelectedForPDF:false,
            }
            parteToInsert.capitoli.map((capitoliToInsert,index) => {
              let capitolo = {
                id:capitoliToInsert.id,
                titolo:capitoliToInsert.titolo,
                parteId:capitoliToInsert.parteId,
                base:0,
                posizione:capitoliToInsert.numeroCapitolo,
                sv:0,
                ul:0,
                area1:0,
                area2:0,
                daEditare:false,
                isDataEditable:false,
                isTabOpen:false,
                dataEditing:Date.now(),
                paragrafi:[],
                isSelectedForPDF:false,
            }
            capitoliToInsert.paragrafi.map((paragrafiToInsert,index) => {
              let paragrafo = {
                id:paragrafiToInsert.id,
                base:0,
                isBloccato:paragrafiToInsert.bloccato,
                capitoloId:paragrafiToInsert.capitoloId,
                posizione:paragrafiToInsert.numeroParagrafo,
                isObbligatorio:paragrafiToInsert.obbligatorio,
                sv:0,
                ul:0,
                area1:0,
                area2:0,
                dataEditing:Date.now(),
                isTabOpen:false,
                titolo:paragrafiToInsert.titolo,
                isSelectedForPDF:false,
                sottoparagrafi:[],
            }
            paragrafiToInsert.componenti.map((sottoparagrafiToInsert,index) => {
              let sottoparagrafo= {
                id:sottoparagrafiToInsert.id,
                base:0,
                posizione:sottoparagrafiToInsert.numeroComponente,
                paragrafoId:sottoparagrafiToInsert.paragrafoId,
                sv:0,
                ul:0,
                area1:0,
                area2:0,
                titolo:sottoparagrafiToInsert.titolo,
                tipologia:sottoparagrafiToInsert.tipoContenuto,
                isSelectedForPDF:false,
                dataEditing:Date.now(),
            }
            paragrafo.sottoparagrafi.push(sottoparagrafo);
            })
            capitolo.paragrafi.push(paragrafo);
            })
            parte.capitoli.push(capitolo);
            })
            manualeData.parti.push(parte);
          })
          //console.log(response);
          //getPartiManuale(this.props.manualeId,0,999,"indiceOrdinamento","ASC").then((response) => {
           
          /*let capitolo = {
              id:1,
              titolo:"Test capitolo numero 1",
              base:0,
              posizione:1,
              sv:0,
              ul:0,
              area1:0,
              area2:0,
              daEditare:false,
              isTabOpen:false,
              dataEditing:Date.now(),
              paragrafi:[],
              isSelectedForPDF:false,
          }
          let capitolo1 = {
              id:2,
              titolo:"Test capitolo numero 2",
              base:0,
              posizione:2,
              sv:0,
              ul:0,
              area1:0,
              area2:0,
              daEditare:false,
              isTabOpen:false,
              dataEditing:Date.now(),
              paragrafi:[],
              isSelectedForPDF:false,
          }
          let paragrafo = {
              id:1,
              base:0,
              posizione:1,
              sv:0,
              ul:0,
              area1:0,
              area2:0,
              dataEditing:Date.now(),
              isTabOpen:false,
              titolo:"Test paragrafo",
              isSelectedForPDF:false,
              sottoparagrafi:[],
          }
          let paragrafo1 = {
            id:2,
            base:0,
            posizione:2,
            sv:0,
            ul:0,
            area1:0,
            area2:0,
            dataEditing:Date.now(),
            isTabOpen:false,
            titolo:"Test paragrafo 1",
            isSelectedForPDF:false,
            sottoparagrafi:[],
        }
        let paragrafo2 = {
          id:3,
          base:0,
          posizione:3,
          sv:0,
          ul:0,
          area1:0,
          area2:0,
          dataEditing:Date.now(),
          isTabOpen:false,
          titolo:"Test paragrafo 2",
          isSelectedForPDF:false,
          sottoparagrafi:[],
      }
          let sottoparagrafo= {
              id:1,
              base:0,
              posizione:1,
              sv:0,
              ul:0,
              area1:0,
              area2:0,
              titolo:"Test sottoparagrafo",
              tipologia:"Testo",
              isSelectedForPDF:false,
              dataEditing:Date.now(),
          }
          let sottoparagrafo2= {
            id:2,
            base:0,
            posizione:2,
            sv:0,
            ul:0,
            area1:0,
            area2:0,
            titolo:"Test sottoparagrafo 2",
            tipologia:"Testo",
            isSelectedForPDF:false,
            dataEditing:Date.now(),
        }
        manualeData.parti.push(parte);
        parte.capitoli.push(capitolo);
        parte.capitoli.push(capitolo1);
        capitolo.paragrafi.push(paragrafo);
        capitolo.paragrafi.push(paragrafo1);
        capitolo1.paragrafi.push(paragrafo2);
        paragrafo.sottoparagrafi.push(sottoparagrafo);
        paragrafo.sottoparagrafi.push(sottoparagrafo2);
        paragrafo2.sottoparagrafi.push(sottoparagrafo);*/
        this.setState({
            manualeData: manualeData,
            loading:false,
        },() => {
            
        })
          }).catch((error) => {
            if (error.status === 403) {
              this.handleInvalidToken();
            } else {
              this.handleError(true, error.message);
            }
          })
        /*}).catch((error) => {
          if (error.status === 403) {
            this.handleInvalidToken();
          } else {
            this.handleError(true, error.message);
          }
        })*/
    }

    getManualeDataAfterSave = (indexParte,indexCapitolo,indexParagrafo,indexSottoparagrafo) => {
     // console.log(indexParte+" "+indexCapitolo+" "+indexParagrafo+" "+indexSottoparagrafo);
      let manualeData = {};
        getIndice(this.props.manualeId).then((response) => {
          manualeData = {
            id:response.manualeId,
            parti:[],
            titolo:response.titolo,
            isTabOpen:false,
          }
          response.parti.map((parteToInsert,index) => {
            let parte = {
              id:parteToInsert.id,
              posizione:parteToInsert.indiceOrdinamento,
              titolo:parteToInsert.titolo,
              manualeId:parteToInsert.manualeId,
              isTitoloEditable:false,
              capitoli:[],
              isSelectedForPDF:false,
            }
            if(index === indexParte){
              parte.isTabOpen = true;
            }
            parteToInsert.capitoli.map((capitoliToInsert,index2) => {
              let capitolo = {
                id:capitoliToInsert.id,
                titolo:capitoliToInsert.titolo,
                parteId:capitoliToInsert.parteId,
                base:0,
                posizione:capitoliToInsert.numeroCapitolo,
                sv:0,
                ul:0,
                area1:0,
                area2:0,
                daEditare:false,
                isDataEditable:false,
                isTabOpen:false,
                dataEditing:Date.now(),
                paragrafi:[],
                isSelectedForPDF:false,
            }
            if(index2 === indexCapitolo){
              capitolo.isTabOpen = true;
            }
            capitoliToInsert.paragrafi.map((paragrafiToInsert,index3) => {
             // console.log(paragrafiToInsert);
              let paragrafo = {
                id:paragrafiToInsert.id,
                base:0,
                isBloccato:paragrafiToInsert.bloccato,
                capitoloId:paragrafiToInsert.capitoloId,
                posizione:paragrafiToInsert.numeroParagrafo,
                isObbligatorio:paragrafiToInsert.obbligatorio,
                sv:0,
                ul:0,
                area1:0,
                area2:0,
                dataEditing:Date.now(),
                isTabOpen:false,
                titolo:paragrafiToInsert.titolo,
                isSelectedForPDF:false,
                sottoparagrafi:[],
            }
            if(index3 === indexParagrafo){
              paragrafo.isTabOpen = true;
            }
            paragrafiToInsert.componenti.map((sottoparagrafiToInsert,index4) => {
              let sottoparagrafo= {
                id:sottoparagrafiToInsert.id,
                base:0,
                posizione:sottoparagrafiToInsert.numeroComponente,
                paragrafoId:sottoparagrafiToInsert.paragrafoId,
                sv:0,
                ul:0,
                area1:0,
                area2:0,
                titolo:sottoparagrafiToInsert.titolo,
                tipologia:sottoparagrafiToInsert.tipoContenuto,
                isSelectedForPDF:false,
                dataEditing:Date.now(),
            }
            if(index4 === indexSottoparagrafo){
              sottoparagrafo.isTabOpen = true;
            }
            paragrafo.sottoparagrafi.push(sottoparagrafo);
            })
            capitolo.paragrafi.push(paragrafo);
            })
            parte.capitoli.push(capitolo);
            })
            manualeData.parti.push(parte);
          })
          //console.log(response);
          //getPartiManuale(this.props.manualeId,0,999,"indiceOrdinamento","ASC").then((response) => {
           
          /*let capitolo = {
              id:1,
              titolo:"Test capitolo numero 1",
              base:0,
              posizione:1,
              sv:0,
              ul:0,
              area1:0,
              area2:0,
              daEditare:false,
              isTabOpen:false,
              dataEditing:Date.now(),
              paragrafi:[],
              isSelectedForPDF:false,
          }
          let capitolo1 = {
              id:2,
              titolo:"Test capitolo numero 2",
              base:0,
              posizione:2,
              sv:0,
              ul:0,
              area1:0,
              area2:0,
              daEditare:false,
              isTabOpen:false,
              dataEditing:Date.now(),
              paragrafi:[],
              isSelectedForPDF:false,
          }
          let paragrafo = {
              id:1,
              base:0,
              posizione:1,
              sv:0,
              ul:0,
              area1:0,
              area2:0,
              dataEditing:Date.now(),
              isTabOpen:false,
              titolo:"Test paragrafo",
              isSelectedForPDF:false,
              sottoparagrafi:[],
          }
          let paragrafo1 = {
            id:2,
            base:0,
            posizione:2,
            sv:0,
            ul:0,
            area1:0,
            area2:0,
            dataEditing:Date.now(),
            isTabOpen:false,
            titolo:"Test paragrafo 1",
            isSelectedForPDF:false,
            sottoparagrafi:[],
        }
        let paragrafo2 = {
          id:3,
          base:0,
          posizione:3,
          sv:0,
          ul:0,
          area1:0,
          area2:0,
          dataEditing:Date.now(),
          isTabOpen:false,
          titolo:"Test paragrafo 2",
          isSelectedForPDF:false,
          sottoparagrafi:[],
      }
          let sottoparagrafo= {
              id:1,
              base:0,
              posizione:1,
              sv:0,
              ul:0,
              area1:0,
              area2:0,
              titolo:"Test sottoparagrafo",
              tipologia:"Testo",
              isSelectedForPDF:false,
              dataEditing:Date.now(),
          }
          let sottoparagrafo2= {
            id:2,
            base:0,
            posizione:2,
            sv:0,
            ul:0,
            area1:0,
            area2:0,
            titolo:"Test sottoparagrafo 2",
            tipologia:"Testo",
            isSelectedForPDF:false,
            dataEditing:Date.now(),
        }
        manualeData.parti.push(parte);
        parte.capitoli.push(capitolo);
        parte.capitoli.push(capitolo1);
        capitolo.paragrafi.push(paragrafo);
        capitolo.paragrafi.push(paragrafo1);
        capitolo1.paragrafi.push(paragrafo2);
        paragrafo.sottoparagrafi.push(sottoparagrafo);
        paragrafo.sottoparagrafi.push(sottoparagrafo2);
        paragrafo2.sottoparagrafi.push(sottoparagrafo);*/
        this.setState({
            manualeData: manualeData,
            loading:false,
        },() => {
            
        })
          }).catch((error) => {
            if (error.status === 403) {
              this.handleInvalidToken();
            } else {
              this.handleError(true, error.message);
            }
          })
        /*}).catch((error) => {
          if (error.status === 403) {
            this.handleInvalidToken();
          } else {
            this.handleError(true, error.message);
          }
        })*/
    }

    render(){
        return(
            <div style={styles.mainContainer}>
                <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}>
            <Typography style={styles.typo} variant="h4">
              {this.state.manualeData.titolo}
            </Typography>
          </div>
          {!this.state.loading ? <div style={{display:"flex",justifyContent:"left"}}>
          <List style={{width: "100%"}}>
          <div style={{display:"flex"}}>
            <Typography  style={{marginLeft: 20}} variant="h4" align="left">Indice</Typography>
            <IconButton onClick={() => {
              let bodyToPass = {
                manualeId:this.state.manualeData.id,
                titolo:"Nuova parte",
                id:null,
              }
              postAggiuntaParteManuale(bodyToPass).then((response) => {
                let manualeData = this.state.manualeData;
              manualeData.parti.push({
                id:response.id,
                posizione:response.posizione,
                titolo:response.posizione,
                isTitoloEditable:false,
                capitoli:[],
              })
              this.setState({
                manualeData:manualeData,
              },() => {
                this.getManualeData();
              })
              }).catch((error) => {
                if (error.status === 403) {
                  this.handleInvalidToken();
                } else {
                  this.handleError(true, error.message);
                }
              })
             
            }} disabled={this.state.activateMovement} size="medium"><ControlPoint htmlColor={this.state.activateMovement ? '#e0e0e0' :"#194074"}></ControlPoint></IconButton>
            <div style={{marginLeft:"auto"}}>
            {!this.state.activateMovement ? <Button
            type="button"
            onClick={() => {
              this.handleMovementActivation();
            }}
            variant="contained"
            size="medium"
            disabled={false}
            style={{
                color: theme.palette.secondary.main, 
                margin:"5px",
                backgroundColor: theme.palette.utilPalette.primary,
            }}
          >Attiva spostamento</Button> : <Button
          type="button"
            onClick={() => {
              this.saveParts();
            }}
            variant="contained"
            size="medium"
            disabled={false}
            style={{
                color: theme.palette.secondary.main, 
                margin:"5px",
                backgroundColor: theme.palette.utilPalette.primary,
            }}
          >
              Salva Spostamento
          </Button>}
          </div>
          </div>
            {this.state.manualeData.parti.map((item,index) => {
            return <Fragment key={index}> <ListItem key={index}>
                <IconButton onClick = {() => {
                let manualeData = this.state.manualeData;
                manualeData.parti[index].isTabOpen = !manualeData.parti[index].isTabOpen;
                this.setState({
                    manualeData:manualeData,
                })
                }}>{item.isTabOpen ? <ExpandMore htmlColor="#194074"/> : <ChevronRight htmlColor="#194074"/>}</IconButton>
                <TextField onBlur={() => {
                  let bodyToPass = {
                    id:item.id,
                    titolo:item.titolo,
                    manualeId:this.state.manualeData.id,
                    indiceOrdinamento:item.posizione,
                    stato:"BOZZA",
                  }
                  putPartiManuale(bodyToPass).then((response) => {
                   // console.log(response);
                  }).catch((error) => {
                    if (error.status === 403) {
                      this.handleInvalidToken();
                    } else {
                      this.handleError(true, error.message);
                    }
                  })
                }} InputProps={{ disableUnderline: true}} 
                onChange={(event) => {
                    let manualeData = this.state.manualeData;
                    manualeData.parti[index].titolo = event.target.value;
                    this.setState({
                        manualeData:manualeData,
                    })
                }} inputProps={{style: {fontSize:"2.125rem",textAlign:"center",color:"black" }}}  disabled={!item.isTitoloEditable} value={item.titolo} style={{fontSize:30}}></TextField>
                <IconButton disabled={this.state.activateMovement} onClick = {() => {
                    let manualeData = this.state.manualeData;
                    manualeData.parti[index].isTitoloEditable = !manualeData.parti[index].isTitoloEditable;
                    this.setState({
                        manualeData:manualeData,
                    })
                }}>{this.state.manualeData.parti[index].isTitoloEditable ? <Edit htmlColor="#194074"></Edit> : <Edit></Edit>}</IconButton>
                <IconButton disabled={this.state.activateMovement} onClick = {() => {
                    let manualeData = this.state.manualeData;
                    let objectToPass = {
                      id:null,
                      manualeId:manualeData.id,
                      parteId:manualeData.parti[index].id,
                      titolo:"Nuovo capitolo",
                    }
                    postCreaCapitoli(objectToPass).then((response) => {
                      manualeData.parti[index].capitoli.push({
                      id:null,
                      titolo:"Nuovo capitolo",
                      base:0,
                      posizione:manualeData.parti[index].capitoli.length+1,
                      sv:0,
                      ul:0,
                      area1:0,
                      area2:0,
                      daEditare:false,
                      isTabOpen:false,
                      dataEditing:Date.now(),
                      paragrafi:[],
                    })
                    this.setState({
                        manualeData:manualeData,
                    },() => {
                      this.getManualeDataAfterSave(index);
                    })
                    }).catch((error) => {
                      if (error.status === 403) {
                      this.handleInvalidToken();
                    } else {
                      this.handleError(true, error.message);
                    }
                    })    
                }}><ControlPoint htmlColor={this.state.activateMovement ? '#e0e0e0' :"#194074"}></ControlPoint></IconButton>
                <div style={{display:"flex",flexDirection:"column"}}>
                <IconButton  disabled={!this.state.activateMovement} size="small" onClick = {() => {
                  if(index > 0){
                    let manualeData = this.state.manualeData;
                    swapElements(manualeData.parti,index,index-1);
                    this.setState({
                      manualeData:manualeData,
                    })
                  }
                }}><ExpandLess htmlColor={this.state.activateMovement ? "#194074" : '#e0e0e0'}></ExpandLess></IconButton>
                <IconButton  disabled={!this.state.activateMovement} size="small" onClick = {() => {
                  if(index < this.state.manualeData.parti.length-1){
                    let manualeData = this.state.manualeData;
                    swapElements(manualeData.parti,index,index+1);
                    this.setState({
                      manualeData:manualeData,
                    })
                  }
                }}><ExpandMore htmlColor={this.state.activateMovement ? "#194074" : '#e0e0e0'}></ExpandMore></IconButton>
                </div>
                <IconButton disabled={this.state.activateMovement} onClick = {() => {
                    let manualeData = this.state.manualeData;
                    if(manualeData.parti[index].capitoli.length === 0){
                    deletePartiManuale(manualeData.parti[index].id).then((response) => {
                    manualeData.parti.splice(index,1);
                    this.setState({
                        manualeData:manualeData,
                    })
                    }).catch((error) => {
                      if (error.status === 403) {
                        this.handleError(true, error.message);
                      } else {
                        this.handleError(true, error.message);
                      }
                    })
                    }else{
                      alert("Attenzione! Sono presenti dei capitoli associati alla parte selezionata. Eliminare prima i capitoli e poi la parte.")
                    }
                }}><Delete htmlColor={this.state.activateMovement ? '#e0e0e0' :"#194074"}></Delete></IconButton>
              </ListItem>  
              <Collapse in = {item.isTabOpen}>   
              <TableContainer >
              <Table style={{
                //borderCollapse: 'separate',
                //borderSpacing: '0px 20px'
              }} size="medium">
            {item.capitoli.map((item2,index2) => {
                return  <Fragment key={index2}>
                <div style={{marginBottom:50}}>
                <TableHead>
            <TableRow>
            <TableCell style={{border:0}} align="center"><IconButton size="medium" onClick = {() => {
                let manualeData = this.state.manualeData;
                manualeData.parti[index].capitoli[index2].isTabOpen = !manualeData.parti[index].capitoli[index2].isTabOpen;
                this.setState({
                    manualeData:manualeData,
                })
                }}>{item2.isTabOpen ? <ExpandMore htmlColor="#194074"></ExpandMore> : <ChevronRight htmlColor="#194074"></ChevronRight>}</IconButton></TableCell>
            <TableCell style={{border:"2px solid lightgray" ,borderTop:"2px solid lightgray",borderBottom:"2px solid lightgray",borderLeft:"2px solid lightgray",borderRight:"2px solid lightgray"}} align="center">Capitolo</TableCell>
            <TableCell style={{border:"2px solid lightgray",borderTop:"2px solid lightgray",borderBottom:"2px solid lightgray",borderLeft:0}} align="center">Titolo</TableCell>
            <TableCell style={{border:"2px solid lightgray",borderTop:"2px solid lightgray",borderBottom:"2px solid lightgray",borderLeft:0,width:"4.5%"}} align="center">Base&nbsp;</TableCell>
            <TableCell style={{border:"2px solid lightgray",borderTop:"2px solid lightgray",borderBottom:"2px solid lightgray",borderLeft:0}} align="center">SV</TableCell>
            <TableCell style={{border:"2px solid lightgray",borderTop:"2px solid lightgray",borderBottom:"2px solid lightgray",borderLeft:0,width:"10%"}} align="center">Da editare&nbsp;</TableCell>
            <TableCell style={{border:"2px solid lightgray",borderTop:"2px solid lightgray",borderBottom:"2px solid lightgray",borderLeft:0}} align="center">Data&nbsp;</TableCell>
            <TableCell style={{border:"2px solid lightgray",borderTop:"2px solid lightgray",borderBottom:"2px solid lightgray",borderLeft:0,width:"1%"}} align="center">Modifica Contenuto</TableCell>
            <TableCell style={{border:0}}></TableCell>
            </TableRow>
            </TableHead>
            <TableBody>                  
                <TableRow key={item2.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell style={{border:0}} align="center">{<Checkbox onClick={() => {
                  let manualeData = this.state.manualeData;
                  manualeData.parti[index].capitoli[index2].isSelectedForPDF = !manualeData.parti[index].capitoli[index2].isSelectedForPDF;
                  this.setState({
                    manualeData:manualeData,
                  })
                }} value={item2.isSelectedForPDF}></Checkbox>}</TableCell>
                <TableCell style={{border:"2px solid lightgray" ,borderTop:"2px solid lightgray",borderBottom:"2px solid lightgray",borderLeft:"2px solid lightgray",borderRight:"2px solid lightgray"}} align="center" component="th" scope="row">
                {item2.posizione}
                </TableCell>
                <TableCell style={{border:"2px solid lightgray",borderLeft:0,width:200}} align="center">{<TextField onInput = {(e) => {
                  let manualeData = this.state.manualeData;
                  manualeData.parti[index].capitoli[index2].titolo = e.target.value;
                  this.setState({
                    manualeData:manualeData,
                  },() => {
                    
                  })
                }} onBlur={() => {
                  let bodyToSave = {
                    id: item2.id,
                    titolo: item2.titolo,
                    manualeId: this.state.manualeData.id,
                    parteId: item.id,
                  }
                  putCapitoli(bodyToSave).then((response) => {
                    //console.log(response);
                  }).catch((error) => {
                    if (error.status === 403) {
                      this.handleInvalidToken();
                    } else {
                      this.handleError(true, error.message);
                    }
                  })

                }} inputProps={{style: {textAlign:"center", color:"black"}}} InputProps={{ disableUnderline: true}} disabled={!item2.isDataEditable} value={item2.titolo}></TextField>}</TableCell>
                <TableCell style={{border:"2px solid lightgray",borderLeft:0}} align="center">{item2.base}</TableCell>
                <TableCell style={{border:"2px solid lightgray",borderLeft:0}} align="center">{item2.sv}</TableCell>
                <TableCell style={{border:"2px solid lightgray",borderLeft:0}} align="center">{item2.daEditare ? "Si" : "No"}</TableCell>
                <TableCell style={{border:"2px solid lightgray",borderLeft:0}} align="center">{moment(item2.dataEditing).format("DD/MM/YYYY")}</TableCell>
                <TableCell style={{border:"2px solid lightgray",borderLeft:0}} align="center">{<IconButton disabled={this.state.activateMovement} size="small" onClick={() => {
                  let manualeData = this.state.manualeData;
                  manualeData.parti[index].capitoli[index2].isDataEditable = !manualeData.parti[index].capitoli[index2].isDataEditable;
                  this.setState({
                    manualeData:manualeData,
                  })
                }}>{this.state.manualeData.parti[index].capitoli[index2].isDataEditable ? <Edit htmlColor="#194074"></Edit> : <Edit></Edit>}</IconButton>}</TableCell>
                <TableCell style={{border:0}}>
                <div style={{display:"flex",flexDirection:"row"}}>
                <IconButton size="medium" disabled = {this.state.activateMovement} onClick = {() => {
                  let manualeData = this.state.manualeData;
                  let bodyToSave = {
                    id:null,
                    titolo:"Nuovo paragrafo",
                    capitoloId:item2.id,
                    manualeId:this.state.manualeData.id,
                  }
                  postAggiuntaParagrafoManualeConParti(bodyToSave).then((response) => {
                    manualeData.parti[index].capitoli[index2].paragrafi.push({
                    id:null,
                    base:0,
                    posizione:manualeData.parti[index].capitoli[index2].paragrafi.length+1,
                    sv:0,
                    ul:0,
                    area1:0,
                    area2:0,
                    dataEditing:Date.now(),
                    isTabOpen:false,
                    titolo:"Nuovo paragrafo",
                    sottoparagrafi:[],
                  })
                  manualeData.parti[index].capitoli[index2].isTabOpen = true;
                  this.setState({
                    manualeData:manualeData,
                  },() => {
                    this.getManualeDataAfterSave(index,index2);
                  })
                  }).catch((error) => {
                    if (error.status === 403) {
                      this.handleInvalidToken();
                    } else {
                      this.handleError(true, error.message);
                    }
                  })
                }}><Add htmlColor={this.state.activateMovement ? '#e0e0e0' :"#194074"}></Add></IconButton>
                <div style={{display:"flex",flexDirection:"column"}}>
                <IconButton disabled={!this.state.activateMovement} size="small" onClick = {() => {
                  let manualeData = this.state.manualeData;
                  if(index2 > 0){
                  swapElements(manualeData.parti[index].capitoli,index2,index2-1);
                  sortAllElements(manualeData.parti[index].capitoli);
                  this.setState({
                    manualeData:manualeData,
                  })
                  }
                  else if(index2 === 0 && manualeData.parti[index-1] !== undefined){
                    let elementToUse = manualeData.parti[index].capitoli[index2];
                    manualeData.parti[index].capitoli.splice(index2,1);
                    manualeData.parti[index-1].capitoli.push(elementToUse);
                    sortAllElements(manualeData.parti[index].capitoli);
                    sortAllElements(manualeData.parti[index-1].capitoli);
                    manualeData.parti[index-1].isTabOpen = true;
                    if(manualeData.parti[index].capitoli.length === 0){
                      manualeData.parti[index].isTabOpen = false;
                    }
                    this.setState({
                      manualeData:manualeData,
                    })
                  }
                }}><ExpandLess htmlColor={this.state.activateMovement ? "#194074" : '#e0e0e0'}></ExpandLess></IconButton>
                <IconButton disabled={!this.state.activateMovement} size="small" onClick = {() => {
                  let manualeData = this.state.manualeData;
                  if(index2 < manualeData.parti[index].capitoli.length - 1){
                  swapElements(manualeData.parti[index].capitoli,index2,index2+1);
                  sortAllElements(manualeData.parti[index].capitoli);
                  this.setState({
                    manualeData:manualeData,
                  })
                  }
                  else if(index2 >= manualeData.parti[index].capitoli.length - 1 && manualeData.parti[index+1] !== undefined){
                    let elementToUse = manualeData.parti[index].capitoli[index2];
                    manualeData.parti[index].capitoli.splice(index2,1);
                    manualeData.parti[index+1].capitoli.unshift(elementToUse);
                    sortAllElements(manualeData.parti[index].capitoli);
                    sortAllElements(manualeData.parti[index+1].capitoli);
                    manualeData.parti[index+1].isTabOpen = true;
                    if(manualeData.parti[index].capitoli.length === 0){
                      manualeData.parti[index].isTabOpen = false;
                    }
                    this.setState({
                      manualeData:manualeData,
                    })
                  }
                }}><ExpandMore htmlColor={this.state.activateMovement ? "#194074" : '#e0e0e0'}></ExpandMore></IconButton>
                </div>
                <IconButton size="medium" disabled={this.state.activateMovement} onClick = {() => {
                    let manualeData = this.state.manualeData;
                    if(manualeData.parti[index].capitoli[index2].paragrafi.length === 0){
                    //console.log(manualeData.parti[index].capitoli[index2].id);
                    deleteCapitoli(manualeData.parti[index].capitoli[index2].id).then(() => {
                      manualeData.parti[index].capitoli.splice(index2,1);
                    this.setState({
                        manualeData:manualeData,
                    },() => {
                      this.getManualeDataAfterSave(index,index2);
                    })
                    }).catch((error) => {
                      if (error.status === 403) {
                        this.handleError(true, error.message);
                      } else {
                        this.handleError(true, error.message);
                      }
                    })
                    }else{
                      alert("Attenzione! Sono presenti dei paragrafi associati a questo capitolo!")
                    }
                }}><Delete htmlColor={this.state.activateMovement ? '#e0e0e0' :"#194074"}></Delete></IconButton>
                </div>
                </TableCell>
                </TableRow>
                <TableRow>
                <TableCell style={item2.isTabOpen && item2.paragrafi.length > 0 ? { paddingBottom: 10, paddingTop: 0,borderBottom:0,borderTop:0 } : {paddingBottom:0,paddingTop:0,borderBottom:0,borderTop:0}} colSpan={10}>
                <Collapse style={{
                marginLeft: "14.6%"
              }} in = {item2.isTabOpen}>
                <Box>
                <Table style={{
                //borderCollapse: 'separate',
                //borderSpacing: '0px 20px'
              }} aria-label="paragrafi">
            {item2.paragrafi.map((item3,index3) => {
              return <Fragment key = {index3}>
              <div style={{marginTop:50}}>
            <TableHead>
            <TableRow>
            <TableCell align="center" style={{border:0}}><IconButton size="medium" onClick = {() => {
                let manualeData = this.state.manualeData;
                manualeData.parti[index].capitoli[index2].paragrafi[index3].isTabOpen = !manualeData.parti[index].capitoli[index2].paragrafi[index3].isTabOpen;
                this.setState({
                    manualeData:manualeData,
                })
                }}>{item3.isTabOpen ? <ExpandMore htmlColor="#194074"></ExpandMore> : <ChevronRight htmlColor="#194074"></ChevronRight>}</IconButton></TableCell>
            <TableCell style={{border:"2px solid lightgray" ,borderLeft:"2px solid lightgray",borderRight:"2px solid lightgray"}} align="center">Paragrafo</TableCell>
            <TableCell style={{border:"2px solid lightgray",borderLeft:0,width:"13%"}} align="center">Titolo</TableCell>
            <TableCell style={{border:"2px solid lightgray",borderLeft:0}} align="center">Base&nbsp;</TableCell>
            <TableCell style={{border:"2px solid lightgray",borderLeft:0}} align="center">SV</TableCell>
            <TableCell style={{border:"2px solid lightgray",borderLeft:0}} align="center">Da editare&nbsp;</TableCell>
            <TableCell style={{border:"2px solid lightgray",borderLeft:0}} align="center">Data&nbsp;</TableCell>
            <TableCell style={{border:"2px solid lightgray",borderLeft:0,width:"1%"}} align="center">Modifica Contenuto</TableCell>
            <TableCell style={{border:0}}></TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
              <TableCell style={{border:"0"}} align="center"><Checkbox onClick = {() => {
                let manualeData = this.state.manualeData;
                manualeData.parti[index].capitoli[index2].paragrafi[index3].isSelectedForPDF = !manualeData.parti[index].capitoli[index2].paragrafi[index3].isSelectedForPDF;
                this.setState({
                  manualeData:manualeData,
                })
              }} value={item3.isSelectedForPDF}></Checkbox></TableCell>
                <TableCell style={{border:"2px solid lightgray" ,borderLeft:"2px solid lightgray",borderRight:"2px solid lightgray"}} align="center" component="th" scope="row">
                {item3.posizione}
                </TableCell>
                <TableCell style={{border:"2px solid lightgray",borderLeft:0}} align="center">{item3.titolo}</TableCell>
                <TableCell style={{border:"2px solid lightgray",borderLeft:0}} align="center">{item3.base}</TableCell>
                <TableCell style={{border:"2px solid lightgray",borderLeft:0}} align="center">{item3.sv}</TableCell>
                <TableCell style={{border:"2px solid lightgray",borderLeft:0}} align="center">{item3.daEditare ? "Si" : "No"}</TableCell>
                <TableCell style={{border:"2px solid lightgray",borderLeft:0}} align="center">{moment(item3.dataEditing).format("DD/MM/YYYY")}</TableCell>
                <TableCell style={{border:"2px solid lightgray",borderLeft:0}} align="center"><IconButton disabled={this.state.activateMovement} onClick = {() => {
                  const {history} = this.props; 
                  history.push("/amministrazione/manuali/nuovomanuale/"+this.state.manualeData.id+"/"+item3.id);
                }}><Edit htmlColor={this.state.activateMovement ? '#e0e0e0' :"#194074"}></Edit></IconButton></TableCell>
                <TableCell style={{border:0}}>
                <div style={{display:"flex",flexDirection:"row"}}>
                <IconButton size="medium" disabled={this.state.activateMovement} onClick = {() => {
                  let manualeData = this.state.manualeData;
                  let body = {
                    id:null,
                    titolo:"Nuovo sottoparagrafo",
                    paragrafoId:item3.id,
                    manualeId:this.state.manualeData.id,
                  }
                  postAggiuntaSottoparagrafoManualeConParti(body).then((response) => {
                    manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi.push({
                    id:null,
                    base:0,
                    posizione:manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi.length+1,
                    sv:0,
                    ul:0,
                    area1:0,
                    area2:0,
                    titolo:"Nuovo sottoparagrafo",
                    tipologia:"Testo",
                    dataEditing:Date.now(),
                  })
                  manualeData.parti[index].capitoli[index2].paragrafi[index3].isTabOpen = true;
                  this.setState({
                    manualeData:manualeData,
                  },() => {
                    this.getManualeDataAfterSave(index,index2,index3);
                  })
                  }).catch((error) => {
                    if (error.status === 403) {
                      this.handleInvalidToken();
                    } else {
                      this.handleError(true, error.message);
                    }
                  })
                }}><Add htmlColor={this.state.activateMovement ? '#e0e0e0' :"#194074"}></Add></IconButton>
                <div style={{display:"flex",flexDirection:"column"}}>
                <IconButton size="small"  disabled={!this.state.activateMovement} onClick = {() => {
                  let manualeData = this.state.manualeData;
                  if(index3 > 0){
                    swapElements(manualeData.parti[index].capitoli[index2].paragrafi,index3,index3-1);
                    sortAllElements(manualeData.parti[index].capitoli[index2].paragrafi);
                    this.setState({
                      manualeData:manualeData,
                    })
                  }
                  else if(index3 === 0 && manualeData.parti[index].capitoli[index2-1] !== undefined){
                    //console.log("true");
                    let elementToUse = manualeData.parti[index].capitoli[index2].paragrafi[index3];
                    manualeData.parti[index].capitoli[index2].paragrafi.splice(index3,1);
                    manualeData.parti[index].capitoli[index2-1].paragrafi.push(elementToUse);
                    sortAllElements(manualeData.parti[index].capitoli[index2].paragrafi);
                    sortAllElements(manualeData.parti[index].capitoli[index2-1].paragrafi);
                    this.setState({
                      manualeData:manualeData,
                    })
                  }
                  else if(index3 === 0 && manualeData.parti[index].capitoli[index2-1] === undefined && manualeData.parti[index-1] !== undefined){
                    if(manualeData.parti[index-1].capitoli !== undefined){
                      let elementToUse = manualeData.parti[index].capitoli[index2].paragrafi[index3];
                      manualeData.parti[index].capitoli[index2].paragrafi.splice(index3,1);
                      let indexThatIWillUse = manualeData.parti[index-1].capitoli.length;
                      //console.log(indexThatIWillUse);
                      manualeData.parti[index-1].capitoli[indexThatIWillUse-1].paragrafi.push(elementToUse);
                      sortAllElements(manualeData.parti[index].capitoli[index2].paragrafi);
                      sortAllElements(manualeData.parti[index-1].capitoli[indexThatIWillUse-1].paragrafi);
                      this.setState({
                        manualeData:manualeData,
                      })
                    }
                  }
                }}><ExpandLess htmlColor={this.state.activateMovement ? "#194074" : '#e0e0e0'}></ExpandLess></IconButton>
                <IconButton  disabled={!this.state.activateMovement} onClick = {() => {
                  let manualeData = this.state.manualeData;
                  if(index3 < manualeData.parti[index].capitoli[index2].paragrafi.length - 1){
                    swapElements(manualeData.parti[index].capitoli[index2].paragrafi,index3,index3+1);
                    sortAllElements(manualeData.parti[index].capitoli[index2].paragrafi);
                    this.setState({
                      manualeData:manualeData,
                    })
                  }
                  else if(index3 >= manualeData.parti[index].capitoli[index2].paragrafi.length -1 && manualeData.parti[index].capitoli[index2+1] !== undefined){
                    let elementToUse = manualeData.parti[index].capitoli[index2].paragrafi[index3];
                    manualeData.parti[index].capitoli[index2].paragrafi.splice(index3,1);
                    manualeData.parti[index].capitoli[index2+1].paragrafi.unshift(elementToUse);
                    sortAllElements(manualeData.parti[index].capitoli[index2].paragrafi);
                    sortAllElements(manualeData.parti[index].capitoli[index2+1].paragrafi);
                    this.setState({
                      manualeData:manualeData,
                    })
                  }
                  else if(index3 >= manualeData.parti[index].capitoli[index2].paragrafi.length -1 && manualeData.parti[index].capitoli[index2+1] === undefined && manualeData.parti[index+1] !== undefined){
                    if(manualeData.parti[index+1].capitoli[0] !== undefined){
                      let elementToUse = manualeData.parti[index].capitoli[index2].paragrafi[index3];
                      manualeData.parti[index].capitoli[index2].paragrafi.splice(index3,1);
                      manualeData.parti[index+1].capitoli[0].paragrafi.unshift(elementToUse);
                      sortAllElements(manualeData.parti[index].capitoli[index2].paragrafi);
                      sortAllElements(manualeData.parti[index+1].capitoli[0].paragrafi);
                      this.setState({
                        manualeData:manualeData,
                      })
                    }
                  }
                }} size="small"><ExpandMore htmlColor={this.state.activateMovement ? "#194074" : '#e0e0e0'}></ExpandMore></IconButton>
                </div>
                <IconButton disabled={this.state.activateMovement} onClick = {() => {
                  let manualeData = this.state.manualeData;
                  if(item3.sottoparagrafi.length > 1){
                    alert("Attenzione, non è possibile eliminare un paragrafo se questo contiene altri paragrafi oltre a quello introduttivo");
                  }
                  else{
                  deleteParagrafoFromManualeConParti(manualeData.parti[index].capitoli[index2].paragrafi[index3].id).then((response) => {
                    manualeData.parti[index].capitoli[index2].paragrafi.splice(index3,1);
                  this.setState({
                    manualeData:manualeData,
                  },this.getManualeDataAfterSave(index,index2,index3))
                  }).catch((error) => {
                    if (error.status === 403) {
                      this.handleError(true, error.message);
                    } else {
                    this.handleError(true, error.message);
                    }
                  })
                  }
                }} size="medium"><Delete htmlColor={this.state.activateMovement ? '#e0e0e0' :"#194074"}></Delete></IconButton>
                </div>
                </TableCell>
              </TableRow>
              <TableRow>
              <TableCell style={item3.isTabOpen && item3.sottoparagrafi.length > 0 ? { paddingBottom: 10, paddingTop: 50,borderBottom:0,borderTop:0 } : {paddingBottom:0, paddingTop:0,borderBottom:0,borderTop:0}} colSpan={10}>
                <Collapse style={{
                marginLeft: "12%"
              }} in = {item3.isTabOpen}>
                {item3.sottoparagrafi.length > 0 ? <Box sx={{ margin: 1 }}>
                <Table style={{
                  
              }}   aria-label="sottoparagrafi">
                <TableHead>
            <TableRow>
            <TableCell style={{border:0}} align="center"></TableCell>
            <TableCell style={{border:"2px solid lightgray", borderRight:0,width:"15%"}} align="center">Sottoparagrafo</TableCell>
            <TableCell style={{border:"2px solid lightgray", borderRight:0,width:"35%"}} align="center">Titolo</TableCell>
            <TableCell style={{border:"2px solid lightgray", borderRight:0}} align="center">Base&nbsp;</TableCell>
            <TableCell style={{border:"2px solid lightgray", borderRight:0,width:"10%"}} align="center">Da editare&nbsp;</TableCell>
            <TableCell style={{border:"2px solid lightgray",width:"10%"}} align="center">Data&nbsp;</TableCell>
            {/*<TableCell style={{border:"2px solid lightgray",width:"1%"}} align="center">Modifica Contenuto</TableCell>*/}
            <TableCell style={{border:0}}></TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {item3.sottoparagrafi.map((item4,index4) => {
              return <Fragment key={index4}>
              <TableRow>
                <TableCell style={{border:0}} align="center"><Checkbox onClick={() => {
                  let manualeData = this.state.manualeData;
                  manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[index4].isSelectedForPDF = !manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[index4].isSelectedForPDF;
                  this.setState({
                    manualeData:manualeData,
                  })
                }} value={item4.isSelectedForPDF}></Checkbox></TableCell>
                <TableCell style={{border:"2px solid lightgray", borderRight:0}} align="center" component="th" scope="row">
                {item4.posizione}
                </TableCell>
                <TableCell style={{border:"2px solid lightgray", borderRight:0}} align="center">{item4.titolo}</TableCell>
                <TableCell style={{border:"2px solid lightgray", borderRight:0}} align="center">{item4.base}</TableCell>
                <TableCell style={{border:"2px solid lightgray", borderRight:0}} align="center">{item4.daEditare ? "Si" : "No"}</TableCell>
                <TableCell style={{border:"2px solid lightgray"}} align="center">{moment(item4.dataEditing).format("DD/MM/YYYY")}</TableCell>
                {/*<TableCell style={{border:"2px solid lightgray"}} align="center"><IconButton onClick = {() => {
                  const {history} = this.props; 
                  history.push("/amministrazione/manuali/nuovomanuale/"+this.state.manualeData.id+"/"+item3.id)
                }}><Edit htmlColor="#194074"></Edit></IconButton></TableCell>*/}
                <TableCell style={{border:0}}>
                <div style={{display:"flex",flexDirection:"row"}}>
                {index4 !== 0 ? <div style={{display:"flex",flexDirection:"column"}}>
                <IconButton  disabled={!this.state.activateMovement} size="small" onClick = {() => {
                  let manualeData = this.state.manualeData;
                  //console.log(manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[index4]);
                  if(!(manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[index4].titolo === "Organigramma" || manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[index4].tipologia === "TABELLA_LOCALI" || manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[index4].tipologia === "TABELLA_REPARTI" || manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[index4].tipologia === "TABELLA_PROCEDURE")){
                  if(index4 > 1){
                    swapElements(manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi,index4,index4-1);
                    sortAllElementsForSottoparagrafi(manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi);
                    this.setState({
                      manualeData:manualeData,
                    })
                  }
                  else if(index4 === 1 && manualeData.parti[index].capitoli[index2].paragrafi[index3-1] !== undefined){
                    let elementToUse = manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[index4];
                    manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi.splice(index4,1);
                    manualeData.parti[index].capitoli[index2].paragrafi[index3-1].sottoparagrafi.push(elementToUse);
                    sortAllElementsForSottoparagrafi(manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi);
                    sortAllElementsForSottoparagrafi(manualeData.parti[index].capitoli[index2].paragrafi[index3-1].sottoparagrafi);
                    this.setState({
                      manualeData:manualeData,
                    })
                  }
                  else if(manualeData.parti[index].capitoli[index2-1] !== undefined){ 
                  if(index4 === 1 && manualeData.parti[index].capitoli[index2].paragrafi[index3-1] === undefined && manualeData.parti[index].capitoli[index2-1].paragrafi[0] !== undefined){
                    let elementToUse = manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[index4];
                    manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi.splice(index4,1);
                    manualeData.parti[index].capitoli[index2-1].paragrafi[manualeData.parti[index].capitoli[index2-1].paragrafi.length-1].sottoparagrafi.push(elementToUse);
                    sortAllElementsForSottoparagrafi(manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi);
                    sortAllElementsForSottoparagrafi(manualeData.parti[index].capitoli[index2-1].paragrafi[manualeData.parti[index].capitoli[index2-1].paragrafi.length-1].sottoparagrafi);
                    this.setState({
                      manualeData:manualeData,
                    })
                    }
                  }
                  else if(manualeData.parti[index].capitoli[index2-1] === undefined && manualeData.parti[index-1] !== undefined){ 
                    if(index4 === 1 && manualeData.parti[index].capitoli[index2].paragrafi[index3-1] === undefined && manualeData.parti[index].capitoli[index2-1] === undefined){
                      let elementToUse = manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[index4];
                      manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi.splice(index4,1);
                      manualeData.parti[index-1].capitoli[manualeData.parti[index-1].capitoli.length-1].paragrafi[manualeData.parti[index-1].capitoli[manualeData.parti[index-1].capitoli.length-1].paragrafi.length-1].sottoparagrafi.push(elementToUse);
                      sortAllElementsForSottoparagrafi(manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi);
                      sortAllElementsForSottoparagrafi(manualeData.parti[index-1].capitoli[manualeData.parti[index-1].capitoli.length-1].paragrafi[manualeData.parti[index-1].capitoli[manualeData.parti[index-1].capitoli.length-1].paragrafi.length-1].sottoparagrafi);
                      this.setState({
                        manualeData:manualeData,
                      })
                    }
                    if(index4 === 1 && manualeData.parti[index].capitoli[index2].paragrafi[index3-1] === undefined && manualeData.parti[index].capitoli[index2-1] !== undefined && manualeData.parti[index].capitoli[index2-1].paragrafi[index3-1] === undefined){
                      let elementToUse = manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[index4];
                      manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi.splice(index4,1);
                      manualeData.parti[index-1].capitoli[manualeData.parti[index].capitoli.length-1].paragrafi[manualeData.parti[index].capitoli[index2-1].paragrafi.length-1].sottoparagrafi.push(elementToUse);
                      sortAllElementsForSottoparagrafi(manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi);
                      sortAllElementsForSottoparagrafi(manualeData.parti[index-1].capitoli[manualeData.parti[index].capitoli.length-1].paragrafi[manualeData.parti[index].capitoli[index2-1].paragrafi.length-1].sottoparagrafi);
                      this.setState({
                        manualeData:manualeData,
                      })
                    }
                  }
                  }else{
                    if(index4 > 0){
                      if(index4-1 !== 0){
                    swapElements(manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi,index4,index4-1);
                    sortAllElementsForSottoparagrafi(manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi);
                    this.setState({
                      manualeData:manualeData,
                    })
                      }else{
                        //console.log("No");
                      }
                  }
                  }
                }}><ExpandLess htmlColor={this.state.activateMovement ? "#194074" : '#e0e0e0'}></ExpandLess></IconButton>
                <IconButton  disabled={!this.state.activateMovement} size="small" onClick = {() => {
                  let manualeData = this.state.manualeData;
                  if(!(manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[index4].titolo === "Organigramma" || manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[index4].tipologia === "TABELLA_LOCALI" || manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[index4].tipologia === "TABELLA_REPARTI" || manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[index4].tipologia === "TABELLA_PROCEDURE")){
                  if(index4 < this.state.manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi.length - 1){
                    swapElements(manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi,index4,index4+1);
                    sortAllElementsForSottoparagrafi(manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi);
                    this.setState({
                      manualeData:manualeData,
                    })
                  }
                  else if(index4 >= this.state.manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi.length - 1 && manualeData.parti[index].capitoli[index2].paragrafi[index3+1] !== undefined){
                    let elementToUse = manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[index4];
                    manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi.splice(index4,1);
                    manualeData.parti[index].capitoli[index2].paragrafi[index3+1].sottoparagrafi.splice(1,0,elementToUse);
                    sortAllElementsForSottoparagrafi(manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi);
                    sortAllElementsForSottoparagrafi(manualeData.parti[index].capitoli[index2].paragrafi[index3+1].sottoparagrafi);
                    this.setState({
                      manualeData:manualeData,
                    })
                    }
                  else if(manualeData.parti[index].capitoli[index2+1] !== undefined){
                    if(index4 >= this.state.manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi.length - 1 && manualeData.parti[index].capitoli[index2].paragrafi[index3+1] === undefined && manualeData.parti[index].capitoli[index2+1].paragrafi[0] !== undefined){
                    let elementToUse = manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[index4];
                    manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi.splice(index4,1);
                    manualeData.parti[index].capitoli[index2+1].paragrafi[0].sottoparagrafi.splice(1,0,elementToUse);
                    sortAllElementsForSottoparagrafi(manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi);
                    sortAllElementsForSottoparagrafi(manualeData.parti[index].capitoli[index2+1].paragrafi[0].sottoparagrafi);
                    this.setState({
                      manualeData:manualeData,
                    })
                  }
                  }
                  else if(manualeData.parti[index].capitoli[index2+1] === undefined && manualeData.parti[index+1] !== undefined && manualeData.parti[index+1].capitoli[0] !== undefined){
                    if(index4 >= this.state.manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi.length - 1 && manualeData.parti[index].capitoli[index2].paragrafi[index3+1] === undefined && manualeData.parti[index].capitoli[0].paragrafi[0] !== undefined && manualeData.parti[index+1].capitoli[0].paragrafi[0] !== undefined){
                      let elementToUse = manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[index4];
                      manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi.splice(index4,1);
                      manualeData.parti[index+1].capitoli[0].paragrafi[0].sottoparagrafi.splice(1,0,elementToUse);
                      sortAllElementsForSottoparagrafi(manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi);
                      sortAllElementsForSottoparagrafi(manualeData.parti[index+1].capitoli[0].paragrafi[0].sottoparagrafi);
                      this.setState({
                        manualeData:manualeData,
                      })
                    }
                    else if(index4 >= this.state.manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi.length - 1 && manualeData.parti[index].capitoli[index2].paragrafi[index3+1] === undefined && manualeData.parti[index].capitoli[0].paragrafi[0] !== undefined && manualeData.parti[index+1].capitoli[0].paragrafi[0] === undefined){
                      /*let elementToUse = manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[index4];
                      manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi.splice(index4,1);
                      manualeData.parti[index+1].capitoli[0].paragrafi[0].sottoparagrafi.unshift(elementToUse);
                      this.setState({
                        manualeData:manualeData,
                      })*/
                      
                    }
                  }
                  }else{
                   // console.log("Hello")
                    if(index4 < this.state.manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi.length - 1){
                    swapElements(manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi,index4,index4+1);
                    sortAllElementsForSottoparagrafi(manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi);
                    this.setState({
                      manualeData:manualeData,
                    })
                  }
                  }
                }}><ExpandMore htmlColor={this.state.activateMovement ? "#194074" : '#e0e0e0'}></ExpandMore></IconButton>
                </div> : null}
                <IconButton size="medium" disabled={this.state.activateMovement} onClick = {() => {
                  let manualeData = this.state.manualeData;
                  //console.log(manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[index4]);
                  if(!(manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[index4].tipologia === "TABELLA_LOCALI" || manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[index4].tipologia === "TABELLA_REPARTI" || manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[index4].tipologia === "TABELLA_PROCEDURE" || manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[index4].titolo === "Organigramma")){
                  if(index4 !== 0){
                  deleteSottoparagrafoFromManualeConParti(manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi[index4].id).then((response) => {
                    manualeData.parti[index].capitoli[index2].paragrafi[index3].sottoparagrafi.splice(index4,1);
                  this.setState({
                    manualeData:manualeData,
                  },() => {
                    this.getManualeDataAfterSave(index,index2,index3,index4);
                  })
                  }).catch((error) => {
                    if (error.status === 403) {
                      this.handleError(true, error.message);
                    } else {
                     this.handleError(true, error.message);
                    }
                  })
                  }else{
                    alert("Attenzione, questo sottoparagrafo è obbligatorio e non può essere cancellato!")
                  }
                  }else{
                    alert("Attenzione, questo sottoparagrafo è obbligatorio e non può essere cancellato!")
                  }
                }}><Delete htmlColor={this.state.activateMovement ? '#e0e0e0' :"#194074"}></Delete></IconButton>
                </div>
                </TableCell>
              </TableRow>
              </Fragment>
            })}
            </TableBody>
            </Table>
            </Box> : null}
                </Collapse>
                </TableCell>
              </TableRow>
              </TableBody>
              </div>
              </Fragment>
            })}
                </Table>
                </Box>
                </Collapse>
                </TableCell>
                </TableRow>
                </TableBody>
                </div>
                </Fragment>
                })}
                    </Table>
                    </TableContainer>
              {/*<Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                {item.capitoli.map((item2,index2) => {
                    return <TableContainer>
                    <Table sx={{ }} aria-label="simple table">
                    <TableHead>
                    <TableRow>
            <TableCell align="left">Capitolo</TableCell>
            <TableCell align="center">Titolo</TableCell>
            <TableCell align="center">Base&nbsp;</TableCell>
            <TableCell align="center">Da editare&nbsp;</TableCell>
            <TableCell align="center">Data&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.rows.map((row) => (
            <>
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left" component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="center">{row.calories}</TableCell>
              <TableCell align="center">{row.fat}</TableCell>
              <TableCell align="center">{row.carbs}</TableCell>
              <TableCell align="center">{row.protein}</TableCell>
              <IconButton  onClick = {() => {
                this.setState({
                    isSubTabOpen:!this.state.isSubTabOpen,
                })
              }}><ExpandMore></ExpandMore></IconButton>
            </TableRow>
            <Collapse in = {this.state.isSubTabOpen}>
              <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Paragrafo</TableCell>
            <TableCell align="center">Titolo</TableCell>
            <TableCell align="center">Base</TableCell>
            <TableCell align="center">Da editare</TableCell>
            <TableCell align="center">Data</TableCell>
            <TableCell align="center">Modifica contenuto</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.rows2.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left" component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="center">{row.calories}</TableCell>
              <TableCell align="center">{row.fat}</TableCell>
              <TableCell align="center">{row.carbs}</TableCell>
              <TableCell align="center">{row.protein}</TableCell>
              <TableCell align="center">{row.button}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
              </Collapse>
              </>
            ))}
            </TableBody>
            </Table>
            </TableContainer>
                })}
              </Grid>*/}
              </Collapse>
              </Fragment>
            })
            }
            </List>
          </div> : <SpinnerComponent></SpinnerComponent>}
          <ErrorDialog
          open={this.state.errorDialogVisible}
          message={this.state.errorDialogMessage}
          onCloseButtonClicked={this.closeErrorDialog}
        />
            </div>
        )
    }
}
export default withRouter(CreazionePartiNuovoManuale);

