import React, { Component, Fragment } from 'react';
// React-table library requires to import css as well:
import ReactTable from 'react-table';
import 'react-table/react-table.css'; // eslint-disable-next-line
import Container from '@material-ui/core/Container';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import PropTypes from 'prop-types';

import IndietroButton from '../../../components/IndietroButton';
import ErrorDialog from '../../../components/ErrorDialog';
import SpinnerComponent from '../../../components/SpinnerComponent';
import { clearToken } from '../../../utils/storage';
import { getEserciziCommerciali, getEsercizioCommerciale, updateEsercizioCommerciale } from '../../../utils/api/esercizi_commerciali_api';
import { getResponsabiliSicurezzaPerConsulente, getResponsabiliSicurezza } from '../../../utils/api/responsabili_sicurezza_api';
import theme from '../../../theme';

export default class EsercizioXResponsabileComponent extends Component {

    constructor(props) {
        super(props);
        const ID = parseInt(this.props.responsabileId, 10);
        let incorrectId = false;
        if (isNaN(ID)) {
            incorrectId = true;
        }
        this.state = {
            esercizi: [],
            responsabileId: ID,
            responsabileSicurezza: {},
            consulenteId: null,
            errorDialogVisible: false,
            errorDialogMessage: '',
            loading: true,
            isResponsabileAssociatedToEsercizio:false,
            recordNotFound: incorrectId
        };
    }

    componentDidMount() {
        if (!this.state.recordNotFound) {
            this.fetchResponsabileSicurezza();
        }
    }

    // This code is a 'patch': at the moment there is not an API request
    // to fetch a single ResponsabileSicurezza by ID. 
    // Using the same GET request to fetch all responsabili associated to the consulente.
    fetchResponsabileSicurezza = () => {
        this.setState({ 
            loading: true,
        });
        if (this.props.consulenteView && !this.props.superconsulenteView) {
            getResponsabiliSicurezzaPerConsulente(this.props.consulenteId, 0, 1000, "id")
            .then(result => {
                this.handleFetchSuccess(result);
            })
            .catch(error => {
                if (error.status === 403) {
                    this.handleInvalidToken();
                } else {
                    this.setState({
                        loading: false,
                        errorDialogVisible: true,
                        errorDialogMessage: error.message,
                        recordNotFound: true
                    });
                }
            });
        } else {
            getResponsabiliSicurezza(this.props.responsabileId, this.props.gruppoId, 0, 1000, "id")
            .then(result => {
                this.handleFetchSuccess(result);
            })
            .catch(error => {
                if (error.status === 403) {
                    this.handleInvalidToken();
                } else {
                    this.setState({
                        loading: false,
                        errorDialogVisible: true,
                        errorDialogMessage: error.message,
                        recordNotFound: true
                    });
                }
            });
        }   
    }
    
    handleFetchSuccess = (responsabiliSicurezza) => {
        if (responsabiliSicurezza.length === 0) {
            this.setState({
                loading: false,
                recordNotFound: true
            });
        } else {
            let responsabile = null;
            for (let i = 0; i < responsabiliSicurezza.length; i++) {
                if (responsabiliSicurezza[i].id === this.state.responsabileId) {
                    responsabile = responsabiliSicurezza[i];
                    break;
                }
            }
            if (responsabile === null) {
                this.setState({
                    loading: false,
                    recordNotFound: true
                });
            } else {
                this.setState({
                    responsabileSicurezza: responsabile
                });
                this.fetchEserciziCommerciali(responsabile);
            }
        }
    }

    handleInvalidToken = () => {
        this.setState({
            errorDialogVisible: true,
            errorDialogMessage: 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.'
        });
        clearToken();
        window.setTimeout(function () {
            window.location.href = "/";
        }, 4000);
    }

    fetchEserciziCommerciali = (responsabileSicurezza) => {
        this.setState({
            loading: true,
        });
        let consulenteID = null;
        if (this.props.consulenteView && !this.props.superconsulenteView) {
            consulenteID = this.props.consulenteId;
        }
        getEserciziCommerciali(responsabileSicurezza.gruppoId, consulenteID, null, 0, 1000, "id")
        .then(result => {
            this.setState({
                esercizi: result
            });
            this.fetchEserciziResponsabile(responsabileSicurezza);
        })
        .catch(error => {
          if (error.status === 403) {
            this.handleInvalidToken();
          } else {
            this.setState({
                loading: false,
                errorDialogVisible: true,
                errorDialogMessage: error.message
            });
          }
        });
    }

    fetchEserciziResponsabile = (responsabileSicurezza) => {
        let consulenteID = null;
        if (this.props.consulenteView && !this.props.superconsulenteView) {
            consulenteID = this.props.consulenteId;
        }
        getEserciziCommerciali(responsabileSicurezza.gruppoId, consulenteID, this.state.responsabileId, 0, 1000, "id")
        .then(result => {
            let eserciziResponsabile = result;
            let esercizi = this.state.esercizi;
            let isResponsabileAssociatedToEsercizio = false; 
            if (eserciziResponsabile.length > 0) {
                isResponsabileAssociatedToEsercizio = true;
            }
            for (let i=0; i<esercizi.length; i++) {
                let isAssociated = false;
                for (let j=0; j<eserciziResponsabile.length; j++) {
                    if (eserciziResponsabile[j].id === esercizi[i].id) {
                        isAssociated = true;
                        break;
                    }
                }
                esercizi[i].isAssociated = isAssociated;
            }
            this.setState({
                loading: false,
                esercizi,
                isResponsabileAssociatedToEsercizio
            });
        })
        .catch(error => {
          if (error.status === 403) {
            this.handleInvalidToken();
          } else {
            this.setState({
                loading: false,
                errorDialogVisible: true,
                errorDialogMessage: error.message
            });
          }
        });
    }

    associateEsercizioCommerciale = (removeAssociation, esercizioCommercialeId) => {
        this.setState({
            loading: true,
        });
        getEsercizioCommerciale(esercizioCommercialeId)
        .then(result => {
            this.updateEsercizio(removeAssociation, result);
        })
        .catch(error => {
            if (error.status === 403) {
                this.handleInvalidToken();
            } else {
                this.setState({
                    loading: false,
                    errorDialogVisible: true,
                    errorDialogMessage: error.message,
                });
            }
        });
    }

    updateEsercizio = (removeAssociation, esercizio) => {
        let responsabiliForEsercizio = esercizio.responsabiliSicurezza;
        let responsabile = this.state.responsabileSicurezza;
        let newArray = [];
        if (removeAssociation) {
            newArray = responsabiliForEsercizio.filter(function(r) { return r.id !== responsabile.id; });
            esercizio.responsabiliSicurezza = newArray;
        } else {
            let resp = {
                id: responsabile.id,
                email: responsabile.email,
                nome : responsabile.nome,
                cognome: responsabile.cognome,
                telefono: responsabile.telefono,
                dataRegistrazione: responsabile.dataRegistrazione,
                terminiUsoAccettati: responsabile.terminiUsoAccettati,
                privacyPolicyAccettata: responsabile.privacyPolicyAccettata,
                gruppoId: responsabile.gruppoId
            };
            responsabiliForEsercizio.push(resp);
            esercizio.responsabiliSicurezza = responsabiliForEsercizio;
        }
        updateEsercizioCommerciale(esercizio)
        .then(result => {
            this.fetchEserciziCommerciali(this.state.responsabileSicurezza);
        })
        .catch(error => {
            if (error.status === 403) {
               this.handleInvalidToken();
            } else {
                this.setState({
                    loading: false,
                    errorDialogVisible: true,
                    errorDialogMessage: error.message,
                });
            }
        });
    }

    closeErrorDialog = () => {
        this.setState({
            errorDialogVisible: false
        });
    }

    render() {
        let esercizi = this.state.esercizi;
        const columns = [
            {
                Header: 'ID',
                id: 'id',
                accessor: d => d.id,
                minWidth: 80,
            },
            {
                Header: 'Ragione sociale',
                id: 'ragioneSociale',
                accessor: d => d.ragioneSociale,
                minWidth: 180,
            },
            {
                Header: 'Partita IVA',
                id: 'partitaIva',
                accessor: d => d.partitaIva,
                minWidth: 120,
            },
            {
                Header: 'Codice ATECO',
                id: 'codiceAteco',
                accessor: d => d.codiceAteco,
                minWidth: 120,
            },
            {
                Header: 'Città sede',
                id: 'cittaSede',
                accessor: d => d.cittaSede,
                minWidth: 180,
            },
            {
                id: 'associaEsercizioBtn',
                Header: 'Associa a responsabile',
                accessor: row => row,
                sortable: false,
                filterable: false,
                width: 180,
                Cell: props => 
                    props.value.isAssociated ?
                    <IconButton
                        size="small" 
                        style={{ color: theme.palette.success.main, }}
                        onClick={() => {this.associateEsercizioCommerciale(true, props.value.id)}}
                    >
                        <CheckCircleOutlineIcon />
                    </IconButton>
                    :
                    <IconButton
                        size="small" 
                        style={{ color: this.state.isResponsabileAssociatedToEsercizio? theme.palette.disabled.main : theme.palette.utilPalette.secondary}}
                        disabled={ this.state.isResponsabileAssociatedToEsercizio }
                        onClick={ () => {this.associateEsercizioCommerciale(false, props.value.id)} }
                    >
                        <AddCircleOutlineIcon />
                    </IconButton>
            }
        ];

        return (
            <Container id="mainContainer" style={{ paddingLeft: '4px', paddingRight: '4px', minHeight: '500px' }} >
                {this.state.loading ?
                    <SpinnerComponent size={24} />
                    :
                    <Fragment>
                        { this.state.recordNotFound ?
                            <Typography variant="h6" style={{ paddingTop: '6px', paddingBottom: '6px' }} >Nessun record trovato</Typography>
                            :
                            <Fragment>
                            <Typography variant="h6" style={{ paddingTop: '6px', paddingBottom: '20px' }} >Responsabile sicurezza presso esercizio:</Typography>
                            <Container id="tableContainer" style={{ paddingBottom: '20px' }} >
                                <ReactTable
                                    filterable={true}
                                    resizable={false}
                                    showPageSizeOptions={true}
                                    showPageJump={true}
                                    defaultPageSize={10}
                                    //pages={this.state.pages}
                                    data={esercizi}
                                    columns={columns}
                                    defaultFilterMethod={(filter, row,column) => {
                                        const id = filter.pivotId || filter.id;
                                        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
                                    }}
                                    //manual // informs React Table that you'll be handling sorting and pagination server-side
                                    //onFetchData={ (state, instance) => { this.fetchData(state.page, state.pageSize) } }
                                    previousText='Precedente'
                                    nextText='Successivo'
                                    noDataText='Nessun record'
                                    pageText='Pagina'
                                    ofText='di'
                                    rowsText='righe'
                                    pageJumpText='Vai a pagina'
                                    rowsSelectorText='righe per pagina'
                                />
                            </Container>
                            </Fragment>
                        }
                        <Grid container justify="center" style={{ paddingBottom:'20px', paddingLeft:'24px', paddingRight:'24px' }} >
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'right' }} >
                                <IndietroButton isDisabled={this.state.loading} />
                            </Grid>
                        </Grid>
                    </Fragment>
                }
            <ErrorDialog open={this.state.errorDialogVisible} message={this.state.errorDialogMessage} onCloseButtonClicked={this.closeErrorDialog} ></ErrorDialog>
            </Container>
        );
    }

}

EsercizioXResponsabileComponent.propTypes = {
    responsabileId: PropTypes.string.isRequired,
    consulenteId: PropTypes.number,
    gruppoId: PropTypes.number,
    consulenteView: PropTypes.bool.isRequired,
    superconsulenteView: PropTypes.bool.isRequired
}