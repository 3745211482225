import React, { Component } from "react";

// React-table library requires to import css as well:
import ReactTable from "react-table";
import "react-table/react-table.css"; // eslint-disable-next-line

import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import HighlightOff from "@material-ui/icons/HighlightOff";
import theme from "../../../theme.js";
import { orariControlli } from "../../../utils/constants.js";
import { Button } from "@material-ui/core";
import ActionButton from "../../../components/ActionButton.js";

const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: "190px",
        // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 270,
      },
    },
  };
  const styles = {
    formcontrol: {
      marginBottom: "30px",
      width: "400px",
      padding: "0px 4px",
    },
    container: {
      //border: '1px solid #e0e0e0',
      //borderRadius: '20px',
      margin: "10px 0px 14px 0px",
      padding: "10px 0px 14px 0px",
    },
    tablecontainer: {
      margin: "10px 0px 10px 0px",
      paddingBottom: "10px",
    },
    modalcontainer: {
      overflowY: "scroll",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      backgroundColor: "white",
      color: "black",
      paddingTop: "26px",
      paddingBottom: "26px",
      paddingLeft: "10px",
      paddingRight: "10px",
      marginTop: "26px",
      marginBottom: "26px",
      boxShadow:
        "0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px #194074",
      marginLeft: "15%",
      marginRight: "15%",
      height: "80%",
    },
  };

  export default class NonConformitaTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            records:[],
            columns:[],
        }
    }

    componentDidMount = () => {
        var a = this.getColumns();
        this.setState({
            columns:a,
        })
    }

    deleteRow = (cellInfo) => {
      let array = [...this.state.records];
    }
    onAddNonConformita = () => {
      let array = [...this.state.records];
      let newObject = {
        
      }
    }

    getColumns = () => {
        const columns = [
            {
                Header: "Non Conformità Rilevata",
                id: "RilevazioneNonConformita",
                accessor:d => d,
                width:250,
              },
              {
                Header: "Cause Di Non Conformità",
                id: "Cause",
                accessor:d => d,
                width:250,
              },
              {
                Header: "AzioniCorrettive",
                id: "Azioni",
                accessor:d => d,
                width:250,
              },
              {
                Header: "Elimina",
                id: "Elimina",
                accessor:d => d,
                Cell: props => <ActionButton label="Elimina" onClick = {() => {this.deleteRow(props.row.Elimina)}}></ActionButton>
              },
        ]
        return columns;
    }

    render(){
        return(
            <Grid
        container
        justify="center"
        alignItems="center"
        style={styles.container}
      >
      <Grid item xs={12} style={{marginTop:20}} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="body1" style={{ fontWeight: 500 }} gutterBottom>
            Gestione Non Conformità
          </Typography>
        </Grid>
        <FormControl style={styles.formcontrol}>
          <Select
            value={0}
            name="NonConformitaRilevata">
            <MenuItem value={0}>
              <em>Nuova gestione di una non conformità</em>
            </MenuItem>
          </Select>
          <Button
            size="small"
            onClick={() => this.onAddNonConformita()}
            variant="contained"
            disabled={false}
            style={{
              color: theme.palette.secondary.main,
              margin: "10px",
              backgroundColor: theme.palette.utilPalette.primary,
            }}
          >
            Aggiungi
          </Button>
        </FormControl>
      <div style={{display:"flex",width:"100%",justifyContent:"center"}}>
      <ReactTable
            filterable={false}
            resizable={true}
            showPageSizeOptions={true}
            showPageJump={true}
            defaultPageSize={5}
            style={{height:400}}
            //pages={this.state.pages}
            data={this.state.records}
            columns={this.state.columns}
            //manual // informs React Table that you'll be handling sorting and pagination server-side
            //onFetchData={ (state, instance) => { this.fetchData(state.page, state.pageSize) } }
            previousText="Precedente"
            nextText="Successivo"
            noDataText="Nessun record"
            pageText="Pagina"
            ofText="di"
            rowsText="righe"
            pageJumpText="Vai a pagina"
            rowsSelectorText="righe per pagina"
          />
          </div>
     </Grid>
     
        )
    }
  }