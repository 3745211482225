import React, { Component } from "react";
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import PropTypes from 'prop-types';
import { InputBase, styled } from "@material-ui/core";

const styles = {
    root: {
        marginTop: '10px',
        marginBottom: '10px',
        paddingTop: '10px',
        paddingBottom: '10px'
    },
    text: {
        padding: '0px 0px 10px 0px'
    },
    formcontrol: {
        marginBottom: '30px',
        marginTop:20,
        width: '500px',
    },
}

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: '146px',
            // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: '250px',
        },
    },
};
const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "& .MuiInputBase-input": {
      background:
        "linear-gradient(white, white) padding-box, linear-gradient(to right,#194074, #438c5a,#194074) border-box;",
      borderRadius: "50em",
      border: "1px solid transparent",
      paddingTop: "10px",
    },
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  }));

export default class SelezioneEsercizio extends Component {

    handleSelection = (event) => {
        this.props.onEsercizioSelected(event.target.value);
    }

    render () {
        return (
            <Container style={styles.root}>
                <Typography variant="body1" style={styles.text} >
                    {this.props.description}
                </Typography>
                <FormControl style={styles.formcontrol}>
                    <Select
                        value={this.props.esercizioId}
                        name="esercizioId"
                        onChange={this.handleSelection}
                        disabled={this.props.disabled}
                        MenuProps={MenuProps}
                        displayEmpty
                        input={<BootstrapInput></BootstrapInput>}
                    >
                        <MenuItem value={''} >
                            <em>Seleziona un esercizio</em>
                        </MenuItem>
                        { this.props.esercizi.map((value, index) => 
                        (<MenuItem key={index} value={value.id}>{value.nome}</MenuItem>))
                        }
                    </Select>
                    { this.props.hasError
                        && <FormHelperText error={this.props.hasError}>
                             Selezionare un esercizio
                            </FormHelperText>
                    }
                </FormControl>
            </Container>
        );
    }
}

SelezioneEsercizio.propTypes = {
    hasError: PropTypes.bool.isRequired,
    esercizi: PropTypes.array.isRequired,
    disabled: PropTypes.bool.isRequired,
    description: PropTypes.string.isRequired,
    onEsercizioSelected: PropTypes.func.isRequired
}