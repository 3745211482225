import React, { Component, Fragment } from 'react';
import Container from '@material-ui/core/Container';
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import PropTypes from 'prop-types';
import validator from "validator";

import SpinnerComponent from '../../../components/SpinnerComponent';
import StoricoAbbonamentiEsercizio from './StoricoAbbonamentiEsercizio';
import ErrorDialog from '../../../components/ErrorDialog';
import IndietroButton from '../../../components/IndietroButton';
import GiorniChiusuraEsercizio from './GiorniChiusuraEsercizio';

import { clearToken } from '../../../utils/storage';
import { getAbbonamenti } from '../../../utils/api/abbonamenti_api';
import { getEsercizioCommerciale, updateEsercizioCommerciale, sendEmailToConfirmRegistration } from '../../../utils/api/esercizi_commerciali_api';

import theme from '../../../theme.js';

const styles = {
    mainContainer: {
        textAlign: 'center',
        color: 'black',
        padding: '26px'
    },
    sectioncontainer: {
        border: '1px solid #e0e0e0',
        borderRadius: '20px',
        marginTop: '10px',
        marginBottom: '10px',
        paddingTop: '10px',
        paddingBottom: '10px'
    },
    fieldscontainer: {
        marginTop: '10px',
        marginBottom: '10px',
        padding: '10px'
    },
    textfield: {
        marginLeft: '20px',
        marginRight: '20px',
        marginBottom: '12px',
        width: '250px'
    }
}

export default class DettaglioEsercizioCommerciale extends Component {

    constructor(props) {
        super(props);
        this.state = {
            record: {
                esercizio: {
                    id: null,
                    consulenteId: null,
                    nome: null,
                    ragioneSociale: null,
                    partitaIva: null,
                    codiceAteco: null,
                    telefono: null,
                    nomeGestore: null,
                    cognomeGestore: null,
                    emailGestore: null,
                    telefonoGestore: null,
                    indirizzoSede: null,
                    numeroSede: null,
                    codiceSdi: null,
                    pec: null,
                    cittaSede: null,
                    provinciaSede: null,
                    gruppoId: null
                },
                giorniChiusura: []
            },
            abbonamenti: [],
            validationErrors: {
                nome: '',
                ragioneSociale: '',
                emailGestore: '',
            },
            errorDialogVisible: false,
            errorDialogMessage: '',
            loading: false,
            notfoundRecord: false,
            saveButtonEnabled: false,
            saveMessageVisible: false
        };
    }

    componentDidMount() {
        this.fetchRecord(this.props.esercizioId);
    }

    handleInvalidToken = () => {
        this.setState({
            errorDialogVisible: true,
            errorDialogMessage: 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.'
        });
        clearToken();
        window.setTimeout(function () {
            window.location.href = "/";
        }, 4000);
    }

    fetchRecord = (esercizioId) => {
        this.setState({
            loading: true,
        });
        getEsercizioCommerciale(esercizioId)
        .then(result => {
            this.setState({
                notfoundRecord: false,
                record: result
            });
            this.fetchAbbonamenti(esercizioId);
        })
        .catch(error => {
            if (error.status === 403) {
                this.handleInvalidToken();
            } else {
                this.setState({
                    loading: false,
                    errorDialogVisible: true,
                    errorDialogMessage: error.message,
                    notfoundRecord: true
                });
            }
        });
    }

    fetchAbbonamenti = (esercizioId) => {
        getAbbonamenti(null, null, esercizioId, 0, 1000, "id")
        .then(result => {
            this.setState({
                loading: false,
                abbonamenti: result
            });
        })
        .catch(error => {
            if (error.status === 403) {
                this.handleInvalidToken();
            }
            this.setState({
                loading: false,
                errorDialogVisible: true,
                errorDialogMessage: error.message,
            });
        });
    }

    validateForm = () => {
        let data = this.state.record;
        Object.keys(data).forEach(key => {
            this.validateField(key, data[key]);
        });
        //Return false if there are validation errors:
        let valid = true;
        let validationErrors = this.state.validationErrors;
        Object.values(validationErrors).forEach(val => {
            val.length > 0 && (valid = false);
        });
        return valid;
    }

    validateField = (key, value) => {
        let validationErrors = this.state.validationErrors;
        switch (key) {
            case "nome":
                if (value === null || value.length === 0) {
                    validationErrors.nome = "Inserire nome";
                } else {
                    validationErrors.nome = "";
                }
                break;
            case "ragioneSociale":
                if (value === null || value.length === 0) {
                    validationErrors.ragioneSociale = "Inserire ragione sociale";
                } else {
                    validationErrors.ragioneSociale = "";
                }
                break;
            case "emailGestore":
                if (value === null || value.length === 0) {
                    validationErrors.emailGestore = "Inserire indirizzo email del titolare";
                } else {
                    if (validator.isEmail(value)) {
                        validationErrors.emailGestore = "";
                    } else {
                        validationErrors.emailGestore = "L'indirizzo email inserito non è valido";
                    }
                }
                break;
            default:
                break;
        }
        this.setState({
            validationErrors
        });
    }

    handleChange = event => {
        if (!this.state.saveButtonEnabled) {
            this.setState({
                saveButtonEnabled: true
            });
        }
        if (this.state.saveMessageVisible) {
            this.setState({
                saveMessageVisible: false
            });
        }
        let name = event.target.name;
        let value = event.target.value;
        let validationErrors = this.state.validationErrors;
        let data = this.state.record;
        let esercizio = this.state.record.esercizio;

        esercizio[name] = value;
        //Reset validations :
        if (name === 'nome') {
            validationErrors[name] = "";
        }
        this.setState({
            data,
            validationErrors
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        let isFormValid = this.validateForm();
        if (isFormValid) {
            this.setState({
                loading: true
            });
            updateEsercizioCommerciale(this.state.record)
            .then(result => {
                this.setState({
                    loading: false,
                    saveButtonEnabled: false,
                    saveMessageVisible: true
                });
            })
            .catch(error => {
                if (error.status === 403) {
                   this.handleInvalidToken();
                } else {
                    this.setState({
                        loading: false,
                        errorDialogVisible: true,
                        errorDialogMessage: error.message,
                    });
                }
            });
        } // no-op if data is not valid
    }

    inviomail = () => {
        let isFormValid = this.validateForm();
        if (isFormValid) {
            this.setState({
                loading: true
            });
            sendEmailToConfirmRegistration(this.state.record.esercizio.id)
            .then(result => {
                this.setState({
                    loading: false
                });
                alert('Email inviata');
            })
            .catch(error => {
                if (error.status === 403) {
                   this.handleInvalidToken();
                } else {
                    this.setState({
                        loading: false,
                        errorDialogVisible: true,
                        errorDialogMessage: error.message,
                    });
                }
            });
        }
    }

    updateParentState = (data) => {
        let record = this.state.record
        record.giorniChiusura = data
        this.setState({
            record: record, saveButtonEnabled: true,
            loading:true,
        })
        updateEsercizioCommerciale(record).then(result => {
            this.setState({
                loading:false,
            })
        }).catch(error => {
            if(error.status === 403){
                this.handleInvalidToken();
            } else {
                this.setState({
                    loading:false,
                    errorDialogVisible:true,
                    errorDialogMessage:error.message,
                })
            }
        })
    }
    
    closeErrorDialog = () => {
        this.setState({
            errorDialogVisible: false
        });
    }

    render() {
        const disabledSubmitbutton = this.state.loading || (!this.state.saveButtonEnabled);
        return (
            <div style={styles.mainContainer} >
                <Typography variant="h4" >Dettagli esercizio commerciale</Typography>
                <Grid container justify="center" style={{ marginTop: '26px', marginBottom: '26px' }}>
                    <div style={{ minHeight: '700px' }} >
                        {this.state.loading ?
                            <SpinnerComponent size={24} />
                            :
                            (this.state.notfoundRecord ?
                                <Typography variant="h6" style={{ paddingTop: '6px', paddingBottom: '6px' }} >Nessun record trovato</Typography>
                                :
                                <Fragment>
                                    {this.state.record.esercizio.terminiUsoAccettati === true && this.state.record.esercizio.privacyPolicyAccettata === true ?
                                        null :
                                        <Button
                                            type="submit"
                                            onClick={this.inviomail}
                                            variant="contained"
                                            size="medium"
                                            style={{
                                                color: theme.palette.secondary.main,
                                                margin: '10px',
                                                borderRadius:30,
                                                backgroundColor: theme.palette.utilPalette.primary,
                                            }}
                                        >
                                            Invia mail di attivazione
                                            </Button>
                                    }
                                    <form autoComplete="off" onSubmit={this.handleSubmit}>
                                        <Container style={{ maxWidth: '700px' }}>
                                            <Grid container justify="center" alignItems="center">
                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.sectioncontainer}>
                                                    {/* <Typography variant="h5" style={{ paddingTop: '6px', paddingBottom: '6px' }} >Dati esercizio</Typography> */}
                                                    <Grid container style={styles.userdatacontainer}>

                                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                            <Grid container direction="column" alignItems="center" style={{ paddingBottom: '10px' }} >
                                                                <TextField
                                                                    label="ID esercizio"
                                                                    disabled
                                                                    value={this.state.record.esercizio.id === null ? "" : this.state.record.esercizio.id}
                                                                    InputProps={{
                                                                        readOnly: true,
                                                                    }}
                                                                    style={styles.textfield}
                                                                />
                                                                <TextField
                                                                    label="ID consulente"
                                                                    disabled
                                                                    value={this.state.record.esercizio.consulenteId === null ? "" : this.state.record.esercizio.consulenteId}
                                                                    InputProps={{
                                                                        readOnly: true,
                                                                    }}
                                                                    style={styles.textfield}
                                                                />
                                                                <TextField
                                                                    label="Nome"
                                                                    value={this.state.record.esercizio.nome === null ? "" : this.state.record.esercizio.nome}
                                                                    name="nome"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                    helperText={this.state.validationErrors.nome}
                                                                    error={this.state.validationErrors.nome.length > 0 ? true : false}
                                                                />
                                                                <TextField
                                                                    label="Ragione sociale"
                                                                    value={this.state.record.esercizio.ragioneSociale === null ? "" : this.state.record.esercizio.ragioneSociale}
                                                                    name="ragioneSociale"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                    helperText={this.state.validationErrors.ragioneSociale}
                                                                    error={this.state.validationErrors.ragioneSociale.length > 0 ? true : false}
                                                                />
                                                                <TextField
                                                                    label="Partita IVA"
                                                                    value={this.state.record.esercizio.partitaIva === null ? "" : this.state.record.esercizio.partitaIva}
                                                                    name="partitaIva"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                />
                                                                <TextField
                                                                    label="Codice ATECO"
                                                                    value={this.state.record.esercizio.codiceAteco === null ? "" : this.state.record.esercizio.codiceAteco}
                                                                    name="codiceAteco"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                />
                                                                <TextField
                                                                    label="Telefono"
                                                                    value={this.state.record.esercizio.telefono === null ? "" : this.state.record.esercizio.telefono}
                                                                    name="telefono"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                />
                                                                <TextField
                                                                    label="Nome titolare"
                                                                    value={this.state.record.esercizio.nomeGestore === null ? "" : this.state.record.esercizio.nomeGestore}
                                                                    name="nomeGestore"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                />
                                                                <TextField
                                                                    label="Cognome titolare"
                                                                    value={this.state.record.esercizio.cognomeGestore === null ? "" : this.state.record.esercizio.cognomeGestore}
                                                                    name="cognomeGestore"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                            <Grid container direction="column" alignItems="center" style={{ paddingBottom: '10px' }} >

                                                                <TextField
                                                                    label="Email titolare"
                                                                    value={this.state.record.esercizio.emailGestore === null ? "" : this.state.record.esercizio.emailGestore}
                                                                    name="emailGestore"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                    helperText={this.state.validationErrors.emailGestore}
                                                                    error={this.state.validationErrors.emailGestore.length > 0 ? true : false}
                                                                />
                                                                <TextField
                                                                    label="Telefono titolare"
                                                                    value={this.state.record.esercizio.telefonoGestore === null ? "" : this.state.record.esercizio.telefonoGestore}
                                                                    name="telefonoGestore"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                />
                                                                <TextField
                                                                    label="Indirizzo"
                                                                    value={this.state.record.esercizio.indirizzoSede === null ? "" : this.state.record.esercizio.indirizzoSede}
                                                                    name="indirizzoSede"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                />
                                                                <TextField
                                                                    label="Numero civico"
                                                                    value={this.state.record.esercizio.numeroSede === null ? "" : this.state.record.esercizio.numeroSede}
                                                                    name="numeroSede"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                />

                                                                <TextField
                                                                    label="Città"
                                                                    value={this.state.record.esercizio.cittaSede === null ? "" : this.state.record.esercizio.cittaSede}
                                                                    name="cittaSede"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                />
                                                                <TextField
                                                                    label="Provincia"
                                                                    value={this.state.record.esercizio.provinciaSede === null ? "" : this.state.record.esercizio.provinciaSede}
                                                                    name="provinciaSede"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                />
                                                                <TextField
                                                                    label="Codice SDI"
                                                                    defaultValue={this.state.record.esercizio.codiceSdi === null ? "" : this.state.record.esercizio.codiceSdi}
                                                                    name="codiceSdi"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                />
                                                                <TextField
                                                                    label="PEC"
                                                                    defaultValue={this.state.record.esercizio.pec === null ? "" : this.state.record.esercizio.pec}
                                                                    name="pec"
                                                                    onChange={this.handleChange}
                                                                    style={styles.textfield}
                                                                />
                                                                <TextField
                                                                    label="termini d'uso e privacy policy accettate"
                                                                    disabled
                                                                    defaultValue={this.state.record.esercizio.privacyPolicyAccettata & this.state.record.esercizio.terminiUsoAccettati ? "Si" : "No"}
                                                                    InputProps={{
                                                                        readOnly: true,
                                                                    }}
                                                                    style={styles.textfield}
                                                                />

                                                            </Grid>
                                                        </Grid>

                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Container>
                                        <StoricoAbbonamentiEsercizio
                                            abbonamenti={this.state.abbonamenti}
                                        />
                                        <GiorniChiusuraEsercizio
                                            esercizioId={this.state.record.esercizio.id}
                                            giorniChiusura={this.state.record.giorniChiusura}
                                            updateParentState={this.updateParentState}
                                        />
                                        <Grid container justify="center" style={{ paddingTop: '10px' }} >
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'center' }} >
                                                <Typography
                                                    variant="body1"
                                                    style={{
                                                        color: theme.palette.utilPalette.primary,
                                                        visibility: this.state.saveMessageVisible ? 'inherit' : 'hidden'
                                                    }}
                                                >
                                                    Dati aggiornati con successo.
                                            </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'right' }} >
                                                <IndietroButton isDisabled={this.state.loading} />
                                                <Button
                                                    type="submit"
                                                    onClick={this.handleSubmit}
                                                    variant="contained"
                                                    size="medium"
                                                    disabled={disabledSubmitbutton}
                                                    style={{
                                                        color: theme.palette.secondary.main,
                                                        margin: '10px',
                                                        borderRadius:30,
                                                        backgroundColor: disabledSubmitbutton ? theme.palette.disabled.main : theme.palette.utilPalette.primary,
                                                    }}
                                                >
                                                    Salva modifiche
                                            </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </Fragment>
                            )
                        }
                    </div>
                </Grid>

                <ErrorDialog open={this.state.errorDialogVisible} message={this.state.errorDialogMessage} onCloseButtonClicked={this.closeErrorDialog} ></ErrorDialog>

            </div>
        );
    }

}

DettaglioEsercizioCommerciale.propTypes = {
    esercizioId: PropTypes.string.isRequired,
    consulenteId: PropTypes.number,
    gruppoId: PropTypes.number.isRequired
}