import { IconButton, TextField } from "@material-ui/core";
import Container from '@material-ui/core/Container';
import Grid from "@material-ui/core/Grid";
import Paper from '@material-ui/core/Paper';
import Typography from "@material-ui/core/Typography";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import HighlightOff from '@material-ui/icons/HighlightOff';
import PropTypes from 'prop-types';
import React from "react";
import SpinnerComponent from "../../../../components/SpinnerComponent";
import theme from "../../../../theme";



const styles = {
    root: {
        //border: '1px solid #e0e0e0',
        //borderRadius: '20px',
        marginTop: '20px',
        marginBottom: '20px',
        minHeight: '296px',
    },
    text: {
        margin: '5px 0px 10px 0px',
    },
    gridContainer: {
        paddingTop: '10px'
    },
    gridElement: {
        padding: '10px 0px',
    },
    paper: {
        padding: '12px',
        boxShadow: '2px 2px 1px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 2px rgba(0,0,0,0.14), 0px 1px 1px 0px rgba(0,0,0,0.12)',
    },
    image: {
        //width: 146,
        height: 146,
        margin: 'auto',
        display: 'block',
    },
    img: {
        maxWidth: '100%',
        maxHeight: '100%',
    },
}

export default function ImmaginiProcedure(props) {

    const URL = process.env.REACT_APP_BACKEND_ENDPOINT;

    return (
        <Container maxWidth='md' style={styles.root}>
            <Typography variant="h6" style={styles.text}>
                Immagini Caricate
            </Typography>
            <Grid container justify="center" alignItems="center" style={styles.gridContainer} >
                {props.loading ?
                    <SpinnerComponent size={32} />
                    :
                    (props.immaginiProcedure.map((item, index) => {
                        return (
                            <Grid key={index} item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.gridElement} >
                                <Paper style={styles.paper} >
                                    <Grid container justify="center" alignItems="center">
                                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                            <div style={styles.image}>
                                                <img style={styles.img} alt="immagini_procedure" src={URL + "/api/paragrafi/procedurehaccp/immagini/" + item.id} />

                                            </div>

                                        </Grid>
                                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                            <div>
                                                <TextField
                                                    label='Titolo'
                                                    value={item.titolo === null ? '' :item.titolo}
                                                    name="titolo"
                                                    multiline
                                                    style={styles.inputfield}
                                                    onChange={(e) => props.onChangeTitolo(e.target.value,index)}
                                                 />
                                                <TextField
                                                    label='Note'
                                                    value={item.note === null ? '' :item.note}
                                                    name="note"
                                                    //variant="outlined"
                                                    multiline
                                                    style={styles.inputfield}
                                                    onChange={(e) => props.onChangeNote(e.target.value,index)}
                                                     />
                                            </div>
                                        </Grid>

                                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                            <div>
                                                <IconButton
                                                    size="small"
                                                    style={{
                                                        color: theme.palette.utilPalette.primary
                                                    }}
                                                    onClick={(e) => { props.onRimuoviImage(item.id, e) }}
                                                    disabled={false}

                                                >
                                                    <HighlightOff />
                                                </IconButton>
                                            </div>
                                            <div>
                                                {index === 0 ?
                                                    <IconButton color="primary" size="small" disabled>
                                                        <ArrowUpwardIcon style={{ color: 'gray' }} />

                                                    </IconButton>
                                                    :
                                                    <IconButton color="primary" size="small" onClick={() => { props.onMove(index , index  -1) }}>

                                                        <ArrowUpwardIcon style={{ color: theme.palette.utilPalette.primary }} />

                                                    </IconButton>
                                                }

                                            </div>
                                            <div>
                                                {index < props.immaginiProcedure.length - 1 ?

                                                    <IconButton color="primary" size="small" onClick={() => { props.onMove(index, index + 1) }}>

                                                        <ArrowDownwardIcon style={{ color: theme.palette.utilPalette.primary }} />
                                                    </IconButton>
                                                    :
                                                    <IconButton color="primary" size="small" disabled>

                                                        <ArrowDownwardIcon style={{ color: 'gray' }} />

                                                    </IconButton>
                                                }
                                             
                                            </div>
                                    </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
            );
        }))
    }
            </Grid>

        </Container >
    );
}

ImmaginiProcedure.propTypes = {
    loading: PropTypes.bool.isRequired,
    immaginiProcedure: PropTypes.array.isRequired,
    onRimuoviImage: PropTypes.func.isRequired,
}