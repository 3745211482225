import React, { Component } from "react";
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import PropTypes from 'prop-types';

import ModalCreazioneGruppo from './ModalCreazioneGruppo';
import { InputBase, styled } from "@material-ui/core";

const styles = {
    // externalcontainer: {
    //     border: '1px solid #e0e0e0',
    //     borderRadius: '20px',
    //     marginTop: '10px',
    //     marginBottom: '10px',
    //     paddingTop: '10px',
    //     paddingBottom: '10px'
    // },
    root: {
        marginTop: '10px',
        marginBottom: '10px',
        paddingTop: '10px',
        paddingBottom: '10px'
    },
    text: {
        padding: '0px 0px 20px 0px'
    },
    formcontrol: {
        marginBottom: '30px',
        width: '400px',
    },
}

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: '146px',
            // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: '250px',
        },
    },
};
const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "& .MuiInputBase-input": {
      background:
        "linear-gradient(white, white) padding-box, linear-gradient(to right,#194074, #438c5a,#194074) border-box;",
      borderRadius: "50em",
      border: "1px solid transparent",
      paddingTop: "10px",
    },
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  }));

export default class SelezioneGruppo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openModal: false
        }
    }

    handleSelection = (event) => {
        const id = event.target.value;
        if (id === '') {
            this.setState({
                openModal: true
            });
        } else {
            this.props.onGruppoSelected(id);
        }
    }

    onModalClosed = (isOperationCancelled) => {
        if (!isOperationCancelled) {
            this.setState({
                openModal: false
            });
            this.props.onGruppoAdded();
        } else {
            this.setState({
                openModal: false
            });
        }
    }

    onAuthenticationError = () => {
        this.setState({
            openModal: false
        });
        this.props.onAuthError();
    }

    render() {
        let gruppoid = this.props.gruppoId !== null ? this.props.gruppoId : '';
        return (
            <Container style={styles.root}>
                <Typography variant="body1" style={styles.text} >
                    {this.props.description}
                </Typography>
                <FormControl style={styles.formcontrol}>
                    <Select
                        displayEmpty
                        value={gruppoid}
                        name="id"
                        onChange={this.handleSelection}
                        disabled={this.props.disabled}
                        MenuProps={MenuProps}
                        input={<BootstrapInput></BootstrapInput>}
                    >
                        <MenuItem value={''} >
                            <em>Crea un nuovo gruppo</em>
                        </MenuItem>
                        {this.props.gruppi.map((value, index) =>
                            (<MenuItem key={index} value={value.id}>{value.nome}</MenuItem>))
                        }
                    </Select>
                </FormControl>
                <ModalCreazioneGruppo
                    open={this.state.openModal}
                    onClose={this.onModalClosed}
                    onAuthError={this.onAuthenticationError}
                />
            </Container>
        );
    }
}

SelezioneGruppo.propTypes = {
    gruppoId: PropTypes.number,
    gruppi: PropTypes.array.isRequired,
    disabled: PropTypes.bool.isRequired,
    description: PropTypes.string.isRequired,
    onGruppoSelected: PropTypes.func.isRequired,
    onGruppoAdded: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    onAuthError: PropTypes.func.isRequired
}