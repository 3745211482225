import React, { Fragment, useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';

import { Link, Route, Switch, useLocation, useParams, useRouteMatch } from "react-router-dom";

import { isLoggedUserAConsulente, getConsulenteId, isSuperconsulente, getGruppoId } from '../../utils/storage.js';

import AbbonamentiTable from './tabelle/AbbonamentiTable';
import ConsulentiTable from './tabelle/ConsulentiTable';
import EserciziCommercialiTable from './tabelle/EserciziCommercialiTable';
//import ManualiTable from './tabelle/ManualiTable.js';
import ModelliPersonalizzatiTable from './tabelle/ModelliPersonalizzatiTable.js';
import ManualiPersonalizzatiTable from './tabelle/ManualiPersonalizzatiTable.js';
//import ModelliManualiTable from './tabelle/ModelliManualiTable';
import ResponsabiliSicurezzaTable from './tabelle/ResponsabiliSicurezzaTable';
import GruppiTable from './tabelle/GruppiTable';

import theme from '../../theme.js';
import { makeStyles } from '@material-ui/core';

const styles = {
    mainContainer: {
        textAlign: 'center',
        color: 'black',
        minHeight:"100vh"
    },
    controlPanelTitle: {
        paddingTop: 30,
        paddingBottom: 20,
        margin: 0,
    },
    controlPanelContainer: {
        padding: "20px",
        overflow: "hidden"
    },
    tab: {
        wordBreak: 'break-word',
        color: 'white'
    },
    buttonGroup: {
        margin: 10
    }
};

const useStyles = makeStyles((themes) => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.utilPalette.primary,
    },
    tab: {
      borderRadius: 50,
      marginRight: 5,
      background: theme.palette.utilPalette.tabEmpty,
      "&.Mui-selected": {
        background: theme.palette.utilPalette.primary,
      },
    },
  }));

export default function ControlPanel() {
    useEffect(() => {
        document.body.style.minWidth = "1200px";
        window.scrollTo((document.body.scrollWidth-window.screen.width) / 2, 0);
        return function cleanup() {
            document.body.style.minWidth = "0px";
        };
    },[])
    const isConsulente = isLoggedUserAConsulente();
    const match = useRouteMatch();
    const location = useLocation();
    const classes = useStyles();
    const tabClasses = { root: classes.tab };
    return (
        <div id="main_component_container" style={styles.mainContainer}>
            <Typography variant="h4" style={styles.controlPanelTitle} >PANNELLO DI CONTROLLO</Typography>
            <div style={styles.controlPanelContainer} >
                <Grid container justify='space-evenly'>
                    <Grid item >
                        <AppBar position="static" style={{ backgroundColor: "white", boxShadow: "none" }} >
                            <Tabs value={location.pathname}
                              TabIndicatorProps={{
                            style: {
                    background: theme.palette.utilPalette.primary,
                    width: 0,
                  },
                }} indicatorColor='secondary' 
                textColor='primary' 
                variant="fullWidth" >
                                {/* isConsulente ? null :
                                    <Tab 
                                     classes={tabClasses}
                                        style={styles.tab} 
                                        label="Gruppi" 
                                        component={Link}
                                        to={`${match.url}/gruppi`} 
                                        value={`${match.url}/gruppi`} 
                                    />
                                */}
                                <Tab style={styles.tab}   classes={tabClasses} label="Responsabili sicurezza" component={Link} to={`${match.url}/responsabili_sicurezza`} value={`${match.url}/responsabili_sicurezza`} />
                                <Tab style={styles.tab}   classes={tabClasses} label={isConsulente ? "Consulente" : "Consulenti"} component={Link} to={`${match.url}/consulenti`} value={`${match.url}/consulenti`} />
                                <Tab style={styles.tab}  classes={tabClasses} label="Esercizi commerciali" component={Link} to={`${match.url}/esercizi_commerciali`} value={`${match.url}/esercizi_commerciali`} />
                                <Tab style={styles.tab}  classes={tabClasses} label="Modelli di manuali" component={Link} to={`${match.url}/modelli_manuali`} value={`${match.url}/modelli_manuali`} />
                                <Tab style={styles.tab}  classes={tabClasses} label="Manuali sicurezza" component={Link} to={`${match.url}/manuali`} value={`${match.url}/manuali`} />
                                {isConsulente ? null :
                                    <Tab style={styles.tab} label="Abbonamenti"  classes={tabClasses} component={Link} to={`${match.url}/abbonamenti`} value={`${match.url}/abbonamenti`} />
                                }
                            </Tabs>
                        </AppBar>
                    </Grid>
                </Grid>
                <Switch>
                    <Route path={`${match.path}/:tablename`}>
                        <TableComponent />
                    </Route>
                    <Route path={`${match.path}`}>
                        <TextComponent />
                    </Route>
                </Switch>
            </div>
        </div>
    );
}

function TableComponent() {
    const consulenteId = isLoggedUserAConsulente() ? Number.parseInt(getConsulenteId(), 10) : null;
    const superconsulenteView = isSuperconsulente() === "true";
    const gruppoId = getGruppoId() === null || getGruppoId() === 'null' ? null : Number.parseInt(getGruppoId(), 10);
    let { tablename } = useParams();
    let table = null
    if (tablename === "responsabili_sicurezza") {
        table = <ResponsabiliSicurezzaTable 
                consulenteId={consulenteId} 
                superconsulenteView={superconsulenteView} 
                gruppoId={gruppoId} 
                />
    } else if (tablename === "consulenti") {
        table = <ConsulentiTable 
                consulenteId={consulenteId} 
                superconsulenteView={superconsulenteView} 
                gruppoId={gruppoId} 
                />
    } else if (tablename === "esercizi_commerciali") {
        table = <EserciziCommercialiTable 
                consulenteId={consulenteId} 
                superconsulenteView={superconsulenteView} 
                gruppoId={gruppoId} 
                />
    } else if (tablename === "modelli_manuali") {
        table = <ModelliPersonalizzatiTable 
                    consulenteId={consulenteId} 
                    superconsulenteView={superconsulenteView} 
                    gruppoId={gruppoId} 
                />
    } else if (tablename === "manuali") {
        table = <ManualiPersonalizzatiTable 
                    consulenteId={consulenteId} 
                    superconsulenteView={superconsulenteView} 
                    gruppoId={gruppoId} 
                />
    } else if (tablename === "abbonamenti") {
        table = <AbbonamentiTable />
    } else if (tablename === "gruppi") {
        table = <GruppiTable />
    }
    return (
        <Fragment>
            {table}
        </Fragment>
    );
}

function TextComponent() {
    return (
        <div style={{ padding: "20px" }}>
            <Typography variant="h5" >Selezionare la tabella da visualizzare cliccando sul tab corrispondente</Typography>
        </div>
    );
}
