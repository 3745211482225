import React from "react";
import { getContenutoParagrafo, postUploadImmaginiSottoparagrafi, putModificaParagrafiManualePersonalizzato, updateSottoparagrafi } from "../../../utils/api/paragrafi_api";
import { clearToken } from "../../../utils/storage";
import BoxTitoloManuale from "./BoxTitoloManuale";
import ErrorDialog from "../../../components/ErrorDialog";
import { Box, IconButton, Modal, TextField, Typography } from "@material-ui/core";
import ActionButton from "../../../components/ActionButton";
import GoBackButton from "../components/GoBackButton";
import { Edit } from "@material-ui/icons";
import NewSottoparagrafoTesto from "./NewSottoparagrafoTesto";
import CloseIcon from "@material-ui/icons/Close";
import CustomTableForManualeForParti from "./CustomTableForManualeParti";
import NewSottoparagrafoTestoEImmagineForParti from "./NewSottoparagrafoTestoEImmagineForParti";
import { updateLocaliManuale } from "../../../utils/api/locali_e_attrezzature_api";
import { updateReparti } from "../../../utils/api/reparti";

function swapElements(arr, i1, i2) {
  let temp = arr[i1];

  arr[i1] = arr[i2];

  arr[i2] = temp;
}
const styles = {
  mainContainer: {
    textAlign: "center",
    color: "black",
    padding: "26px 10px",
    minHeight: "90vh",
  },
  typo: {
    align: "center",
  },
  root: {
    flexGrow: 1,
    display: "flex",
  },
  buttonContainer: {},
  modalcontainer: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    textAlign: "center",
    backgroundColor: "white",
    color: "black",
    //paddingTop: '26px',
    //paddingBottom: '26px',
    paddingTop: "6px",
    paddingBottom: "6px",
    paddingLeft: "10px",
    paddingRight: "10px",
    marginTop: "26px",
    marginBottom: "26px",
    boxShadow:
      "0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px #194074",
    marginLeft: "20%",
    marginRight: "20%",
    //height: '85%'
    overflowY: "scroll",
    height: "600px",
  },
  textfield: {
    marginLeft: "20px",
    marginRight: "20px",
    marginBottom: "12px",
    width: "250px",
  },
  sectioncontainer: {
    // border: '1px solid #e0e0e0',
    //borderRadius: '20px',
    //marginTop: '10px',
    marginBottom: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  rowcontainer: {
    flexDirection: "row",
  },
  modalsectioncontainer: {
    flex: 1,
    display: "flex",
    //marginTop: 20,
    //marginBottom: 20,
    flexDirection: "column",
    justifyContent: "space-evely",
  },
  periodo: {
    flex: 1,
    display: "flex",
    flexDirection: "column",

    alignItems: "center",
    justifyContent: "space-evenly",
  },
  tableModalText: {
    marginTop: "2%",
    marginBottom: "2%",
  },
  notSelectedTab:{
    display:"flex",
    flexDirection:"row"
  },
  selectedTab:{
    display:"flex",
    flexDirection:"row",
  }
};

export default class ModificaParagrafiManualeConParti extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            sottoParagrafiData:[],
            errorDialogVisible: false,
            sottoParagrafoIndex: 0,
            sottoParagrafi:[],
            isModalOpen: false,
            isBlocked:false,
            edit:false,
            idManuale:this.props.manualeId,
            paragrafoId:this.props.paragrafo,
            titoloParagrafo:"",
            errorTitle:"Errore",
            colonneTabellaCustom: [],
            titoloSottoParagrafo: "Nuovo Sottoparagrafo",
            isTitoloEditable:false,
            currentTabValue: {
                label: "",
                value: "",
                idManuale: "",
                isSelected: true,
                isBlocked: false,
                obbligatorio: true,
              },
              tabValue: 0,
      idToSave: 0,
      tabListValues:[
        /*{
        name:"Chapter 1",
        isTabOpen:false,
        paragraphData:[{
          nomeTab:"Organigramma",
          value:0,
          isTabSelected:false,
        },{
          nomeTab:"Definizione Procedure Autocontrollo",
          value:1,
          isTabSelected:false,
        }]
        },
        {
          name:"Chapter 2",
          isTabOpen:false,
          paragraphData:[{
            nomeTab:"Descrizione Reparti",
            value:2,
            isTabSelected:false,
          },{
            nomeTab:"Definizione Reparti",
            value:3,
            isTabSelected:false,
          }]
          },*/
      ],
      loading: true,
      model: [],
      manual: [],
      tabs: [
        /*{label:"Organigramma",value:"Organigramma",isSelected:true,isBlocked:true},{label:"Definizione Reparti",value:"Definizione_Reparti",isSelected:true,isBlocked:true},{label:"Descrizione Reparti",value:"Descrizione_Reparti",isSelected:true,isBlocked:true}/*,{label:"Attrezzatura",value:"Attrezzatura",isSelected:true,isBlocked:true},{label:"Definizione Procedure Autocontrollo",value:"Descrizione_Procedure_Autocontrollo",isSelected:true,isBlocked:true}*/
      ],
      isSottoparagrafiModalOpen: false,
      firstRefresh: true,
      errorDialogMessage: "",
      errorDialogTitle: null,
      isDialogOpen: false,
      tipoDiTabella: "Custom",
      tabelle: [],
      tabelleIndex: 1,
      chapterMode:undefined,
      numberOfSottoparagrafo: 0,
      numberOfParagrafi: 0,
      selectedChapter:null,
      modalitaSottoparagrafo: "Testo",
      numberOfLines: 1,
      isChangeChapterNameModalOpen:false,
      modalTitle: "",
      editParagraphTitle: false,
      showTables: false,
      updatedReparti: null,
      updatedLocali: null,
        }
    }

    componentDidMount() {
    this.getSottoparagrafi(this.props.paragrafo);
  }

  getSottoparagrafi = (id) => {
    getContenutoParagrafo(id)
      .then((result) => {
        this.setState({
          sottoParagrafiData: result.componenti,
          titoloParagrafo:result.titolo,
        },() => {
            this.loadSottoparagrafi();
        });
      })
      .catch((error) => {
        if (error.status === 403) {
          //this.handleInvalidToken();
        } else {
          this.handleError(true, error.message);
        }
      });
  };

  handleRimuoviTabella = (indexValue) => {
    if (
      !this.state.currentTabValue.isBlocked ||
      localStorage.getItem("superconsulente") === "true" ||
      localStorage.getItem("consulenteId") === "null"
    ) {
      let subparagraphArray = [...this.state.sottoParagrafi];
      let subParagraphDataArray = [...this.state.sottoParagrafiData];
      let newSubparagraphArray = subparagraphArray.filter(
        (subparagraphArray) => subparagraphArray.index !== indexValue
      );
      let newSubparagraphArrayData = subParagraphDataArray.filter(
        (subParagraphDataArray) =>
          subParagraphDataArray.numeroComponente !== indexValue
      );
      let newIndex = this.state.sottoParagrafoIndex;
      newIndex -= 1;
      this.setState({
        sottoParagrafoIndex: newIndex,
      });
      this.setState({
        sottoParagrafi: newSubparagraphArray,
        sottoParagrafiData: newSubparagraphArrayData,
        edit: true,
      });
    } else {
      alert(
        "Attenzione, operazione non permessa poichè il paragrafo è bloccato"
      );
    }
  };

  handleRimuoviSottoparagrafo = (indexValue) => {
    if (
      !this.state.currentTabValue.isBlocked ||
      localStorage.getItem("superconsulente") === "true" ||
      localStorage.getItem("consulenteId") === "null"
    ) {
      let subparagraphArray = [...this.state.sottoParagrafi];
      let subParagraphDataArray = [...this.state.sottoParagrafiData];
      let newSubparagraphArray = subparagraphArray.filter(
        (subparagraphArray) => subparagraphArray.index !== indexValue
      );
      let newSubparagraphArrayData = subParagraphDataArray.filter(
        (subParagraphDataArray) =>
          subParagraphDataArray.numeroComponente !== indexValue
      );
      let newIndex = this.state.sottoParagrafoIndex;
      newIndex -= 1;
      this.setState({
        sottoParagrafoIndex: newIndex,
      });
      this.setState(
        {
          sottoParagrafi: newSubparagraphArray,
          sottoParagrafiData: newSubparagraphArrayData,
          edit: true,
        },
        () => {
          //this.loadSottoparagrafi();
        }
      );
    } else {
      alert(
        "Attenzione, operazione non permessa poichè il paragrafo è bloccato"
      );
    }
  };
  handleAggiuntaTable = () => {
    if (this.state.colonneTabellaCustom.length > 0) {
      let tablesArray = [...this.state.sottoParagrafi];
      //let currentParagraph = this.state.tabs.at(this.state.currentTabValue);
      let currentParagraph = this.state.currentTabValue;
      var nameToPass = "Tabella Personalizzata";
      let subParagraphDataArray = [...this.state.sottoParagrafiData];
      let columnsToInsert = [...this.state.colonneTabellaCustom];
      let columnsNames = [];
      columnsToInsert.forEach((element) => {
        columnsNames.push(element.header);
      });
      let logicalArrayOfColumns = "";
      let data = [];
      let logicalArrayOfData = [];
      logicalArrayOfColumns = JSON.stringify(columnsNames);
      logicalArrayOfData = JSON.stringify(data);
      if (this.state.tipoDiTabella === "Custom") {
        let newSubparagraphArrayData = {
          tipo: "SOTTOPARAGRAFO",
          id: null,
          paragrafoId: currentParagraph.id,
          parentComponentId: null,
          numeroComponente: this.state.sottoParagrafoIndex,
          titolo: "Tabella Personalizzata",
          numeroSottoparagrafo: null,
          tipoContenuto: null,
          componenti: [
            {
              tipo: "TABELLA_PERSONALIZZATA",
              id: null,
              paragrafoId: currentParagraph.id,
              parentComponentId: null,
              numeroComponente: this.state.sottoParagrafoIndex,
              definizioniColonne: logicalArrayOfColumns,
              contenuto: logicalArrayOfData,
            },
          ],
          immagini: [],
        };
        this.setState(
          {
            sottoParagrafiData: subParagraphDataArray,
          },
          () => {}
        );
        tablesArray.push({
          index: this.state.sottoParagrafoIndex,
          value: (
            <CustomTableForManualeForParti
              key={this.state.sottoParagrafoIndex}
              isParagrafoBlocked={this.state.currentTabValue.isBlocked}
              mode={"Custom"}
              updateCustomTable={this.aggiornaTabellaCustomHandler}
              moveUpward={this.handleSottoparagrafoMovementUpward}
              moveDownward={this.handleSottoparagrafoMovementDownward}
              titoloParagrafo={
                this.state.currentTabValue.label
                  ? this.state.currentTabValue.label
                  : "Nuovo Paragrafo"
              }
              idParagrafo={null}
              sottoParagrafiData={subParagraphDataArray}
              modalTitle={nameToPass}
              titoloInizialeSottoparagrafo={nameToPass}
              handleError={this.handleError}
              handleInvalidToken={this.handleInvalidToken}
              rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo}
              bringTitleToParent={this.bringTitleToParent}
              idManuale={this.state.idManuale}
              indexTab={this.state.sottoParagrafoIndex}
              handleRimuoviTabella={this.handleRimuoviTabella}
              columns={this.state.colonneTabellaCustom}
              numberOfRows={this.state.numberOfLines}
            ></CustomTableForManualeForParti>
          ),
        });
        subParagraphDataArray.push(newSubparagraphArrayData);

        let newIndex = this.state.sottoParagrafoIndex;
        newIndex += 1;
        this.setState({
          sottoParagrafoIndex: newIndex,
        });
      }
      /*else if(this.state.tipoDiTabella === "Organigramma"){
          nameToPass = "Organigramma";
          tablesArray.push(<CustomTableForManuale mode={"Organigramma"} modalTitle={nameToPass} numberOfRows={this.state.numberOfLines}></CustomTableForManuale>);
        }
        else if(this.state.tipoDiTabella === "Definizione_Reparto"){
          nameToPass = "Definizione Reparto";
          tablesArray.push(<CustomTableForManuale mode={"Definizione_Reparto"} modalTitle={nameToPass} numberOfRows={this.state.numberOfLines}></CustomTableForManuale>);
        }
        else if(this.state.tipoDiTabella === "Descrizione_Reparto"){
          nameToPass = "Descrizione Reparto";
          tablesArray.push(<CustomTableForManuale mode={"Descrizione_Reparto"} modalTitle={nameToPass} numberOfRows={this.state.numberOfLines}></CustomTableForManuale>);
        }
        else if(this.state.tipoDiTabella === "Definizione_Procedure_Autocontrollo"){
          nameToPass = "Descrizione Procedure Autocontrollo"
          tablesArray.push(<CustomTableForManuale mode={"Definizione_Procedure_Autocontrollo"} modalTitle={nameToPass} numberOfRows={this.state.numberOfLines}></CustomTableForManuale>);
        }*/
      this.setState({
        sottoParagrafi: tablesArray,
        colonneTabellaCustom: [],
        showTables: true,
        isModalOpen: false,
        edit: true,
      },() => {

      });
    } else {
      alert("Attenzione, occorre aggiungere almeno una colonna");
    }
  };
  handleEditChange = () => {
    this.setState({
      edit: true,
    });
  };
  handleSottoparagrafoMovementUpward = (sottoParagrafoIndex) => {
    if (
      !this.state.currentTabValue.isBlocked ||
      localStorage.getItem("superconsulente") === "true" ||
      localStorage.getItem("consulenteId") === "null"
    ) {
      let newArray = [...this.state.sottoParagrafiData];
      if (sottoParagrafoIndex > 1) {
        swapElements(newArray, sottoParagrafoIndex, sottoParagrafoIndex - 1);
        this.setState(
          {
            sottoParagrafiData: [...newArray],
          },
          () => {
           this.loadSottoparagrafiAfterSwap();
          }
        );
        /*if(sottoParagrafoIndex > 0){
          let newArray = this.state.tabs;
          swapElements(newArray,sottoParagrafoIndex,sottoParagrafoIndex-1);
        }*/
      }
    } else {
      alert(
        "Attenzione, operazione non permessa poichè il paragrafo è bloccato"
      );
    }
  };
  handleSottoparagrafoMovementDownward = (sottoParagrafoIndex) => {
    if (
      !this.state.currentTabValue.isBlocked ||
      localStorage.getItem("superconsulente") === "true" ||
      localStorage.getItem("consulenteId") === "null"
    ) {
      let newArray = [...this.state.sottoParagrafiData];
      if (sottoParagrafoIndex < this.state.sottoParagrafi.length - 1) {
        swapElements(newArray, sottoParagrafoIndex, sottoParagrafoIndex + 1);
        /**/
        this.setState(
          {
            sottoParagrafiData: [...newArray],
          },
          () => {
            this.loadSottoparagrafiAfterSwap();
          }
        );
      }
    } else {
      alert(
        "Attenzione, operazione non permessa poichè il paragrafo è bloccato"
      );
    }
  };

  loadSottoparagrafi = () => {
    let sottoParagrafiArray = [];
    let numberToIncrease = this.state.numberOfSottoparagrafo;
    let sottoParagrafiData = [...this.state.sottoParagrafiData];
    sottoParagrafiData.map((item) => {
      if (item.componenti[0] === undefined) {
        if (item.tipoContenuto === "TABELLA_REPARTI") {
          sottoParagrafiArray.push({
            index: item.numeroComponente,
            value: (
              <CustomTableForManualeForParti
                key={item.numeroComponente}
                aggiornaReparti={this.aggiornaReparti}
                isParagrafoBlocked={this.state.currentTabValue.isBlocked}
                mode={"Definizione_Reparto"}
                kind={this.props.mode}
                firstElement={true}
                handleError={this.handleError}
                titoloInizialeSottoparagrafo={item.titolo}
                handleInvalidToken={this.handleInvalidToken}
                idManuale={this.state.idManuale}
                modalTitle={item.titolo}
                numberOfRows={this.state.numberOfLines}
              ></CustomTableForManualeForParti>
            ),
          });
        } else if (item.tipoContenuto === "TABELLA_LOCALI") {
          sottoParagrafiArray.push({
            index: item.numeroComponente,
            value: (
              <CustomTableForManualeForParti
                key={item.numeroComponente}
                mode={"Descrizione_Reparto"}
                aggiornaLocali={this.aggiornaLocali}
                isParagrafoBlocked={this.state.currentTabValue.isBlocked}
                handleError={this.handleError}
                firstElement={true}
                handleInvalidToken={this.handleInvalidToken}
                titoloInizialeSottoparagrafo={item.titolo}
                idManuale={this.state.idManuale}
                modalTitle={item.titolo}
                numberOfRows={this.state.numberOfLines}
              ></CustomTableForManualeForParti>
            ),
          });
        } else if (item.tipoContenuto === "TABELLA_PROCEDURE") {
          sottoParagrafiArray.push({
            index: item.numeroComponente,
            value: (
              <CustomTableForManualeForParti
                key={item.numeroComponente}
                idParagrafo={item.paragrafoId}
                isParagrafoBlocked={this.state.currentTabValue.isBlocked}
                indexTab={item.numeroComponente}
                mode={"Definizione_Procedure_Autocontrollo"}
                bringDataToParent={this.bringDataToParentAutocontrollo}
                bringDataAfterExchange={
                  this.bringDataToParentAutocontrolloAfterExchange
                }
                bringTextToParent={this.bringTextToParentAutocontrollo}
                bringTitleToParent={this.bringTitleToParent}
                sottoParagrafiData={this.state.sottoParagrafiData}
                handleError={this.handleError}
                firstElement={true}
                itemScheda={item}
                handleInvalidToken={this.handleInvalidToken}
                titoloInizialeSottoparagrafo={item.titolo}
                idManuale={this.state.idManuale}
                modalTitle={item.titolo}
                numberOfRows={this.state.numberOfLines}
              ></CustomTableForManualeForParti>
            ),
          });
        }
      } else if (item.tipoContenuto === "TABELLA_PROCEDURE") {
        sottoParagrafiArray.push({
          index: item.numeroComponente,
          value: (
            <CustomTableForManualeForParti
              key={item.numeroComponente}
              indexTab={item.numeroComponente}
              isParagrafoBlocked={this.state.currentTabValue.isBlocked}
              bringTitleToParent={this.bringTitleToParent}
              idParagrafo={item.paragrafoId}
              mode={"Definizione_Procedure_Autocontrollo"}
              bringDataAfterExchange={
                this.bringDataToParentAutocontrolloAfterExchange
              }
              bringDataToParent={this.bringDataToParentAutocontrollo}
              bringTextToParent={this.bringTextToParentAutocontrollo}
              sottoParagrafiData={this.state.sottoParagrafiData}
              handleError={this.handleError}
              firstElement={true}
              itemScheda={item}
              handleInvalidToken={this.handleInvalidToken}
              rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo}
              titoloInizialeSottoparagrafo={item.titolo}
              idManuale={this.state.idManuale}
              modalTitle={item.titolo}
              numberOfRows={this.state.numberOfLines}
            ></CustomTableForManualeForParti>
          ),
        });
      } else {
        if (item.componenti[0].tipo === "BLOCCO_TESTO") {
          if (item.numeroComponente === 0) {
            if (item.titolo === null) {
              sottoParagrafiArray.push({
                index: item.numeroComponente,
                value: (
                  <NewSottoparagrafoTestoEImmagineForParti
                    key={item.numeroComponente}
                    handleEditChange={this.handleEditChange}
                    isParagrafoBlocked={this.state.currentTabValue.isBlocked}
                    sottoParagrafiData={this.state.sottoParagrafiData}
                    bringDataToParent={this.bringDataToParent}
                    deleteImage={this.deleteImageFromSottoparagrafo}
                    handleError={this.handleError}
                    handleInvalidToken={this.handleInvalidToken}
                    immaginiParagrafo={item.immagini}
                    bringTitleToParent={this.bringTitleToParent}
                    bringImageToParent={this.bringImageToParent}
                    testoSottoParagrafo={item.componenti[0].contenuto}
                    indexValue={item.numeroComponente}
                    firstElement={true}
                    titoloInizialeSottoparagrafo={
                      item.componenti[0].titolo === null
                        ? ""
                        : item.componenti[0].titolo
                    }
                    rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo}
                    idImmagine={item.numeroComponente}
                    borderWidth={"100%"}
                    componentHeight={"50%"}
                    componentWidth={"50%"}
                    photoWidth={"100%"}
                    photoHeight={"100%"}
                  ></NewSottoparagrafoTestoEImmagineForParti>
                ),
              });
            } else {
              sottoParagrafiArray.push({
                index: item.numeroComponente,
                value: (
                  <NewSottoparagrafoTestoEImmagineForParti
                    key={item.numeroComponente}
                    handleEditChange={this.handleEditChange}
                    isParagrafoBlocked={this.state.currentTabValue.isBlocked}
                    sottoParagrafiData={this.state.sottoParagrafiData}
                    bringDataToParent={this.bringDataToParent}
                    deleteImage={this.deleteImageFromSottoparagrafo}
                    handleError={this.handleError}
                    handleInvalidToken={this.handleInvalidToken}
                    immaginiParagrafo={item.immagini}
                    bringTitleToParent={this.bringTitleToParent}
                    bringImageToParent={this.bringImageToParent}
                    testoSottoParagrafo={item.componenti[0].contenuto}
                    indexValue={item.numeroComponente}
                    firstElement={true}
                    titoloInizialeSottoparagrafo={
                      item.componenti[0].titolo === null
                        ? "Nuovo Sottoparagrafo"
                        : item.componenti[0].titolo
                    }
                    rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo}
                    idImmagine={item.numeroComponente}
                    borderWidth={"100%"}
                    componentHeight={"50%"}
                    componentWidth={"50%"}
                    photoWidth={"100%"}
                    photoHeight={"100%"}
                  ></NewSottoparagrafoTestoEImmagineForParti>
                ),
              });
            }
          } else {
            /*if(item.componenti[0].contenuto === ""){
                  sottoParagrafiArray.push({index:item.numeroComponente,value: <BoxTitoloManuale indexValue={item.numeroComponente} handleError={this.handleError} deleteImage={this.deleteImageFromSottoparagrafo} handleInvalidToken={this.handleInvalidToken} immaginiParagrafo={item.immagini} titoloInizialeSottoparagrafo={item.titolo === null ? "Nuovo Sottoparagrafo" : item.titolo} bringImageToParent={this.bringImageToParent} bringTitleToParent={this.bringTitleToParent}  rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo} idImmagine={item.numeroComponente} borderWidth={"100%"} componentHeight={"50%"} componentWidth={"50%"} photoWidth={"100%"} photoHeight={"100%"}></BoxTitoloManuale>});
                }
                else if(item.immagini.length === 0){
                  sottoParagrafiArray.push({index:item.numeroComponente,value:<NewSottoparagrafoTesto bringDataToParent={this.bringDataToParent} bringTitleToParent={this.bringTitleToParent} indexValue={item.numeroComponente} testoSottoParagrafo={item.componenti[0].contenuto} componentHeight={"50%"} componentWidth={"50%"} rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo} titoloInizialeSottoparagrafo={item.titolo === null ? "Nuovo Sottoparagrafo" : item.titolo}></NewSottoparagrafoTesto>})
                }
                else{*/
            sottoParagrafiArray.push({
              index: item.numeroComponente,
              value: (
                <NewSottoparagrafoTestoEImmagineForParti
                  key={item.numeroComponente}
                  handleEditChange={this.handleEditChange}
                  isParagrafoBlocked={this.state.currentTabValue.isBlocked}
                  sottoParagrafiData={this.state.sottoParagrafiData}
                  moveUpward={this.handleSottoparagrafoMovementUpward}
                  moveDownward={this.handleSottoparagrafoMovementDownward}
                  indexValue={item.numeroComponente}
                  deleteImage={this.deleteImageFromSottoparagrafo}
                  handleError={this.handleError}
                  handleInvalidToken={this.handleInvalidToken}
                  immaginiParagrafo={item.immagini}
                  bringDataToParent={this.bringDataToParent}
                  bringTitleToParent={this.bringTitleToParent}
                  bringImageToParent={this.bringImageToParent}
                  testoSottoParagrafo={item.componenti[0].contenuto}
                  firstElement={false}
                  titoloInizialeSottoparagrafo={
                    item.titolo === null ? "Nuovo Sottoparagrafo" : item.titolo
                  }
                  rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo}
                  idImmagine={item.numeroComponente}
                  borderWidth={"100%"}
                  componentHeight={"50%"}
                  componentWidth={"50%"}
                  photoWidth={"100%"}
                  photoHeight={"100%"}
                ></NewSottoparagrafoTestoEImmagineForParti>
              ),
            });
            //}
          }
        }
        if (item.componenti[0].tipo === "TABELLA") {
          if (item.componenti[0].organigramma) {
            sottoParagrafiArray.push({
              index: item.numeroComponente,
              value: (
                <CustomTableForManualeForParti
                  key={item.numeroComponente}
                  isParagrafoBlocked={this.state.currentTabValue.isBlocked}
                  firstElement={true}
                  sottoParagrafiData={this.state.sottoParagrafiData}
                  mode={"Organigramma"}
                  changeEditMode={this.handleEditChange}
                  bringDataToParentAutocontrolloOrganigramma={
                    this.bringDataToParentAutocontrolloOrganigramma
                  }
                  kind={this.props.mode}
                  idParagrafo={item.paragrafoId}
                  handleError={this.handleError}
                  handleInvalidToken={this.handleInvalidToken}
                  idManuale={this.state.idManuale}
                  modalTitle={item.titolo}
                  titoloInizialeSottoparagrafo={item.titolo}
                  indexTab={item.numeroComponente}
                  numberOfRows={this.state.numberOfLines}
                ></CustomTableForManualeForParti>
              ),
            });
          } else {
            sottoParagrafiArray.push({
              index: item.numeroComponente,
              value: (
                <CustomTableForManualeForParti
                  key={item.numeroComponente}
                  isParagrafoBlocked={this.state.currentTabValue.isBlocked}
                  mode={"Custom"}
                  updateCustomTable={this.aggiornaTabellaCustomHandler}
                  moveUpward={this.handleSottoparagrafoMovementUpward}
                  moveDownward={this.handleSottoparagrafoMovementDownward}
                  titoloParagrafo={
                    item.titolo
                  }
                  sottoParagrafiData={this.state.sottoParagrafiData}
                  idParagrafo={item.paragrafoId}
                  modalTitle={
                    item.titolo
                  }
                  titoloInizialeSottoparagrafo={
                    item.titolo
                  }
                  handleError={this.handleError}
                  bringTitleToParent={this.bringTitleToParent}
                  handleInvalidToken={this.handleInvalidToken}
                  idManuale={this.state.idManuale}
                  indexTab={this.state.sottoParagrafoIndex}
                  handleRimuoviTabella={this.handleRimuoviTabella}
                  rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo}
                  columns={this.state.colonneTabellaCustom}
                  numberOfRows={this.state.numberOfLines}
                ></CustomTableForManualeForParti>
              ),
            });
          }
        }
        if (item.componenti[0].tipo === "TABELLA_PERSONALIZZATA") {
          let contenuto = item.componenti[0].contenuto;
          let colonne = item.componenti[0].definizioniColonne;
          var array = JSON.parse("[" + colonne + "]");
          this.setState({
            colonneTabellaCustom: array[0],
          });
          sottoParagrafiArray.push({
            index: item.numeroComponente,
            value: (
              <CustomTableForManualeForParti
                key={item.numeroComponente}
                isParagrafoBlocked={this.state.currentTabValue.isBlocked}
                sottoParagrafiData={this.state.sottoParagrafiData}
                mode={"Custom"}
                updateCustomTable={this.aggiornaTabellaCustomHandler}
                moveUpward={this.handleSottoparagrafoMovementUpward}
                moveDownward={this.handleSottoparagrafoMovementDownward}
                modalTitle={
                  item.titolo
                }
                titoloParagrafo={item.titolo}
                titoloInizialeSottoparagrafo={
                  item.titolo
                }
                handleError={this.handleError}
                bringTitleToParent={this.bringTitleToParent}
                contenuto={contenuto}
                handleInvalidToken={this.handleInvalidToken}
                idManuale={this.state.idManuale}
                idParagrafo={item.paragrafoId}
                indexTab={item.numeroComponente}
                handleRimuoviTabella={this.handleRimuoviTabella}
                rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo}
                columns={array[0]}
                numberOfRows={this.state.numberOfLines}
              ></CustomTableForManualeForParti>
            ),
          });
        }
      }
    });
    numberToIncrease = sottoParagrafiArray.length;
    this.setState({
      sottoParagrafi: sottoParagrafiArray,
      numberOfSottoparagrafo: numberToIncrease,
      sottoParagrafoIndex: numberToIncrease,
      colonneTabellaCustom: [],
    },() => {

    });
  };
  loadSottoparagrafiAfterSwap = () => {
    let sottoParagrafiArray = [];
    let numberToIncrease = this.state.numberOfSottoparagrafo;
    let sottoParagrafiData = [...this.state.sottoParagrafiData];
    sottoParagrafiData.map((item, index) => {
      if (item !== undefined) {
        if (item.componenti[0] === undefined) {
          if (item.tipoContenuto === "TABELLA_REPARTI") {
            sottoParagrafiArray.push({
              index: index,
              value: (
                <CustomTableForManualeForParti
                  key={index}
                  aggiornaReparti={this.aggiornaReparti}
                  isParagrafoBlocked={this.state.currentTabValue.isBlocked}
                  mode={"Definizione_Reparto"}
                  kind={this.props.mode}
                  firstElement={true}
                  handleError={this.handleError}
                  titoloInizialeSottoparagrafo={item.titolo}
                  handleInvalidToken={this.handleInvalidToken}
                  idManuale={this.state.idManuale}
                  modalTitle={item.titolo}
                  numberOfRows={this.state.numberOfLines}
                ></CustomTableForManualeForParti>
              ),
            });
          } else if (item.tipoContenuto === "TABELLA_LOCALI") {
            sottoParagrafiArray.push({
              index: index,
              value: (
                <CustomTableForManualeForParti
                  key={index}
                  aggiornaLocali={this.aggiornaLocali}
                  isParagrafoBlocked={this.state.currentTabValue.isBlocked}
                  mode={"Descrizione_Reparto"}
                  handleError={this.handleError}
                  firstElement={true}
                  handleInvalidToken={this.handleInvalidToken}
                  titoloInizialeSottoparagrafo={item.titolo}
                  idManuale={this.state.idManuale}
                  modalTitle={item.titolo}
                  numberOfRows={this.state.numberOfLines}
                ></CustomTableForManualeForParti>
              ),
            });
          } else if (item.tipoContenuto === "TABELLA_PROCEDURE") {
            sottoParagrafiArray.push({
              index: index,
              value: (
                <CustomTableForManualeForParti
                  key={index}
                  isParagrafoBlocked={this.state.currentTabValue.isBlocked}
                  idParagrafo={item.paragrafoId}
                  indexTab={index}
                  mode={"Definizione_Procedure_Autocontrollo"}
                  bringDataToParent={this.bringDataToParentAutocontrollo}
                  bringDataAfterExchange={
                    this.bringDataToParentAutocontrolloAfterExchange
                  }
                  bringTextToParent={this.bringTextToParentAutocontrollo}
                  bringTitleToParent={this.bringTitleToParent}
                  sottoParagrafiData={this.state.sottoParagrafiData}
                  handleError={this.handleError}
                  firstElement={true}
                  itemScheda={item}
                  handleInvalidToken={this.handleInvalidToken}
                  titoloInizialeSottoparagrafo={item.titolo}
                  idManuale={this.state.idManuale}
                  modalTitle={item.titolo}
                  numberOfRows={this.state.numberOfLines}
                ></CustomTableForManualeForParti>
              ),
            });
          }
        } else if (item.tipoContenuto === "TABELLA_PROCEDURE") {
          sottoParagrafiArray.push({
            index: index,
            value: (
              <CustomTableForManualeForParti
                key={index}
                isParagrafoBlocked={this.state.currentTabValue.isBlocked}
                indexTab={index}
                bringTitleToParent={this.bringTitleToParent}
                idParagrafo={item.paragrafoId}
                mode={"Definizione_Procedure_Autocontrollo"}
                bringDataAfterExchange={
                  this.bringDataToParentAutocontrolloAfterExchange
                }
                bringDataToParent={this.bringDataToParentAutocontrollo}
                bringTextToParent={this.bringTextToParentAutocontrollo}
                sottoParagrafiData={this.state.sottoParagrafiData}
                handleError={this.handleError}
                firstElement={true}
                itemScheda={item}
                handleInvalidToken={this.handleInvalidToken}
                rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo}
                titoloInizialeSottoparagrafo={item.titolo}
                idManuale={this.state.idManuale}
                modalTitle={item.titolo}
                numberOfRows={this.state.numberOfLines}
              ></CustomTableForManualeForParti>
            ),
          });
        } else {
          if (item.componenti[0].tipo === "BLOCCO_TESTO") {
            if (index === 0) {
              if (item.titolo === null) {
                sottoParagrafiArray.push({
                  index: index,
                  value: (
                    <NewSottoparagrafoTestoEImmagineForParti
                      key={index}
                      isParagrafoBlocked={this.state.currentTabValue.isBlocked}
                      sottoParagrafiData={this.state.sottoParagrafiData}
                      bringDataToParent={this.bringDataToParent}
                      deleteImage={this.deleteImageFromSottoparagrafo}
                      handleError={this.handleError}
                      handleInvalidToken={this.handleInvalidToken}
                      handleEditChange={this.handleEditChange}
                      immaginiParagrafo={item.immagini}
                      bringTitleToParent={this.bringTitleToParent}
                      bringImageToParent={this.bringImageToParent}
                      testoSottoParagrafo={item.componenti[0].contenuto}
                      indexValue={index}
                      firstElement={true}
                      titoloInizialeSottoparagrafo={
                        item.componenti[0].titolo === null
                          ? ""
                          : item.componenti[0].titolo
                      }
                      rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo}
                      idImmagine={index}
                      borderWidth={"100%"}
                      componentHeight={"50%"}
                      componentWidth={"50%"}
                      photoWidth={"100%"}
                      photoHeight={"100%"}
                    ></NewSottoparagrafoTestoEImmagineForParti>
                  ),
                });
              } else {
                sottoParagrafiArray.push({
                  index: index,
                  value: (
                    <NewSottoparagrafoTestoEImmagineForParti
                      key={index}
                      handleEditChange={this.handleEditChange}
                      isParagrafoBlocked={this.state.currentTabValue.isBlocked}
                      sottoParagrafiData={this.state.sottoParagrafiData}
                      bringDataToParent={this.bringDataToParent}
                      deleteImage={this.deleteImageFromSottoparagrafo}
                      handleError={this.handleError}
                      handleInvalidToken={this.handleInvalidToken}
                      immaginiParagrafo={item.immagini}
                      bringTitleToParent={this.bringTitleToParent}
                      bringImageToParent={this.bringImageToParent}
                      testoSottoParagrafo={item.componenti[0].contenuto}
                      indexValue={index}
                      firstElement={true}
                      titoloInizialeSottoparagrafo={
                        item.componenti[0].titolo === null
                          ? "Nuovo Sottoparagrafo"
                          : item.componenti[0].titolo
                      }
                      rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo}
                      idImmagine={index}
                      borderWidth={"100%"}
                      componentHeight={"50%"}
                      componentWidth={"50%"}
                      photoWidth={"100%"}
                      photoHeight={"100%"}
                    ></NewSottoparagrafoTestoEImmagineForParti>
                  ),
                });
              }
            } else {
              /*if(item.componenti[0].contenuto === ""){
                  sottoParagrafiArray.push({index:item.numeroComponente,value: <BoxTitoloManuale indexValue={item.numeroComponente} handleError={this.handleError} deleteImage={this.deleteImageFromSottoparagrafo} handleInvalidToken={this.handleInvalidToken} immaginiParagrafo={item.immagini} titoloInizialeSottoparagrafo={item.titolo === null ? "Nuovo Sottoparagrafo" : item.titolo} bringImageToParent={this.bringImageToParent} bringTitleToParent={this.bringTitleToParent}  rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo} idImmagine={item.numeroComponente} borderWidth={"100%"} componentHeight={"50%"} componentWidth={"50%"} photoWidth={"100%"} photoHeight={"100%"}></BoxTitoloManuale>});
                }
                else if(item.immagini.length === 0){
                  sottoParagrafiArray.push({index:item.numeroComponente,value:<NewSottoparagrafoTesto bringDataToParent={this.bringDataToParent} bringTitleToParent={this.bringTitleToParent} indexValue={item.numeroComponente} testoSottoParagrafo={item.componenti[0].contenuto} componentHeight={"50%"} componentWidth={"50%"} rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo} titoloInizialeSottoparagrafo={item.titolo === null ? "Nuovo Sottoparagrafo" : item.titolo}></NewSottoparagrafoTesto>})
                }
                else{*/
              sottoParagrafiArray.push({
                index: index,
                value: (
                  <NewSottoparagrafoTestoEImmagineForParti
                    key={index}
                    handleEditChange={this.handleEditChange}
                    isParagrafoBlocked={this.state.currentTabValue.isBlocked}
                    sottoParagrafiData={this.state.sottoParagrafiData}
                    moveUpward={this.handleSottoparagrafoMovementUpward}
                    moveDownward={this.handleSottoparagrafoMovementDownward}
                    indexValue={index}
                    deleteImage={this.deleteImageFromSottoparagrafo}
                    handleError={this.handleError}
                    handleInvalidToken={this.handleInvalidToken}
                    immaginiParagrafo={item.immagini}
                    bringDataToParent={this.bringDataToParent}
                    bringTitleToParent={this.bringTitleToParent}
                    bringImageToParent={this.bringImageToParent}
                    testoSottoParagrafo={item.componenti[0].contenuto}
                    firstElement={false}
                    titoloInizialeSottoparagrafo={
                      item.titolo === null
                        ? "Nuovo Sottoparagrafo"
                        : item.titolo
                    }
                    rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo}
                    idImmagine={index}
                    borderWidth={"100%"}
                    componentHeight={"50%"}
                    componentWidth={"50%"}
                    photoWidth={"100%"}
                    photoHeight={"100%"}
                  ></NewSottoparagrafoTestoEImmagineForParti>
                ),
              });
              //}
            }
          }
          if (item.componenti[0].tipo === "TABELLA") {
            if (item.componenti[0].organigramma) {
              sottoParagrafiArray.push({
                index: index,
                value: (
                  <CustomTableForManualeForParti
                    key={index}
                    isParagrafoBlocked={this.state.currentTabValue.isBlocked}
                    firstElement={true}
                    sottoParagrafiData={this.state.sottoParagrafiData}
                    mode={"Organigramma"}
                    changeEditMode={this.handleEditChange}
                    kind={this.props.mode}
                    idParagrafo={item.paragrafoId}
                    handleError={this.handleError}
                    bringDataToParentAutocontrolloOrganigramma={
                      this.bringDataToParentAutocontrolloOrganigramma
                    }
                    handleInvalidToken={this.handleInvalidToken}
                    indexTab={index}
                    idManuale={this.state.idManuale}
                    modalTitle={item.titolo}
                    titoloInizialeSottoparagrafo={item.titolo}
                    numberOfRows={this.state.numberOfLines}
                  ></CustomTableForManualeForParti>
                ),
              });
            } else {
              sottoParagrafiArray.push({
                index: index,
                value: (
                  <CustomTableForManualeForParti
                    key={index}
                    isParagrafoBlocked={this.state.currentTabValue.isBlocked}
                    mode={"Custom"}
                    updateCustomTable={this.aggiornaTabellaCustomHandler}
                    moveUpward={this.handleSottoparagrafoMovementUpward}
                    moveDownward={this.handleSottoparagrafoMovementDownward}
                    titoloParagrafo={
                      item.titolo
                    }
                    sottoParagrafiData={this.state.sottoParagrafiData}
                    idParagrafo={item.paragrafoId}
                    modalTitle={
                      item.titolo
                    }
                    titoloInizialeSottoparagrafo={
                      item.titolo
                    }
                    handleError={this.handleError}
                    bringTitleToParent={this.bringTitleToParent}
                    handleInvalidToken={this.handleInvalidToken}
                    idManuale={this.state.idManuale}
                    indexTab={index}
                    handleRimuoviTabella={this.handleRimuoviTabella}
                    rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo}
                    columns={this.state.colonneTabellaCustom}
                    numberOfRows={this.state.numberOfLines}
                  ></CustomTableForManualeForParti>
                ),
              });
            }
          }
          if (item.componenti[0].tipo === "TABELLA_PERSONALIZZATA") {
            let contenuto = item.componenti[0].contenuto;
            let colonne = item.componenti[0].definizioniColonne;
            var array = JSON.parse("[" + colonne + "]");
            this.setState({
              colonneTabellaCustom: array[0],
            });
            sottoParagrafiArray.push({
              index: index,
              value: (
                <CustomTableForManualeForParti
                  key={index}
                  isParagrafoBlocked={this.state.currentTabValue.isBlocked}
                  sottoParagrafiData={this.state.sottoParagrafiData}
                  mode={"Custom"}
                  updateCustomTable={this.aggiornaTabellaCustomHandler}
                  moveUpward={this.handleSottoparagrafoMovementUpward}
                  moveDownward={this.handleSottoparagrafoMovementDownward}
                  modalTitle={
                    item.titolo
                  }
                  titoloParagrafo={
                    item.titolo
                  }
                  titoloInizialeSottoparagrafo={
                    item.titolo
                  }
                  handleError={this.handleError}
                  bringTitleToParent={this.bringTitleToParent}
                  contenuto={contenuto}
                  handleInvalidToken={this.handleInvalidToken}
                  idManuale={this.state.idManuale}
                  idParagrafo={item.paragrafoId}
                  indexTab={index}
                  handleRimuoviTabella={this.handleRimuoviTabella}
                  rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo}
                  columns={array[0]}
                  numberOfRows={this.state.numberOfLines}
                ></CustomTableForManualeForParti>
              ),
            });
          }
        }
      }
      item.numeroComponente = index;
    });
    numberToIncrease = sottoParagrafiArray.length;
    this.setState(
      {
        sottoParagrafi: [...sottoParagrafiArray],
        numberOfSottoparagrafo: numberToIncrease,
        sottoParagrafoIndex: numberToIncrease,
        sottoParagrafiData: sottoParagrafiData,
        colonneTabellaCustom: [],
      },
      () => {}
    );
  };

  handleInvalidToken = () => {
    this.handleError(
      true,
      "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi."
    );
    clearToken();
    window.setTimeout(function () {
      window.location.href = "/";
    }, 4000);
  };
  handleError = (showModal, errorMessage) => {
    this.setState({
      errorTitle:"Errore",
      errorDialogVisible: showModal,
      errorDialogMessage: errorMessage,
    });
  };
  handleSuccess = (showModal, successMessage) => {
    this.setState({
      errorTitle:"Successo",
      errorDialogVisible: showModal,
      errorDialogMessage: successMessage,
    });
  }
  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false,
    });
  };
  handleChangeTipo = (event) => {
    this.setState({
      tipoDiTabella: event.target.value,
    });
  };
  handleNumbersOfLines = (event) => {
    this.setState({
      numberOfLines: event.target.value,
    });
  };
  aggiornaTabellaCustomHandler = (recordData) => {
    this.setState({
      edit: true,
    });
  };

  aggiornaReparti = (repartiData, index) => {
    this.setState(
      {
        updatedReparti: repartiData,
        edit: true,
      },
      () => {}
    );
  };
  launchRepartiUpdate = (reparti) => {
    updateReparti(this.state.idManuale, reparti)
      .then((result) => {
        //window.location.reload(true);
        this.setState({
          updatedReparti: null,
        },() => {
          this.handleSuccess(true, "Paragrafo salvato con successo");
        });
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.handleError(true, error.message);
        }
      });
  };
  aggiornaLocali = (records) => {
    let recordsToChange = [...records];
    /*recordsToChange.map(item => {
          delete item['nomeReparto']
          delete item['repartiToSelect']
        })*/
    this.setState(
      {
        updatedLocali: recordsToChange,
        edit: true,
      },
      () => {}
    );
  };
  launchLocaliUpdate = (locali) => {
    let recordsToSend = [...locali];
    recordsToSend.map((item) => {
      delete item["nomeReparto"];
      delete item["repartiToSelect"];
    });
    let body = {
      manualeId: this.state.idManuale,
      locali: recordsToSend,
    };
    updateLocaliManuale(body)
      .then((result) => {
        this.setState({
          updatedLocali: null,
        },() => {
          this.handleSuccess(true, "Paragrafo salvato con successo");
        });
        //this.getLocaliForTabella();
        //window.location.reload(true);
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.handleError(true, error.message);
        }
      });
  };
  handleAggiuntaSottoparagrafi = () => {
    if (
      !this.state.currentTabValue.isBlocked ||
      localStorage.getItem("superconsulente") === "true" ||
      localStorage.getItem("consulenteId") === "null"
    ) {
      let numberToIncrease = this.state.numberOfSottoparagrafo;
      let subParagraphArray = [...this.state.sottoParagrafi];
      let currentParagraph = this.state.currentTabValue;
      let subParagraphDataArray = [...this.state.sottoParagrafiData];
      if (this.state.modalitaSottoparagrafo === "Immagine") {
        let newSubparagraphArrayData = {
          tipo: "SOTTOPARAGRAFO",
          id: null,
          paragrafoId: currentParagraph.id,
          parentComponentId: null,
          numeroComponente: this.state.sottoParagrafoIndex,
          titolo: null,
          numeroSottoparagrafo: null,
          tipoContenuto: null,
          componenti: [
            {
              tipo: "BLOCCO_TESTO",
              id: null,
              paragrafoId: currentParagraph.id,
              parentComponentId: null,
              numeroComponente: this.state.sottoParagrafoIndex,
              titolo: "",
              contenuto: "",
            },
          ],
          immagini: [],
        };
        subParagraphArray.push({
          index: this.state.sottoParagrafoIndex,
          value: (
            <BoxTitoloManuale
              isParagrafoBlocked={this.state.currentTabValue.isBlocked}
              deleteImage={this.deleteImageFromSottoparagrafo}
              indexValue={this.state.sottoParagrafoIndex}
              titoloInizialeSottoparagrafo={this.state.titoloSottoParagrafo}
              handleError={this.handleError}
              handleInvalidToken={this.handleInvalidToken}
              bringTitleToParent={this.bringTitleToParent}
              bringImageToParent={this.bringImageToParent}
              rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo}
              idImmagine={numberToIncrease}
              borderWidth={"100%"}
              componentHeight={"50%"}
              componentWidth={"50%"}
              photoWidth={"100%"}
              photoHeight={"100%"}
            ></BoxTitoloManuale>
          ),
        });
        subParagraphDataArray.push(newSubparagraphArrayData);
        let newIndex = this.state.sottoParagrafoIndex;
        newIndex += 1;
        this.setState({
          sottoParagrafoIndex: newIndex,
        });
      }
      if (this.state.modalitaSottoparagrafo === "Testo") {
        let newSubparagraphArrayData = {
          tipo: "SOTTOPARAGRAFO",
          id: null,
          paragrafoId: currentParagraph.id,
          parentComponentId: null,
          numeroComponente: this.state.sottoParagrafoIndex,
          titolo: null,
          numeroSottoparagrafo: null,
          tipoContenuto: null,
          componenti: [
            {
              tipo: "BLOCCO_TESTO",
              id: null,
              paragrafoId: currentParagraph.id,
              parentComponentId: null,
              numeroComponente: this.state.sottoParagrafoIndex,
              titolo: "",
              contenuto: "",
            },
          ],
          immagini: [],
        };
        subParagraphDataArray.push(newSubparagraphArrayData);
        subParagraphArray.push({
          index: this.state.sottoParagrafoIndex,
          value: (
            <NewSottoparagrafoTesto
              isParagrafoBlocked={this.state.currentTabValue.isBlocked}
              bringDataToParent={this.bringDataToParent}
              bringTitleToParent={this.bringTitleToParent}
              indexValue={this.state.sottoParagrafoIndex}
              componentHeight={"50%"}
              componentWidth={"50%"}
              rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo}
              titoloInizialeSottoparagrafo={this.state.titoloSottoParagrafo}
            ></NewSottoparagrafoTesto>
          ),
        });
        let newIndex = this.state.sottoParagrafoIndex;
        newIndex += 1;
        this.setState({
          sottoParagrafoIndex: newIndex,
        });
      }
      if (this.state.modalitaSottoparagrafo === "ImmagineAndTesto") {
        let newSubparagraphArrayData = {
          tipo: "SOTTOPARAGRAFO",
          id: null,
          paragrafoId: currentParagraph.id,
          parentComponentId: null,
          numeroComponente: this.state.sottoParagrafoIndex,
          titolo: null,
          numeroSottoparagrafo: null,
          tipoContenuto: null,
          componenti: [
            {
              tipo: "BLOCCO_TESTO",
              id: null,
              paragrafoId: currentParagraph.id,
              parentComponentId: null,
              numeroComponente: this.state.sottoParagrafoIndex,
              titolo: "",
              contenuto: "",
            },
          ],
          immagini: [],
        };
        subParagraphDataArray.push(newSubparagraphArrayData);
        this.setState({
          sottoParagrafiData: subParagraphDataArray,
        });
        subParagraphArray.push({
          index: this.state.sottoParagrafoIndex,
          value: (
            <NewSottoparagrafoTestoEImmagineForParti
              key={this.state.sottoParagrafoIndex}
              handleEditChange={this.handleEditChange}
              isParagrafoBlocked={this.state.currentTabValue.isBlocked}
              sottoParagrafiData={subParagraphDataArray}
              immaginiParagrafo={[]}
              deleteImage={this.deleteImageFromSottoparagrafo}
              moveUpward={this.handleSottoparagrafoMovementUpward}
              moveDownward={this.handleSottoparagrafoMovementDownward}
              firstElement={false}
              indexValue={this.state.sottoParagrafoIndex}
              handleError={this.handleError}
              handleInvalidToken={this.handleInvalidToken}
              bringDataToParent={this.bringDataToParent}
              bringTitleToParent={this.bringTitleToParent}
              bringImageToParent={this.bringImageToParent}
              titoloInizialeSottoparagrafo={this.state.titoloSottoParagrafo}
              rimuoviSottoparagrafo={this.handleRimuoviSottoparagrafo}
              idImmagine={numberToIncrease}
              borderWidth={"100%"}
              componentHeight={"50%"}
              componentWidth={"50%"}
              photoWidth={"100%"}
              photoHeight={"100%"}
            ></NewSottoparagrafoTestoEImmagineForParti>
          ),
        });
        let newIndex = this.state.sottoParagrafoIndex;
        newIndex += 1;
        this.setState({
          sottoParagrafoIndex: newIndex,
        });
      }
      numberToIncrease += 1;
      this.setState({
        numberOfSottoparagrafo: numberToIncrease,
        sottoParagrafi: subParagraphArray,
        isSottoparagrafiModalOpen: false,
        edit: true,
        titoloSottoParagrafo: "Nuovo Sottoparagrafo",
      });
    } else {
      alert(
        "Attenzione, operazione non permessa poichè il paragrafo è bloccato"
      );
    }
  };

  bringDataToParent = (data, index) => {
    let newSubparagraphArray = [...this.state.sottoParagrafiData];
    let elementToChange = newSubparagraphArray.filter(
      (item) => item.numeroComponente === index
    );
    elementToChange[0].componenti[0].contenuto = data;
    this.setState({
      sottoParagrafiData: newSubparagraphArray,
    },() => {
      this.handleEditChange();
    });
  };
  bringDataToParentAutocontrollo = (data, index) => {
    let newSubparagraphArray = [...this.state.sottoParagrafiData];
    let elementToChange = newSubparagraphArray.filter(
      (item) => item.numeroComponente === index
    );
    elementToChange[0].componenti = data.componenti[1].componenti;
    this.setState({
      sottoParagrafiData: newSubparagraphArray,
    });
  };
  bringDataToParentAutocontrolloAfterExchange = (data, index) => {
    let newSubparagraphArray = [...this.state.sottoParagrafiData];
    let elementToChange = newSubparagraphArray.filter(
      (item) => item.numeroComponente === index
    );
    elementToChange[0].componenti = data;
    this.setState({
      sottoParagrafiData: newSubparagraphArray,
    });
  };
  bringDataToParentAutocontrolloOrganigramma = (data, index) => {
    let newSubparagraphArray = [...this.state.sottoParagrafiData];
    let elementToChange = newSubparagraphArray.filter(
      (item) => item.numeroComponente === index
    );
    elementToChange[0].componenti = data;
    this.setState({
      sottoParagrafiData: newSubparagraphArray,
    });
  };
  bringTextToParentAutocontrollo = (data, index, indexElement) => {
    let newSubparagraphArray = [...this.state.sottoParagrafiData];
    let elementToChange = newSubparagraphArray.filter(
      (item) => item.numeroComponente === index
    );
    elementToChange = data;
    this.setState({
      sottoParagrafiData: elementToChange,
    });
  };
  bringTitleToParent = (title, index) => {
    let newSubparagraphArray = [...this.state.sottoParagrafiData];
    let elementToChange = newSubparagraphArray.filter(
      (item) => item.numeroComponente === index
    );
    elementToChange[0].titolo = title;
    this.setState({
      sottoParagrafiData: newSubparagraphArray,
    },() => {
      this.handleEditChange();
    });
  };
  bringImageToParent = (image, names, index) => {
    let newSubparagraphArray = [...this.state.sottoParagrafiData];
    let elementToChange = newSubparagraphArray.filter(
      (item) => item.numeroComponente === index
    );
    postUploadImmaginiSottoparagrafi(image[image.length - 1])
      .then((result) => {
        elementToChange[0].immagini.push(result);
        this.setState({
          sottoParagrafiData: newSubparagraphArray,
        });
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.handleError(true, error.message);
        }
      });
  };
  deleteImageFromSottoparagrafo = (immagini, index) => {
    let newSubparagraphArray = [...this.state.sottoParagrafiData];
    newSubparagraphArray[index].immagini = immagini;
    this.setState({
      sottoParagrafiData: newSubparagraphArray,
    });
  };


    render(){
        return(
            <div
              style={{
                flex: 1 /*border:"3px solid lightGrey",borderRadius:"10px",padding:30*/,
              }}
            >
            <div>
             <TextField onInput={(event) => {
                    let titoloParagrafo = this.state.titoloParagrafo;
                    titoloParagrafo = event.target.value;
                    this.setState({
                        titoloParagrafo:titoloParagrafo
                    })
                }} disabled={!this.state.isTitoloEditable} InputProps={{ disableUnderline: true}} inputProps={{style: {fontSize:"2.125rem",textAlign:"center",color:"black" }}} value={this.state.titoloParagrafo} style={{fontSize:30,width: "35%"}}></TextField>
              <IconButton style={{marginTop:5 }} onClick = {() => {
                   this.state.isTitoloEditable ? this.setState({isTitoloEditable:false}) : this.setState({isTitoloEditable:true})
                }}>{this.state.isTitoloEditable ? <Edit htmlColor="#194074"></Edit> : <Edit></Edit>}</IconButton>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                  <Box
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {this.state.sottoParagrafi.map((item,index) => {
                        return item.value;
                    })}
                  </Box>
              </div>
              <Box p={1} style={styles.buttonContainer}>
                      <ActionButton
                        disabled={false}
                        grayVersion={false}
                        label="Aggiungi sottoparagrafo"
                        onClick={() => {
                          this.setState(
                            {
                              modalitaSottoparagrafo: "ImmagineAndTesto",
                            },
                            () => {
                              this.handleAggiuntaSottoparagrafi();
                            }
                          );
                        }}
                      />
                      <ActionButton
                        disabled={false}
                        grayVersion={false}
                        label="Aggiungi tabella"
                        onClick={() => {
                          if (
                            !this.state.currentTabValue.isBlocked ||
                            localStorage.getItem("superconsulente") ===
                              "true" ||
                            localStorage.getItem("consulenteId") === "null"
                          ) {
                            this.setState({
                              isModalOpen: !this.state.isModalOpen,
                            });
                          } else {
                            alert(
                              "Attenzione, operazione non permessa poichè il paragrafo è bloccato"
                            );
                          }
                        }}
                      />
                </Box>
              <div style={{display:"flex",justifyContent:"space-evenly"}}>
              <GoBackButton label="Indietro"></GoBackButton>
              <ActionButton label="Salva Paragrafo" 
              disabled={false}
              grayVersion={false} 
              onClick={() => {
                let paragrafoToPass = {
                  id: this.state.paragrafoId,
                  titolo: this.state.titoloParagrafo,
                  manualeId: this.state.idManuale,
                  componenti:this.state.sottoParagrafiData,
                }
                updateSottoparagrafi(paragrafoToPass).then((result) => {
                  if (this.state.updatedReparti !== null) {
                              this.launchRepartiUpdate(
                                this.state.updatedReparti
                              );
                            } else if (this.state.updatedLocali !== null) {
                              this.launchLocaliUpdate(this.state.updatedLocali);
                            } else {
                              this.handleSuccess(true, "Paragrafo salvato con successo");
                            }
                }).catch((error) => {
                  if (error.status === 403) {
                    this.handleInvalidToken();
                  } else {
                    this.handleError(true, error.message);
                  }
                })
              }}></ActionButton>      
                  </div>
              <ErrorDialog
          title = {this.state.errorTitle}
          open={this.state.errorDialogVisible}
          message={this.state.errorDialogMessage}
          onCloseButtonClicked={this.closeErrorDialog}
            />
          <Modal
          open={this.state.isModalOpen}
          onClose={() => {
            this.setState({
              isModalOpen: !this.state.isModalOpen,
              colonneTabellaCustom: [],
            });
          }}
        >
          <div style={styles.modalcontainer}>
            <div style={styles.modalsectioncontainer}>
              <Typography
                variant="h6"
                style={{ paddingTop: "8px", paddingBottom: "8px" }}
              >
                {"Creazione tabella per la sezione " +
                  this.state.currentTabValue.label}
              </Typography>
              {/*<Typography variant="h6" style={{ paddingTop: '8px', paddingBottom: '8px' }} >
                                Tipologia Di Tabella
                        </Typography>
                        <Select
                        style={{alignSelf:"center",width:500}}
                        value={this.state.tipoDiTabella}
                              name="tipoDiTabella"
                              onChange={this.handleChangeTipo}
                        >
                          <MenuItem value={'Custom'}>Personalizzato</MenuItem>
                        </Select>*/}
              <Typography variant="h6" style={styles.tableModalText}>
                Cliccare su "Aggiungi colonna" per aggiungere una colonna alla
                tabella
              </Typography>
              <Box style={{ marginBottom: "2%" }}>
                {this.state.colonneTabellaCustom.map((item, index) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      <Typography>Nome colonna: </Typography>
                      <TextField
                        style={{ marginLeft: 10, marginTop: -2, width: "50%" }}
                        onChange={(e) => {
                          let columnName = e.target.value;
                          if (columnName === "") {
                            columnName = " ";
                          }
                          let array = this.state.colonneTabellaCustom;
                          array[index].header = columnName;
                          this.setState({
                            colonneTabellaCustom: array,
                          });
                        }}
                        value={item.header}
                      ></TextField>
                      <IconButton
                        size="small"
                        style={{ marginTop: 0 }}
                        onClick={() => {
                          let columnArray = [
                            ...this.state.colonneTabellaCustom,
                          ];
                          columnArray.splice(index, 1);
                          this.setState(
                            {
                              colonneTabellaCustom: columnArray,
                            },
                            () => {
                              
                            }
                          );
                        }}
                      >
                        <CloseIcon></CloseIcon>
                      </IconButton>
                    </div>
                  );
                })}
              </Box>
              <ActionButton
                grayVersion={false}
                disabled={false}
                label={"Aggiungi colonna"}
                onClick={() => {
                  if (this.state.colonneTabellaCustom.length < 16) {
                    let updateColumn = [...this.state.colonneTabellaCustom];
                    let valueToSave = this.state.colonneTabellaCustom.length;
                    updateColumn.push({
                      header: "Colonna " + valueToSave++,
                      id: "Colonna" + valueToSave++,
                    });
                    this.setState({
                      colonneTabellaCustom: updateColumn,
                    });
                  } else {
                    alert("Errore, sono state inserite più di 16 colonne");
                  }
                }}
              ></ActionButton>

              <Typography
                variant="h6"
                style={{ paddingTop: "10%", paddingBottom: "8px" }}
              ></Typography>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <ActionButton
                grayVersion={false}
                disabled={false}
                onClick={this.handleAggiuntaTable}
                label="Aggiungi Tabella"
              ></ActionButton>
            </div>
          </div>
        </Modal>
              </div>
              
        )
        
    }
}