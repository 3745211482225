import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Background from '../../background.png';
import Background2 from '../../background2.png';
import { clearToken } from '../../utils/storage.js';
import theme from '../../theme.js';

const styles = {
    mainContainer: {
        textAlign: 'center',
        color: 'black',
        padding: '10px'
    }
}

export default function LogoutPage() {

    const [height, setHeight] = useState(window.innerHeight);
    const [width, setWidth] = useState(window.innerWidth);

   useEffect(() => {
        window.addEventListener('resize', updateDimensions);
    }, []);
    useEffect(() => {
        return () => {
            console.log("Removed");
          window.removeEventListener('resize', updateDimensions);
        };
      }, []);

    const updateDimensions = () => {
        setHeight(window.innerHeight);
        setWidth(window.innerWidth);
        //this.setState({ width: window.innerWidth, height: window.innerHeight });
    };

    return (
        <div style={{
          textAlign: 'center',
             color: 'black',
             padding: '10px',
             minHeight: '100vh',
             backgroundImage: width > 1299  ? `url("${Background}"),url("${Background2}")` : `url("")`,
             backgroundRepeat: 'no-repeat',
             backgroundAttachment: 'fixed',
             backgroundPositionX:width > 1899 ? "110%, -10%" : width > 1799 ? "110%, -10%" : width > 1599 ? "110%, -10%" : width > 1399 ? "110%, -10%" : width > 1299 ? "120%, -20%" : null,
        }}>
            <Typography variant="h5" style={{ padding:'30px' }} >Effettua il log out dalla piattaforma.</Typography>
            <div style={{ textAlign: 'center' }} >
                <Button 
                    variant="contained"
                    size="large"
                    onClick={() => {
                        clearToken(); 
                        window.location.href = "/";
                    }} 
                    style={{ 
                        color: theme.palette.secondary.main, 
                        backgroundColor: theme.palette.utilPalette.primary,
                        margin: 10,
                        borderRadius:30,
                    }} 
                >
                    Logout
                </Button>
            </div>
        </div>
    );
}