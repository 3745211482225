import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { NavLink } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import theme from '../../theme.js';
import Background from '../../background.png';
import Background2 from '../../background2.png';
import image from '../../logoOrologio.png';
import image2 from "../../logoCristoforo.png";

import { retrieveToken, setBrandId } from '../../utils/storage.js';
import { getCurrentBrand } from '../../utils/api/brand_api.js';

const styles = {
    loginButton: {
        background: "linear-gradient(to right,#194074,#438c5a)",
        borderRadius: '30px',
        border: 0,
        color:"white",
        height: 54,
        width: 104,
        fontSize: 16,
        fontWeight: 'bold',
    },
    controlpanelButton: {
        background: "linear-gradient(to right,#194074,#438c5a)",
        borderRadius: '30px',
        border: 0,
        color: "white",
        height: 68,
        width: 234,
        fontSize: 16,
        fontWeight: 'bold',
    }
}

export default class HomePage extends Component {

    constructor() {
        super();
        this.state = {
            isAuthenticated: false,
            message: 'Effettua il Login per gestire la piattaforma.',
            img: image,
            img2: image2,
            titleImg: 'BS_AUDIT_logo',
            titleImg2: 'Cristoforo_logo',
            width:window.innerWidth,
            height:window.innerHeight,
        };
    }
    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
      };
      componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
      }

    componentDidMount() {
        const token = retrieveToken();
        window.addEventListener('resize', this.updateDimensions);
        if (token !== null) {
            this.setState({
                isAuthenticated: true,
                message: 'Clicca sul pulsante per procedere'
            });
        } else {
            this.setState({
                isAuthenticated: false,
                message: 'Effettua il Login per gestire la piattaforma.'
            });
        }
        getCurrentBrand(0,10,"id")
        .then(result => {
            if (result.length > 0) {
                setBrandId(result[0].id);
            }
        })
        .catch(error => {
            console.log("ERRORE CARICAMENTO BRAND: " + error.message);
        });
    }

    render() {

        var accessButton = this.state.isAuthenticated ? (
            <NavLink to="/amministrazione/esercizi_commerciali" style={{ textDecoration: 'none' }}>
                <Button variant="contained" style={styles.controlpanelButton}>Pannello Controllo</Button>
            </NavLink>
        ) : (
                <NavLink to="/login" style={{ textDecoration: 'none' }}>
                    <Button variant="contained" style={styles.loginButton}>Login</Button>
                </NavLink>
            );
        var msg = this.state.message;

        return (
            <React.Fragment>
                <CssBaseline />
                <Container id='home_container' fixed style={{
                    minWidth:"100%",
                    minHeight:"85vh",
                    backgroundImage: this.state.width > 1299  ? `url("${Background}"),url("${Background2}")` : `url("")`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '',
                    backgroundAttachment: 'fixed',
                    backgroundPositionY:"50%",
                    backgroundPositionX:this.state.width > 1899 ? "110%, -10%" : this.state.width > 1799 ? "110%, -10%" : this.state.width > 1599 ? "110%, -10%" : this.state.width > 1399 ? "110%, -10%" : this.state.width > 1299 ? "120%, -20%" : null,
                    }}>
                    <Typography component="div" style={{display:"flex",flexDirection:"column",flex:1}} >
                        <Typography component="div" style={{ paddingTop: '10px', paddingBottom: '10px',flex:1 }} >
                            <Typography variant="h4" style={{ padding: '20px',fontSize:40, color:'black',marginBottom:50 }} >
                                Pannello di controllo di BS-UTILITY
                            </Typography>
                            <div style={{flex:2}}><img src={this.state.img} alt={this.state.titleImg} style={{width:'400px',marginBottom:50}} /><img src={this.state.img2} alt={this.state.titleImg2} style={{width:'400px',marginBottom:230}} /></div>
                        </Typography>
                        <Typography component="div" style={{ marginTop: '20px', marginBottom: '20px' }} >
                            <Typography variant="h6" style={{ padding: '10px', color:'black'  }} >
                                {msg}
                            </Typography>
                            <Typography component="div" style={{ padding: '10px' }} >
                                {accessButton}
                            </Typography>
                        </Typography>
                    </Typography>
                </Container>
            </React.Fragment>
        );
    }

}