import React from 'react';
import { Component } from 'react';
import { Fragment } from 'react';
import { TableCell, TableRow, Checkbox, IconButton } from '@material-ui/core';
import { ExpandLess, ExpandMore, Delete, Edit } from '@material-ui/icons';
import moment from 'moment';



class SottoparagrafoParti extends Component{
    constructor(props){
        super(props);
        this.state = {
           
        }
    }
    shouldComponentUpdate(nextProps,nextState){
      return true;
    }

    render(){
        return <Fragment key={this.props.index4}>
              <TableRow>
                <TableCell style={{border:0}} align="center"><Checkbox onClick={() => {
                  this.props.handleAggiuntaAlManualeSottoparagrafo(this.props.index,this.props.index2,this.props.index3,this.props.index4);
                }} value={this.props.isSelectedForPDF}></Checkbox></TableCell>
                <TableCell style={{border:"2px solid lightgray", borderRight:0}} align="center" component="th" scope="row">
                {this.props.posizione}
                </TableCell>
                <TableCell style={{border:"2px solid lightgray", borderRight:0}} align="center">{this.props.titolo}</TableCell>
                <TableCell style={{border:"2px solid lightgray", borderRight:0}} align="center">{this.props.base}</TableCell>
                <TableCell style={{border:"2px solid lightgray", borderRight:0}} align="center">{this.props.daEditare ? "Si" : "No"}</TableCell>
                <TableCell style={{border:"2px solid lightgray"}} align="center">{moment(this.props.dataEditing).format("DD/MM/YYYY")}</TableCell>
                <TableCell style={{border:"2px solid lightgray"}} align="center"><IconButton onClick = {() => {
                  this.props.handleParagrafoRedirect(this.props.item3);
                }}><Edit htmlColor="#194074"></Edit></IconButton></TableCell>
                <TableCell style={{border:0}}>
                <div style={{display:"flex",flexDirection:"row"}}>
                {this.props.index4 !== 0 ? <div style={{display:"flex",flexDirection:"column"}}>
                <IconButton  disabled={!this.props.activateMovement} size="small" onClick = {() => {this.props.handleSottoparagrafoMovementUpward(this.props.index,this.props.index2,this.props.index3,this.props.index4)}}><ExpandLess htmlColor={this.props.activateMovement ? "#194074" : '#e0e0e0'}></ExpandLess></IconButton>
                <IconButton  disabled={!this.props.activateMovement} size="small" onClick = {() => {this.props.handleSottoparagrafoMovementDownward(this.props.index,this.props.index2,this.props.index3,this.props.index4)}}><ExpandMore htmlColor={this.props.activateMovement ? "#194074" : '#e0e0e0'}></ExpandMore></IconButton>
                </div> : null}
                <IconButton size="medium" disabled={this.props.activateMovement} onClick = {() => {this.props.handleSottoparagrafoDelete(this.props.index,this.props.index2,this.props.index3,this.props.index4)}}><Delete htmlColor={this.props.activateMovement ? '#e0e0e0' :"#194074"}></Delete></IconButton>
                </div>
                </TableCell>
              </TableRow>
              </Fragment>
    }
}
export default SottoparagrafoParti;