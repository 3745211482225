import { Component } from "react";
import React from "react";
import Modal from '@material-ui/core/Modal';
import { Button, IconButton, TextField, Typography } from "@material-ui/core";
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { addNewTipologiaStrumentazione, getAttrezzature, getAttrezzatureCount, getTipologiaStrumentazione, updateAttrezzatureLocale } from "../../../utils/api/locali_e_attrezzature_api";
import selectTableHOC from "react-table/lib/hoc/selectTable";
import theme from '../../../theme';
import ReactTable from "react-table";
import ActionButton from "../../../components/ActionButton";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import GoToCreazioneManualeButton from "./GoToCreazioneManualeButton";

const styles = {
    modalcontainer: {
        flexDirection: "column",
        alignItems: 'center',
        justifyContent: 'space-evenly',
        textAlign: 'center',
        backgroundColor: 'white',
        color: 'black',
        //paddingTop: '26px',
        //paddingBottom: '26px',
        paddingTop: '6px',
        paddingBottom: '6px',
        paddingLeft: '10px',
        paddingRight: '10px',
        marginTop: '10%',
        marginBottom: '26px',
        boxShadow: '0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px #194074',
        marginLeft: '20%',
        marginRight: '20%',
        //height: '85%'
        overflowY: 'scroll',
        height: '200px',
        display:"flex",
    },
    miniModalContainer:{
      flexDirection: "column",
        alignItems: 'center',
        justifyContent: 'space-evenly',
        textAlign: 'center',
        backgroundColor: 'white',
        color: 'black',
        //paddingTop: '26px',
        //paddingBottom: '26px',
        paddingTop: '6px',
        paddingBottom: '6px',
        paddingLeft: '10px',
        paddingRight: '10px',
        marginTop: '26px',
        marginBottom: '26px',
        boxShadow: '0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px #194074',
        marginLeft: '20%',
        marginRight: '20%',
        //height: '85%'
        overflowY: 'scroll',
        height: '300px',
        display:"flex",
    },
    modalsectioncontainer: {
        flex: 1,
        display: "flex",
        //marginTop: 20,
        //marginBottom: 20,
        flexDirection: "column",
        justifyContent: 'space-evely',

    },
    buttoncontainer: {
      justifyContent: 'center',
      padding: '10px 0px',
    },
    modalTitle:{
      fontSize:20,
      alignSelf:"center",
    }
}

export default class ModalSelezioneModalita extends Component{
    constructor(props){
        super(props);
        this.state = {
            tipologiaItem:true,
        }
    }
    render(){
    return(
    <Modal
            open={this.props.open}
            onClose={this.props.onClose}
            >
            <div style={styles.modalcontainer}>
                    <div style={styles.modalsectioncontainer}>
                    <Typography style={styles.modalTitle}>Si desidera inserire i capitoli nel proprio manuale?</Typography>
                    <Select style={{marginTop:"5%"}} value = {this.state.tipologiaItem} onChange={(e) => {
                        this.setState({
                            tipologiaItem: e.target.value,
                        })
                    }}>
                        <MenuItem value={true}>Si</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                    </Select>
                    <div style={styles.buttoncontainer}>
                    <ActionButton isGreen label="Indietro" onClick = {() => this.props.onClose()} disabled={false}
                grayVersion={false}
                showAlert={false}></ActionButton>
                    <ActionButton label = {"Prosegui"} onClick = {() => {
                        this.props.onProseguiButton(this.state.tipologiaItem,this.props.selectedEsercizio);
                    }}></ActionButton>
                    {/*<GoToCreazioneManualeButton
                gruppoId={this.props.gruppoId}
                esercizioConsulente={this.props.selectedEsercizio}
                manualeBaseId={this.props.manualeBaseId}
                handleError={this.props.handleError}
                handleInvalidToken={this.props.handleInvalidToken}
                mode="Manuale"
                chapters={this.state.tipologiaItem}
                selectedConsulente={this.props.idConsulente}
                path={"/amministrazione/manuali/nuovomanuale"}
                label="Prosegui"
                disabled={false}
                grayVersion={false}
                showAlert={false}
                 />*/}
                        
                    </div>
                    </div>
            </div>

    </Modal>
    )}
}