import React, { Fragment } from "react";
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";

import SpinnerComponent from '../../../components/SpinnerComponent';
import TwoActionsAlertDialog from '../../../components/TwoActionsAlertDialog';
import AlertDialog from '../../../components/AlertDialog';
import ErrorDialog from '../../../components/ErrorDialog';
import IndietroButton from '../../../components/IndietroButton';
import FormManuale from './FormManuale';
import ModalAvvisoReparti from "./ModalAvvisoReparti";
import GoToPaginaCostruzioneManuale from "./GoToPaginaCostruzioneManuale";

import { clearToken } from '../../../utils/storage';
import { getRevisioneManuale, cancellaRevisione, updateRevisioneManuale, pubblicaManuale } from '../../../utils/api/revisioni_manuali_api';
import { getReparti } from '../../../utils/api/reparti';
import { getResponsabiliSicurezzaPerEsercizioDatoManualeId } from '../../../utils/api/responsabili_sicurezza_api';
import { getEserciziCommerciali } from '../../../utils/api/esercizi_commerciali_api';

import theme from '../../../theme.js';

import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

const styles = {
    root: {
        textAlign: 'center',
        color: 'black',
        padding: '20px 10px 10px 10px',
    },
    paper: {
        marginTop: '26px',
        marginBottom: '26px',
        boxShadow: '0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px #194074'
    },
    maincontainer: {
        padding: '0px 4px 10px 4px',
        maxWidth: '800px',
        minHeight: '480px'
    },
    sectioncontainer: {
        border: '1px solid #e0e0e0',
        borderRadius: '20px',
        marginTop: '10px',
        marginBottom: '10px',
        paddingTop: '10px',
        paddingBottom: '10px'
    },
    text: {
        width: '100%',
        padding: '0px 10px',
        //textAlign: 'left'
    }
}

class DettaglioManualeSicurezza extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            record: null,
            errorDialogVisible: false,
            errorDialogMessage: '',
            errorDialogTitle: null,
            loading: true,
            esercizi: [],
            saveButtonEnabled: false,
            saveMessageVisible: false,
            showPubblicatoMsg: false,
            checkbox: true,
            alertVisible: false,
            twoActionsAlertVisible: false,
            openAlertReparti: false,
        };
    }

    componentDidMount() {
        this.fetchRevisione();
    }

    handleInvalidToken = () => {
        const errorMessage = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
        this.showErrorDialog(null, errorMessage);
        clearToken();
        window.setTimeout(function () {
            window.location.href = "/";
        }, 4000);
    }

    showErrorDialog = (title, message) => {
        this.setState({
            errorDialogVisible: true,
            errorDialogTitle: title,
            errorDialogMessage: message
        });
    }

    closeErrorDialog = () => {
        this.setState({
            errorDialogVisible: false
        });

    }

    fetchRevisione = () => {
        getRevisioneManuale(this.props.revisioneManualeId)
            .then(result => {
                if (result.manuale.hidden) {
                    this.setState({
                        loading: false
                    });
                } else {
                    if (result.manuale.hidden) {
                        this.setState({
                            loading: false,
                            record: null
                        });
                    } else {
                        this.setState({
                            record: result,
                        });
                        this.fetchEserciziCommerciali(result);
                    }
                }
            })
            .catch(error => {
                if (error.status === 403) {
                    this.handleInvalidToken();
                } else {
                    this.setState({
                        loading: false
                    });
                    this.showErrorDialog(null, error.message);
                }
            });
    }

    fetchEserciziCommerciali = (revisioneManuale) => {
        let consulenteId = null;
        if (!this.props.superconsulenteView && this.props.consulenteId !== null) { // If user has role 'consulente':
            consulenteId = this.props.consulenteId;
        } else {
            // Otherwise, if user is admin or superconsulente then esercizi are filtered only by group, if the revisione is not assigned to a consulente.
            // In that case get all esercizi that are associated to this consulente.
            consulenteId = revisioneManuale.consulenteId === null ? null : revisioneManuale.consulenteId;
        }
        getEserciziCommerciali(this.props.gruppoId, consulenteId, null, 0, 3000, "id")
            .then(result => {
                this.setState({
                    esercizi: result,
                    loading: false
                });
            })
            .catch(error => {
                if (error.status === 403) {
                    this.handleInvalidToken();
                } else {
                    this.setState({
                        loading: false
                    });
                    this.showErrorDialog(null, error.message);
                }
            });
    }

    deleteRevisioneManuale = () => {
        cancellaRevisione(this.state.record.id, true)
            .then(result => {
                this.setState({
                    alertVisible: true
                });
            })
            .catch(error => {
                if (error.status === 403) {
                    //this.handleInvalidToken();
                } else {
                    this.setState({
                        loading: false
                    });
                    this.showErrorDialog(null, error.message);
                }
            });
    }

    updateRevisione = () => {
        updateRevisioneManuale(this.state.record)
            .then(result => {
                this.setState({
                    loading: false,
                    saveButtonEnabled: false,
                    saveMessageVisible: true
                });
            })
            .catch(error => {
                if (error.status === 403) {
                    this.handleInvalidToken();
                } else {
                    this.setState({
                        loading: false
                    });
                    this.showErrorDialog(null, error.message);
                }
            });
    }

    pubblicaRevisioneManuale = () => {
        this.setState({
            loading: true,
            openAlertReparti: false
        });
        pubblicaManuale(this.state.record.manuale.id)
            .then(result => {
                this.setState({
                    showPubblicatoMsg: true,
                    saveButtonEnabled: false,
                    loading: false
                });
                this.fetchRevisione();
            })
            .catch(error => {
                if (error.status === 403) {
                    this.handleInvalidToken();
                } else {
                    this.setState({
                        loading: false
                    });
                    this.showErrorDialog(null, error.message);
                }
            });
    }

    onChangeData = event => {
        if (!this.state.saveButtonEnabled) {
            this.setState({
                saveMessageVisible: false,
                saveButtonEnabled: true
            });
        }
        const name = event.target.name;
        const value = event.target.value;

        if (name === "esercizioCommercialeId") {
            // Before setting the new esercizio, show an alert to user 
            // to ask to remove responsabili associated to reparti:
            this.setState({
                loading: true
            });
            getReparti(this.state.record.manuale.id, 0, 500, "id")
                .then(result => {
                    let assignedResponsabili = false;
                    for (let i = 0; i < result.length; i++) {
                        if (result[i].responsabileSicurezzaId !== null) {
                            assignedResponsabili = true;
                            break;
                        }
                    }
                    if (assignedResponsabili) {
                        this.setState({
                            loading: false
                        });
                        const title = "ATTENZIONE";
                        const errorMessage = "Prima di cambiare l'esercizio associato al manuale rimuovere tutti i responsabili sicurezza associati ai reparti nel paragrafo dedicato alla descrizione dei reparti.";
                        this.showErrorDialog(title, errorMessage);
                    } else {
                        // Reparti are not associated to responsabili, it is possible to change esercizio:
                        let esercizioid = value;
                        let responsabileId = null;
                        if (esercizioid !== null) {
                            for (let i = 0; i < this.state.esercizi.length; i++) {
                                if (this.state.esercizi[i].id === esercizioid) {
                                    responsabileId = this.state.esercizi[i].responsabileSicurezzaId;
                                    break;
                                }
                            }
                        }
                        this.setState({
                            loading: false,
                            record: { ...this.state.record, esercizioCommercialeId: esercizioid, responsabileSicurezzaId: responsabileId }
                        });
                    }
                })
                .catch(error => {
                    if (error.status === 403) {
                        this.handleInvalidToken();
                    } else {
                        this.setState({
                            loading: false
                        });
                        this.showErrorDialog(null, 'Si è verificato un errore. Riprovare più tardi.');
                    }
                });

        } else {
            let data = Object.assign({}, this.state.record);
            let manuale = Object.assign({}, data.manuale);
            if (name === 'indiceRevisione' || name === 'motivoRevisione') {
                data[name] = value;
            } else if (name === 'titolo' || name === 'regolamenti') {
                manuale[name] = value;
            }
            data.manuale = manuale;
            this.setState({
                record: data
            });
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        let data = this.state.record;
        if (data.esercizioCommercialeId === '') {
            data.esercizioCommercialeId = null;
        }
        this.setState({
            record: data,
            loading: true,
        });
        this.updateRevisione();
    }

    onPubblicaBtnClicked = () => {
        let errorMessage = null;
        let title = null;
        if (this.state.saveButtonEnabled) {
            title = "Avviso";
            errorMessage = "Salvare le modifiche prima di procedere.";
        } else if (this.state.record.esercizioCommercialeId === null) {
            errorMessage = "Associare un esercizio commerciale al manuale.";
        } else if (this.state.record.motivoRevisione === null || this.state.record.motivoRevisione.length === 0) {
            errorMessage = "Inserire il motivo della revisione.";
        } else if (this.state.record.indiceRevisione === null || this.state.record.indiceRevisione.length === 0) {
            errorMessage = "Inserire il numero della revisione.";
        } else if (this.state.checkbox !== true) {
            errorMessage = "Accettare le condizioni d'uso";
        } else if (this.state.record.manuale.titolo.length === 0) {
            errorMessage = "Inserire il titolo del manuale.";
        } else if (this.state.record.manuale.regolamenti.length === 0) {
            errorMessage = "Inserire i regolamenti del manuale.";
        }
        if (errorMessage !== null) {
            this.showErrorDialog(title, errorMessage);
        } else {
            this.checkResponsabiliAssegnatiAReparti();
        }
    }

    checkResponsabiliAssegnatiAReparti = () => {
        this.setState({
            loading: true,
        });
        getResponsabiliSicurezzaPerEsercizioDatoManualeId(this.state.record.manuale.id)
            .then(result => {
                this.checkReparti(result);
            }).catch(error => {
                if (error.status === 403) {
                    this.handleInvalidToken();
                }
                this.setState({
                    loading: false
                });
                this.showErrorDialog(null, 'Si è verificato un errore. Riprovare più tardi.');
            });
    }

    checkReparti = (responsabiliEsercizio) => {
        getReparti(this.state.record.manuale.id, 0, 500, "id")
            .then(result => {
                // For each responsabile of the esercizio, 
                //check if is associated to a reparto in manuale:
                let noAssignedResponsabile = false;
                for (let i = 0; i < responsabiliEsercizio.length; i++) {
                    let assigned = false;
                    let currentResponsabileId = responsabiliEsercizio[i].id;
                    for (let j = 0; j < result.length; j++) {
                        if (result[j].responsabileSicurezzaId === currentResponsabileId) {
                            assigned = true;
                            break;
                        }
                    }
                    if (!assigned) {
                        noAssignedResponsabile = true;
                        break;
                    }
                }
                if (noAssignedResponsabile) {
                    this.setState({
                        loading: false,
                        openAlertReparti: true
                    });
                } else { // Each responsabile is assigned to a reparto.
                    this.pubblicaRevisioneManuale();
                }
            })
            .catch(error => {
                if (error.status === 403) {
                    this.handleInvalidToken();
                } else {
                    this.setState({
                        loading: false
                    });
                    this.showErrorDialog(null, 'Si è verificato un errore. Riprovare più tardi.');
                }
            });
    }

    handleModalIndietroButtonClicked = () => {
        this.setState({
            openAlertReparti: false
        });
    }

    onEliminaButtonClicked = () => {
        this.setState({
            twoActionsAlertVisible: true
        });
    }

    closeTwoActionsAlert = () => {
        this.setState({
            twoActionsAlertVisible: false
        });
    }

    handleCancellaManuale = () => {
        this.setState({
            loading: true,
            twoActionsAlertVisible: false
        });
        this.deleteRevisioneManuale();
    }

    closeAlertOnDeleteSuccessful = () => {
        this.setState({
            alertVisible: false,
            record: null,
            loading: false
        });
    }

    handlecheck = () => {
        this.setState({ checkbox: !this.state.checkbox })
    }

    render() {
        const disableSubmit = !this.state.saveButtonEnabled;
        let disablePubblicaButton = true;
        let disableForm = true;
        if (this.state.record !== null) {
            disableForm = this.state.record.stato === 'PUBBLICATO' ? true : false;
            disablePubblicaButton = this.state.record.stato === 'PUBBLICATO' ? true : false
        }
        return (
            <Fragment>
                <div style={styles.root} >
                    <Typography variant="h4" >Dettagli manuale di sicurezza</Typography>
                        <Container id="maincontainer" style={styles.maincontainer} >
                            {this.state.loading ?
                                <SpinnerComponent size={24} />
                                :
                                (this.state.record === null ?
                                    <Typography variant="h6" style={{ paddingTop: '20px', paddingBottom: '20px' }} >Nessun record trovato</Typography>
                                    :
                                    <Fragment>
                                        <Grid id="buttonsContainer" container justify="center" style={{ paddingTop: '20px', paddingBottom: '20px' }} >
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'right' }} >
                                                <div>
                                                    <Button
                                                        type="button"
                                                        onClick={this.onEliminaButtonClicked}
                                                        variant="contained"
                                                        size="medium"
                                                        disabled={this.state.record.stato === 'PUBBLICATO' ? true : false}
                                                        style={{
                                                            color: theme.palette.secondary.main,
                                                            margin: '10px',
                                                            borderRadius:30,
                                                            backgroundColor: this.state.record.stato === 'PUBBLICATO' ? theme.palette.disabled.main : theme.palette.utilPalette.secondary,
                                                        }}
                                                    >
                                                        Elimina bozza
                                                    </Button>
                                                    <GoToPaginaCostruzioneManuale
                                                        manualeId={this.state.record.manuale.id}
                                                        disabled={this.state.record.stato === 'PUBBLICATO' ? true : false}
                                                    />
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <FormManuale
                                            record={this.state.record}
                                            esercizi={this.state.esercizi}
                                            onChange={this.onChangeData}
                                            onSubmit={this.handleSubmit}
                                            disabledForm={disableForm}
                                            disabledSubmitButton={disableSubmit}
                                            saveSuccessMessageVisible={this.state.saveMessageVisible}
                                        />
                                        <Grid container justify="center" alignItems="center" style={styles.sectioncontainer}>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: '6px' }}>
                                                <Grid container justify="center" alignItems="center">
                                                    <Typography variant="body1" style={styles.text} >
                                                        Cliccare sul pulsante PUBBLICA per pubblicare il manuale.
                                                    </Typography>
                                                    <Typography variant="body1" style={styles.text} >
                                                        Dopo questa operazione non sarà più possibile modificarne il contenuto.
                                                    </Typography>
                                                    {this.state.consulenteid !== null && this.state.consulenteid !== 'null' ?
                                                        <div>
                                                            <Checkbox defaultChecked onClick={this.handlecheck} color="primary" value={this.state.checkbox} />
                                                            <a
                                                                href='https://bsfood.biorsaf.it/condizioni-generali-uso-BS-FOOD.pdf'
                                                                target='_blank'
                                                                rel="noopener noreferrer"
                                                            >
                                                                Dichiaro di aver letto e di accettare le Condizioni d’Uso di BS-UTILITY
                                                            </a>
                                                        </div> : null}

                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: '10px' }}>
                                                    <Grid container justify="center" alignItems="center">
                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                                            <Button
                                                                type="button"
                                                                onClick={this.onPubblicaBtnClicked}
                                                                variant="contained"
                                                                size="medium"
                                                                disabled={disablePubblicaButton}
                                                                style={{
                                                                    color: theme.palette.secondary.main,
                                                                    margin: '10px',
                                                                    borderRadius:30,
                                                                    backgroundColor: disablePubblicaButton ? theme.palette.disabled.main : theme.palette.utilPalette.primary,
                                                                }}
                                                            >
                                                                PUBBLICA
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                                            <Typography
                                                                variant="body1"
                                                                style={{
                                                                    color: theme.palette.utilPalette.primary,
                                                                    visibility: this.state.showPubblicatoMsg ? 'inherit' : 'hidden'
                                                                }}
                                                            >
                                                                Manuale pubblicato con successo.
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid container justify="flex-end" alignItems="center" style={{ paddingTop: '6px' }}>
                                            <IndietroButton isDisabled={false} alert={false} />
                                        </Grid>

                                    </Fragment>
                                )
                            }
                        </Container>
                </div>
                <ErrorDialog
                    open={this.state.errorDialogVisible}
                    title={this.state.errorDialogTitle}
                    message={this.state.errorDialogMessage}
                    onCloseButtonClicked={this.closeErrorDialog}
                />
                <TwoActionsAlertDialog
                    visible={this.state.twoActionsAlertVisible}
                    title="Cancellazione bozza"
                    message="Cliccare su OK per confermare la richiesta di cancellazione oppure Indietro per annullare la richiesta."
                    onIndietroClicked={this.closeTwoActionsAlert}
                    onOkClicked={this.handleCancellaManuale}
                />
                <AlertDialog
                    isOpen={this.state.alertVisible}
                    title="Operazione completata"
                    message="Manuale eliminato con successo."
                    onButtonClicked={this.closeAlertOnDeleteSuccessful}
                />
                {this.state.openAlertReparti &&
                    <ModalAvvisoReparti
                        open={this.state.openAlertReparti}
                        onIndietroSelected={this.handleModalIndietroButtonClicked}
                        onOkSelected={this.pubblicaRevisioneManuale}
                    />
                }
            </Fragment>
        );
    }

}
export default withRouter(DettaglioManualeSicurezza);

DettaglioManualeSicurezza.propTypes = {
    revisioneManualeId: PropTypes.string,
    gruppoId: PropTypes.number,
    consulenteId: PropTypes.number,
    superconsulenteView: PropTypes.bool.isRequired
}
