import React from "react";
import { Box,Grid, Icon, Typography } from "@material-ui/core";
import { CancelRounded, Edit,EditOffIcon } from "@material-ui/icons";
import {Button,TextField} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from '@material-ui/core/IconButton';
const styles = {
    header:{
        textAlign:"left",
        fontSize:30,
        marginLeft:"0%",
        display:"flex",
        flexDirection:"row"
    },
    textBox:{
        display:"flex",
        marginLeft:"0%",
        flexDirection:"column"
    }
}

class NewSottoparagrafoTesto extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            titoloInizialeSottoparagrafo:props.titoloInizialeSottoparagrafo,
            testoSottoparagrafo:props.testoSottoParagrafo,
            isTitleEditable:false,
        }
    }

    componentDidMount(){
        
    }

    render(){
        return(
            <div style={{margin: 35,marginLeft:"25%",textAlign: "center",color: "black",paddingBottom: "1%",height:this.props.componentHeight,width:this.props.componentWidth}}>
            <div style={styles.header}>
            <TextField InputProps={{ disableUnderline: true,style:{fontSize:30} }} InputLabelProps={{style: {fontSize: 30}}} fullWidth disabled={!this.state.isTitleEditable} onInput={(e) => {
                this.setState({
                titoloInizialeSottoparagrafo:e.target.value,
              })}} 
              onBlur={() => {
                                  this.props.bringTitleToParent(this.state.titoloInizialeSottoparagrafo,this.props.indexValue);
              }}
              value={this.state.titoloInizialeSottoparagrafo}></TextField>
              {!this.state.isTitleEditable ? (<IconButton><Edit onClick={() => {this.setState(
                {
                  isTitleEditable:!this.state.isTitleEditable
                }
              )}}></Edit></IconButton>) : (
                <IconButton><Edit onClick={() => {
                    this.setState({
                        isTitleEditable:!this.state.isTitleEditable
                    })
                }}></Edit></IconButton>
              )}
              {this.props.index !== 0 && <IconButton onClick={() => {
                this.props.rimuoviSottoparagrafo(this.props.indexValue);
              }}><CancelRounded style={{color:"red"}}></CancelRounded></IconButton>}
              </div>
              <div style={styles.textBox}>
              <TextField
                                label="Inserire il testo..."
                                value={this.state.testoSottoparagrafo}
                                name="contenuto"
                                InputProps={{style:{  borderRadius:30,}}}
                                onChange={(e) => { 
                                    this.setState({
                                        testoSottoparagrafo:e.currentTarget.value,
                                    })
                                 }}
                                 onBlur = {() => {
                                  this.props.bringDataToParent(this.state.testoSottoparagrafo,this.props.indexValue);
                                 }}
                                multiline
                                rows={10}
                                rowsMax={15}
                                fullWidth
                                disabled={false}
                                style={styles.textfield}
                ></TextField>
              </div>
            </div>
        )
    }
}

export default NewSottoparagrafoTesto;