import React from 'react';
import { Component } from 'react';
import { Fragment } from 'react';
import { TableCell, TableRow, Checkbox, IconButton,TextField } from '@material-ui/core';
import { ExpandLess, ExpandMore, Delete, Edit } from '@material-ui/icons';
import moment from 'moment';
import { TableHead, TableBody } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { Table } from '@material-ui/core';
import SottoparagrafoParti from './SottoparagrafoParti';
import Add from '@material-ui/icons/Add';
import {Collapse} from '@material-ui/core';
import { Box } from "@material-ui/core";
import { putCapitoli } from '../../../utils/api/capitoli_api';
import ParagrafoParti from './ParagrafoParti';


class CapitoloParti extends Component{
    constructor(props){
        super(props);
        this.state ={
            titoloParte:this.props.item.titolo,
            isTitoloParteEditable:this.props.item.isTitoloEditable,
            isTabOpen:this.props.item2.isTabOpen,
            isDataEditable:this.props.item2.isDataEditable,
            titoloCapitolo:this.props.item2.titoloCapitolo,
        }
    }

    shouldComponentUpdate(nextProps,nextState){
      if(this.state.titoloParte !== this.props.item.titolo){
        return false;
      }
      return true;
    }
    UNSAFE_componentWillReceiveProps(nextProps){
      if(nextProps.item.titolo !== this.state.titoloParte){
        this.setState({
          titoloParte:nextProps.item.titolo
        })
      }
    }

    handleParagrafoOpening = () => {
        this.setState({
            isTabOpen:!this.state.isTabOpen
        })
    }

    render(){
        return <Fragment key={this.props.index2}>
        <div style={{marginBottom:50}}>
        <TableHead>
    <TableRow>
    <TableCell style={{border:0}} align="center"><IconButton size="medium" onClick = {() => {
        this.handleParagrafoOpening();
        }}>{this.state.isTabOpen ? <ExpandMore htmlColor="#194074"></ExpandMore> : <ChevronRight htmlColor="#194074"></ChevronRight>}</IconButton></TableCell>
    <TableCell style={{border:"2px solid lightgray" ,borderTop:"2px solid lightgray",borderBottom:"2px solid lightgray",borderLeft:"2px solid lightgray",borderRight:"2px solid lightgray"}} align="center">Capitolo</TableCell>
    <TableCell style={{border:"2px solid lightgray",borderTop:"2px solid lightgray",borderBottom:"2px solid lightgray",borderLeft:0}} align="center">Titolo</TableCell>
    <TableCell style={{border:"2px solid lightgray",borderTop:"2px solid lightgray",borderBottom:"2px solid lightgray",borderLeft:0,width:"4.5%"}} align="center">Base&nbsp;</TableCell>
    <TableCell style={{border:"2px solid lightgray",borderTop:"2px solid lightgray",borderBottom:"2px solid lightgray",borderLeft:0}} align="center">SV</TableCell>
    <TableCell style={{border:"2px solid lightgray",borderTop:"2px solid lightgray",borderBottom:"2px solid lightgray",borderLeft:0,width:"10%"}} align="center">Da editare&nbsp;</TableCell>
    <TableCell style={{border:"2px solid lightgray",borderTop:"2px solid lightgray",borderBottom:"2px solid lightgray",borderLeft:0}} align="center">Data&nbsp;</TableCell>
    <TableCell style={{border:"2px solid lightgray",borderTop:"2px solid lightgray",borderBottom:"2px solid lightgray",borderLeft:0,width:"1%"}} align="center">Modifica Contenuto</TableCell>
    <TableCell style={{border:0}}></TableCell>
    </TableRow>
    </TableHead>
    <TableBody>                  
        <TableRow key={this.props.item2.id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell style={{border:0}} align="center">{<Checkbox onClick={() => {
          this.props.handleAggiuntaAlManualeCapitolo(this.props.index,this.props.index2);
          /*let manualeData = this.state.manualeData;
          manualeData.parti[this.props.index].capitoli[this.props.index2].isSelectedForPDF = !manualeData.parti[this.props.index].capitoli[this.props.index2].isSelectedForPDF;
          this.setState({
            manualeData:manualeData,
          })*/
        }} value={this.props.item2.isSelectedForPDF}></Checkbox>}</TableCell>
        <TableCell style={{border:"2px solid lightgray" ,borderTop:"2px solid lightgray",borderBottom:"2px solid lightgray",borderLeft:"2px solid lightgray",borderRight:"2px solid lightgray"}} align="center" component="th" scope="row">
        {this.props.item2.posizione}
        </TableCell>
        <TableCell style={{border:"2px solid lightgray",borderLeft:0,width:200}} align="center">{<TextField onInput = {(e) => {
            this.setState({
                titoloCapitolo:e.target.value
            })
            this.props.handleCambioTitoloCapitolo(e,this.props.index,this.props.index2);
          /*let manualeData = this.state.manualeData;
          manualeData.parti[this.props.index].capitoli[this.props.index2].titolo = e.target.value;
          this.setState({
            manualeData:manualeData,
          },() => {
            
          })*/
        }} onBlur={() => {
          this.props.saveCapitoloData(this.props.item,this.props.item2,this.props.index,this.props.index2);
        }} inputProps={{style: {textAlign:"center", color:"black"}}} InputProps={{ disableUnderline: true}} disabled={!this.state.isDataEditable} value={this.props.item2.titolo}></TextField>}</TableCell>
        <TableCell style={{border:"2px solid lightgray",borderLeft:0}} align="center">{this.props.item2.base}</TableCell>
        <TableCell style={{border:"2px solid lightgray",borderLeft:0}} align="center">{this.props.item2.sv}</TableCell>
        <TableCell style={{border:"2px solid lightgray",borderLeft:0}} align="center">{this.props.item2.daEditare ? "Si" : "No"}</TableCell>
        <TableCell style={{border:"2px solid lightgray",borderLeft:0}} align="center">{moment(this.props.item2.dataEditing).format("DD/MM/YYYY")}</TableCell>
        <TableCell style={{border:"2px solid lightgray",borderLeft:0}} align="center">{<IconButton disabled={this.props.activateMovement} size="small" onClick={() => {
          this.setState({
            isDataEditable:!this.state.isDataEditable
          })
          /*let manualeData = this.state.manualeData;
          manualeData.parti[index].capitoli[index2].isDataEditable = !manualeData.parti[index].capitoli[index2].isDataEditable;
          this.setState({
            manualeData:manualeData,
          })*/
        }}>{this.state.isDataEditable ? <Edit htmlColor="#194074"></Edit> : <Edit></Edit>}</IconButton>}</TableCell>
        <TableCell style={{border:0}}>
        <div style={{display:"flex",flexDirection:"row"}}>
        <IconButton size="medium" disabled = {this.props.activateMovement} onClick = {() => {
          this.props.handleAddParagrafo(this.props.item2,this.props.index,this.props.index2);
        }}><Add htmlColor={this.props.activateMovement ? '#e0e0e0' :"#194074"}></Add></IconButton>
        <div style={{display:"flex",flexDirection:"column"}}>
        <IconButton disabled={!this.props.activateMovement} size="small" onClick = {() => {
          this.props.handleCapitoloMovementUpward(this.props.index,this.props.index2);
        }}><ExpandLess htmlColor={this.props.activateMovement ? "#194074" : '#e0e0e0'}></ExpandLess></IconButton>
        <IconButton disabled={!this.props.activateMovement} size="small" onClick = {() => {
         this.props.handleCapitoloMovementDownward(this.props.index,this.props.index2);
        }}><ExpandMore htmlColor={this.props.activateMovement ? "#194074" : '#e0e0e0'}></ExpandMore></IconButton>
        </div>
        <IconButton size="medium" disabled={this.props.activateMovement} onClick = {() => {
            this.props.handleDeleteCapitolo(this.props.index,this.props.index2);
        }}><Delete htmlColor={this.props.activateMovement ? '#e0e0e0' :"#194074"}></Delete></IconButton>
        </div>
        </TableCell>
        </TableRow>
        <TableRow>
        <TableCell style={this.props.item2.isTabOpen && this.props.item2.paragrafi.length > 0 ? { paddingBottom: 10, paddingTop: 0,borderBottom:0,borderTop:0 } : {paddingBottom:0,paddingTop:0,borderBottom:0,borderTop:0}} colSpan={10}>
        <Collapse style={{
        marginLeft: "14.6%"
      }} in = {this.state.isTabOpen}>
        <Box>
        <Table style={{
        //borderCollapse: 'separate',
        //borderSpacing: '0px 20px'
      }} aria-label="paragrafi">
    {this.props.item2.paragrafi.map((item3,index3) => {
      return <ParagrafoParti
      key={index3}
      item3={item3}
      index3={index3}
      index={this.props.index}
      index2={this.props.index2}
      titoloCapitolo={this.state.titoloCapitolo}
      activateMovement={this.props.activateMovement}
      handleParagrafoDelete={this.props.handleParagrafoDelete}
      handleParagrafoMovementUpward={this.props.handleParagrafoMovementUpward}
      handleParagrafoMovementDownward={this.props.handleParagrafoMovementDownward}
      handleSottoparagrafoDelete={this.props.handleSottoparagrafoDelete}
      handleSottoparagrafoMovementDownward={this.props.handleSottoparagrafoMovementDownward}
      handleSottoparagrafoMovementUpward={this.props.handleSottoparagrafoMovementUpward}
      handleAddSottoparagrafo={this.props.handleAddSottoparagrafo}
      handleParagrafoRedirect={this.props.handleParagrafoRedirect}
      handleAggiuntaAlManualeParagrafo={this.props.handleAggiuntaAlManualeParagrafo}
      handleAggiuntaAlManualeSottoparagrafo={this.props.handleAggiuntaAlManualeSottoparagrafo}
      ></ParagrafoParti>
    })}
        </Table>
        </Box>
        </Collapse>
        </TableCell>
        </TableRow>
        </TableBody>
        </div>
        </Fragment>
    }

}
export default CapitoloParti;