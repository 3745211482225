import React from 'react';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import IconButton from "@material-ui/core/IconButton";
import Settings from '@material-ui/icons/Settings';
import Save from '@material-ui/icons/Save';
import HighlightOff from '@material-ui/icons/HighlightOff';

import CustomTable from "../components/CustomTable";
import ModificaModelloManualeButton from '../components/ModificaModelloManualeButton';

import moment from 'moment';
import 'moment/locale/it';

import PropTypes from 'prop-types';
import theme from '../../../theme.js';

const styles = {
    root: {
        //border: '1px solid #e0e0e0',
        //borderRadius: '20px',
        margin: '30px 0px',
        padding: '10px 10px',
    },
    formcontrol: {
        marginBottom: '30px',
        width: '136px',
    },
}

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: '146px',
            width: '136px',
        },
    },
};

const dateTimeFormat = "DD-MM-YYYY HH:mm";

export default function TabellaModelliGruppo(props) {

    const renderEditable = (record, fieldName) => {
        if (props.edit === true && record.id === props.selectedRecordId) {
            return (
                <div
                    style={{ backgroundColor: "#fafafa" }}
                    contentEditable
                    suppressContentEditableWarning
                    onBlur={e => { props.onEditedCell(record, fieldName, e.target.textContent) }}
                >
                    {record[fieldName].trim().length === 0 ? "" : record[fieldName]}
                </div>
            );
        }
        return (<div>{record[fieldName]}</div>);
    }

    const columns = [
        {
            Header: 'ID',
            id: 'id',
            accessor: d => d.id,
            width: 182,
        },
        {
            Header: 'ID modello di origine',
            id: 'manualeBaseId',
            accessor: d => d.manualeBaseId,
            width: 196,
        },
        {
            Header: 'Data ultima modifica',
            id: 'dataUltimoAggiornamento',
            sortable: false,
            filterable: false,
            accessor: d => d.dataUltimoAggiornamento ? moment(d.dataUltimoAggiornamento).locale("it").format(dateTimeFormat) : '-',
            width: 196,
        },
        {
            Header: 'Titolo',
            id: 'titolo',
            accessor: d => d,
            minWidth: 120,
            Cell: propCell => renderEditable(propCell.value, 'titolo')
        },
        {
            Header: 'Regolamenti',
            id: 'regolamenti',
            accessor: d => d,
            minWidth: 120,
            Cell: propCell => renderEditable(propCell.value, 'regolamenti')
        },
        {
            Header: 'Stato',
            id: 'stato',
            sortable: false,
            filterable: false,
            resizable: false,
            accessor: d => d,
            width: 152,
            Cell: propCell => {
                if (propCell.value.stato === 'PUBBLICATO') {
                    return (<div>PUBBLICATO</div>);
                } else {
                    return (
                        <FormControl
                            style={styles.formcontrol}
                        >
                            <Select
                                value={propCell.value.stato}
                                name="stato"
                                disabled={props.edit === true}
                                onChange={ (event) => { props.onStatoChanged(propCell.value, event) }}
                                MenuProps={MenuProps}
                            >
                                <MenuItem value='PUBBLICATO'>PUBBLICATO</MenuItem>
                                <MenuItem value='BOZZA'>BOZZA</MenuItem>
                            </Select>
                        </FormControl>
                    );
                }
            }
        },
        {
            id: 'modifica',
            Header: 'Modifica',
            sortable: false,
            filterable: false,
            resizable: false,
            accessor: d => d,
            width: 80,
            Cell: propCell => {
                if (propCell.value.stato === 'BOZZA') {
                    return (
                        <IconButton 
                            style={{ color: theme.palette.utilPalette.secondary }} 
                            size="small"
                            onClick={(e) => { props.edit && propCell.value.id === props.selectedRecordId ? props.onSave(propCell.value, e) : props.onEdit(propCell.value, e) }} 
                        >
                            {props.edit && propCell.value.id === props.selectedRecordId ? <Save/> : <Settings />}
                        </IconButton>
                    );
                } else {
                    return (
                        <IconButton size="small" disabled >
                            <Settings />
                        </IconButton>
                    );
                }
            }
        },
        {
            id: 'modificaContenuto',
            Header: 'Modifica contenuto',
            sortable: false,
            filterable: false,
            resizable: false,
            width: 170,
            accessor: d => d,
            Cell: propCell => 
                <ModificaModelloManualeButton 
                    modelloId={propCell.value.id} 
                    disabled={propCell.value.stato === 'PUBBLICATO' ? true : false} 
                />
        },
        {
            id: 'elimina',
            Header: 'Elimina',
            sortable: false,
            filterable: false,
            resizable: false,
            accessor: d => d,
            width: 80,
            Cell: propsCell =>
                <IconButton 
                    style={{ color: theme.palette.utilPalette.secondary }}
                    size="small" 
                    onClick={(e) => props.onElimina(propsCell.value, e)}
                >
                    <HighlightOff />
                </IconButton>
        }
    ];
    return (
        <Container maxWidth={false} style={styles.root}>
            <CustomTable 
                columns={columns}
                records={props.modelli}
                loading={false}
                filterable={true}
                resizable={true}
                sortable={true}
                defaultPageSize={5}
            />
        </Container>
    );
}

TabellaModelliGruppo.propTypes = {
    modelli: PropTypes.array.isRequired,
    selectedRecordId: PropTypes.number,
    edit: PropTypes.bool.isRequired,
    onStatoChanged: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onEditedCell: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onElimina: PropTypes.func.isRequired,
}