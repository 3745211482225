import React, { Component } from 'react';
import Button from "@material-ui/core/Button";

// React-table library requires to import css as well:
import ReactTable from 'react-table';
import 'react-table/react-table.css'; // eslint-disable-next-line

import SpinnerComponent from '../../../components/SpinnerComponent';
import ErrorDialog from '../../../components/ErrorDialog';
import EditRecordButton from '../components/EditRecordButton';
import NewRecordButton from '../components/NewRecordButton';
import SelezioneGruppo from '../components/SelezioneGruppo';
import ModalResponsabiliEsercizio from '../components/ModalResponsabiliEsercizio';

import { clearToken } from '../../../utils/storage.js';
import { getEserciziCommerciali } from '../../../utils/api/esercizi_commerciali_api';
import { getResponsabiliSicurezzaEsercizio } from '../../../utils/api/responsabili_sicurezza_api';
import { getGruppi } from '../../../utils/api/gruppi_api';

import theme from '../../../theme.js';

import PropTypes from 'prop-types';

const styles = {
  mainContainer: {
    textAlign: 'center',
    color: 'black'
  },
  vediButton: {
    color: theme.palette.secondary.main,
    padding: '0px',
    margin: '0px',
    borderRadius:30,
    backgroundColor: theme.palette.utilPalette.secondary,
  }
};

export default class EserciziCommercialiTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      esercizi: [],
      gruppoId: null,
      gruppi: [],
      loading: true,
      errorDialogVisible: false,
      errorDialogMessage: '',
      isModalResponsabiliOpen: false,
      selectedEsercizioName: "",
      responsabiliEsercizio: [],
      loadingModalResponsabili: false,
      errorModalResponsabili: "",
    };
  }

  componentDidMount() {
    if (this.props.consulenteId === null) { //User is Admin
      this.fetchGruppi();
    } else {
      let id = this.props.gruppoId;
      this.setState({
        gruppoId: id,
      });
      this.fetchEserciziCommerciali(id);
    }
  }

  handleInvalidToken = () => {
    this.handleError(true, 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.');;
    clearToken();
    window.setTimeout(function () {
      window.location.href = "/";
    }, 4000);
  }

  handleError = (showModal, errorMessage) => {
    this.setState({
      errorDialogVisible: showModal,
      errorDialogMessage: errorMessage
    });
  }

  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false
    });
  }

  fetchGruppi = () => {
    this.setState({
      loading: true,
    });
    getGruppi(null, null, 0, 1000, "id")
      .then(result => {
        let newarray = [{ id: -1, nome: "Tutti i gruppi" }];
        newarray.push(...result);
        let gruppoId = this.state.gruppoId;
        if (gruppoId === null) {
          gruppoId = -1;
        }
        this.setState({
          gruppi: newarray,
          gruppoId,
        });
        this.fetchEserciziCommerciali(gruppoId);
      })
      .catch(error => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
          });
          this.handleError(true, error.message);
        }
      });
  }

  fetchEserciziCommerciali = (gruppoId) => {
    let consulenteId = null;
    if (!(this.props.superconsulenteView) && this.props.consulenteId !== null) {
      consulenteId = this.props.consulenteId;
    }
    if (this.props.consulenteId !== null) {
      gruppoId = this.props.gruppoId;
    }
    getEserciziCommerciali(gruppoId, consulenteId, null, 0, 2000, "id")
      .then(result => {
        this.setState({
          esercizi: result,
          loading: false
        });
      })
      .catch(error => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
          });
          this.handleError(true, error.message);
        }
      });
  }

  handleSelectedGruppo = (gruppoId) => {
    this.setState({
      loading: true,
      gruppoId
    });
    this.fetchEserciziCommerciali(gruppoId);
  }

  getColumnsForTable = () => {
    let isSuperConsulente = this.props.superconsulenteView;
    let isAdmin = !this.props.superconsulenteView && this.props.consulenteId === null;
    if (isAdmin) {
      return [
        {
          Header: 'Numero',
          id: 'numero',
          accessor: row => row,
          width: 120,
          Cell: props => props.index
        },
        {
          Header: 'ID esercizio',
          id: 'id',
          accessor: d => d.id,
          filterable: true,
          width: 120,
        },
        {
          Header: 'ID gruppo',
          id: 'gruppoId',
          accessor: d => d.gruppoId,
          filterable: true,
          minWidth: 80,
        },
        {
          Header: 'ID consulente',
          id: 'consulenteId',
          accessor: d => d.consulenteId,
          filterable: true,
          minWidth: 100,
        },
        {
          Header: 'Responsabili',
          id: 'responsabili',
          accessor: row => row,
          sortable: false,
          resizable: false,
          filterable: false,
          width: 120,
          Cell: props =>
            <Button
              type="button"
              onClick={(e) => { this.onVediButtonClicked(props.value, e) }}
              variant="contained"
              size="small"
              disabled={false}
              style={styles.vediButton}
            >
              {"VEDI"}
            </Button>
        },
        {
          Header: 'Ragione sociale',
          id: 'ragioneSociale',
          accessor: d => d.ragioneSociale,
          filterable: true,
          minWidth: 180,
        },
        {
          Header: 'Partita IVA',
          id: 'partitaIva',
          accessor: d => d.partitaIva,
          filterable: true,
          minWidth: 120,
        },
        {
          Header: 'Telefono',
          id: 'telefono',
          accessor: d => d.telefono,
          filterable: true,
          width: 180
        },
        {
          Header: 'Sede',
          id: 'cittaSede',
          accessor: d => d.cittaSede,
          filterable: true,
          minWidth: 180,
        },
        {
          id: 'editBtn',
          Header: 'Modifica',
          accessor: row => row,
          sortable: false,
          resizable: false,
          filterable: false,
          width: 80,
          Cell: props =>
            <EditRecordButton recordId={props.value.id} disabled={false} />
        }
      ];
    } else if (isSuperConsulente) {
      return [
        {
          Header: 'Numero',
          id: 'numero',
          accessor: row => row,
          minWidth: 120,
          Cell: props => props.index
        },
        {
          Header: 'ID esercizio',
          id: 'id',
          accessor: d => d.id,
          filterable: true,
          width: 120,
        },
        {
          Header: 'ID consulente',
          id: 'consulenteId',
          accessor: d => d.consulenteId,
          filterable: true,
          minWidth: 100,
        },
        {
          Header: 'Responsabili',
          id: 'responsabili',
          accessor: row => row,
          sortable: false,
          resizable: false,
          filterable: false,
          width: 120,
          Cell: props =>
            <Button
              type="button"
              onClick={(e) => { this.onVediButtonClicked(props.value, e) }}
              variant="contained"
              size="small"
              disabled={false}
              style={styles.vediButton}
            >
              {"VEDI"}
            </Button>
        },
        {
          Header: 'Ragione sociale',
          id: 'ragioneSociale',
          accessor: d => d.ragioneSociale,
          filterable: true,
          minWidth: 180,
        },
        {
          Header: 'Partita IVA',
          id: 'partitaIva',
          accessor: d => d.partitaIva,
          filterable: true,
          minWidth: 120,
        },
        {
          Header: 'Telefono',
          id: 'telefono',
          accessor: d => d.telefono,
          filterable: true,
          width: 180
        },
        {
          Header: 'Sede',
          id: 'cittaSede',
          accessor: d => d.cittaSede,
          filterable: true,
          minWidth: 180,
        },
        {
          id: 'editBtn',
          Header: 'Modifica',
          accessor: row => row,
          sortable: false,
          filterable: false,
          width: 80,
          Cell: props =>
            <EditRecordButton recordId={props.value.id} disabled={false} />
        }
      ];
    }
    return [
      {
        Header: 'Numero',
        id: 'numero',
        accessor: row => row,
        minWidth: 120,
        Cell: props => props.index
      },
      {
        Header: 'ID esercizio',
        id: 'id',
        accessor: d => d.id,
        filterable: true,
        width: 120,
      },
      {
        Header: 'Responsabili',
        id: 'responsabili',
        accessor: row => row,
        sortable: false,
        resizable: false,
        filterable: false,
        width: 120,
        Cell: props =>
          <Button
            type="button"
            onClick={(e) => { this.onVediButtonClicked(props.value, e) }}
            variant="contained"
            size="small"
            disabled={false}
            style={styles.vediButton}
          >
            {"VEDI"}
          </Button>
      },
      {
        Header: 'Ragione sociale',
        id: 'ragioneSociale',
        accessor: d => d.ragioneSociale,
        filterable: true,
        minWidth: 180,
      },
      {
        Header: 'Partita IVA',
        id: 'partitaIva',
        accessor: d => d.partitaIva,
        filterable: true,
        minWidth: 120,
      },
      {
        Header: 'Telefono',
        id: 'telefono',
        accessor: d => d.telefono,
        filterable: true,
        width: 180
      },
      {
        Header: 'Sede',
        id: 'cittaSede',
        accessor: d => d.cittaSede,
        filterable: true,
        minWidth: 180,
      },
      {
        id: 'editBtn',
        Header: 'Modifica',
        accessor: row => row,
        sortable: false,
        filterable: false,
        width: 80,
        Cell: props =>
          <EditRecordButton recordId={props.value.id} disabled={false} />
      }
    ];

  }

  onVediButtonClicked = (esercizio, event) => {
    let nomeEsercizio = "";
    if (esercizio.nome !== null) {
      nomeEsercizio = esercizio.nome;
    }
    this.setState({
      selectedEsercizioName: nomeEsercizio,
      isModalResponsabiliOpen: true,
      loadingModalResponsabili: true
    });
    getResponsabiliSicurezzaEsercizio(esercizio.id)
      .then(result => {
        this.setState({
          selectedEsercizioName: nomeEsercizio,
          responsabiliEsercizio: result,
          loadingModalResponsabili: false,
          errorModalResponsabili: ""
        });
      })
      .catch(error => {
        if (error.status === 403) {
          this.setState({
            isModalResponsabiliOpen: false,
          });
          this.handleInvalidToken();
        } else {
          this.setState({
            loadingModalResponsabili: false,
            errorModalResponsabili: error.message
          });
        }
      });
  }

  closeModalResponsabili = () => {
    this.setState({
      isModalResponsabiliOpen: false
    });
  }

  render() {
    const isAdmin = this.props.consulenteId === null && !this.props.superconsulenteView;
    const columns = this.getColumnsForTable();
    return (
      <div style={{ paddingTop: "30px", minHeight: '400px' }}>
        {this.state.loading ?
          <SpinnerComponent size={24} />
          :
          <div style={styles.mainContainer}>
            {isAdmin ?
              <SelezioneGruppo
                gruppoId={this.state.gruppoId}
                gruppi={this.state.gruppi}
                disabled={false}
                description="Selezionare il gruppo su cui filtrare i risultati:"
                onGruppoSelected={this.handleSelectedGruppo}
                onGruppoAdded={this.fetchGruppi}
                onError={this.handleError}
                onAuthError={this.handleInvalidToken}
              />
              : null
            }
            <div style={{ paddingTop: '10px', paddingBottom: '10px', textAlign: 'right' }}>
              <NewRecordButton disabled={false} />
            </div>
            <ReactTable
              filterable={false}
              resizable={true}
              sortable={false}
              showPageSizeOptions={true}
              showPageJump={true}
              defaultPageSize={10}
              //pages={this.state.pages}
              columns={columns}
              data={this.state.esercizi}
              defaultFilterMethod={(filter, row,column) => {
                const id = filter.pivotId || filter.id;
                return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
              }}
              //manual // informs React Table that you'll be handling sorting and pagination server-side
              //onFetchData={ (state, instance) => { this.fetchData(state.page, state.pageSize) } }
              previousText='Precedente'
              nextText='Successivo'
              noDataText='Nessun record'
              pageText='Pagina'
              ofText='di'
              rowsText='righe'
              pageJumpText='Vai a pagina'
              rowsSelectorText='righe per pagina'
            />
          </div>
        }
        <ErrorDialog
          open={this.state.errorDialogVisible}
          message={this.state.errorDialogMessage}
          onCloseButtonClicked={this.closeErrorDialog}
        />
        <ModalResponsabiliEsercizio
          open={this.state.isModalResponsabiliOpen}
          loading={this.state.loadingModalResponsabili}
          onCloseModal={this.closeModalResponsabili}
          nomeEsercizio={this.state.selectedEsercizioName}
          responsabili={this.state.responsabiliEsercizio}
          errorLabel={this.state.errorModalResponsabili}
        />
      </div>
    );
  }

}

EserciziCommercialiTable.propTypes = {
  consulenteId: PropTypes.number,
  gruppoId: PropTypes.number,
  superconsulenteView: PropTypes.bool.isRequired
}