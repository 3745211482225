import React from "react";
import Button from "@material-ui/core/Button";

import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';

import theme from '../../../theme';

export default function GoToButton(props) {
  const history = useHistory();
  return (
    <Button
        type="button"
        variant="contained"
        size="medium"
        disabled={props.disabled}
        style={{
            color: theme.palette.secondary.main, 
            borderRadius:30,
            margin: '10px',
            float:props.isRight ? 'right' : 'none',
            backgroundColor: props.disabled ? 
                theme.palette.disabled.main
                :
                (props.grayVersion? theme.palette.secondary.other : props.isGreen ? theme.palette.utilPalette.secondary : theme.palette.utilPalette.primary)
        }}
        onClick={() => {
            if (props.showAlert === true) {
                var r = window.confirm("Modifiche non salvate. Lasciando la pagina verranno persi tutti i dati inseriti. Procedere comunque?");
                if (r === true) {
                    history.push(props.path);
                }
            } else {
                history.push(props.path);
            }
        }}
    >
    {props.label}
    </Button>
);
}

GoToButton.propTypes = {
    showAlert: PropTypes.bool.isRequired,
    path: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,   
    disabled: PropTypes.bool.isRequired,
    grayVersion: PropTypes.bool.isRequired,
}