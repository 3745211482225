import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import PropTypes from 'prop-types';

import moment from 'moment';
import 'moment/locale/it';

import theme from '../../../theme.js';
import { InputBase, styled } from "@material-ui/core";

const styles = {
    sectioncontainer: {
        border: '1px solid #e0e0e0',
        borderRadius: '20px',
    },
    textfieldscontainer: {
        marginTop: '10px',
        marginBottom: '10px',
        paddingTop: '10px',
        paddingBottom: '10px'
    },
    textfield: {
        marginLeft: '20px',
        marginRight: '20px',
        marginBottom: '12px',
        width: '325px'
    },
    formcontrol: {
        marginBottom: '30px',
        width: '325px'
    },
}

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: '190px',
            width: 250
        },
    },
};

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "& .MuiInputBase-input": {
      background:
        "linear-gradient(white, white) padding-box, linear-gradient(to right,#194074, #438c5a,#194074) border-box;",
      borderRadius: "50em",
      border: "1px solid transparent",
      paddingTop: "10px",
      paddingLeft:15
    },
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  }));

export default function FormManuale(props) {

    const dateFormat = "DD-MM-YYYY HH:mm";
    return (
        <form noValidate autoComplete="off" onSubmit={props.onSubmit}>
            <Grid container justify="center" alignItems="center">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.sectioncontainer}>
                    <Grid container style={styles.textfieldscontainer}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Grid container direction="column" alignItems="center" style={{ paddingBottom: '10px' }} >
                                <TextField
                                    label="ID manuale"
                                    disabled
                                    name='id'
                                    value={props.record.manuale.id}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    style={styles.textfield}
                                />
                                <TextField
                                    label="ID manuale base"
                                    disabled
                                    name='manualeBaseId'
                                    value={props.record.manuale.manualeBaseId === null ? " - " : props.record.manuale.manualeBaseId}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    style={styles.textfield}
                                />
                                <TextField
                                    label="ID revisione"
                                    disabled
                                    name='id'
                                    value={props.record.id}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    style={styles.textfield}
                                />
                                <TextField
                                    label="ID consulente"
                                    disabled
                                    name='consulenteId'
                                    value={props.record.consulenteId === null ? "" : props.record.consulenteId}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    style={styles.textfield}
                                />
                                <TextField
                                    label="Data creazione manuale"
                                    disabled
                                    name='dataCreazione'
                                    value={props.record.manuale.dataCreazione === null ? "" : moment(props.record.manuale.dataCreazione).format(dateFormat)}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    style={styles.textfield}
                                />
                                <TextField
                                    label="Data aggiornamento manuale"
                                    disabled
                                    name='dataAggiornamento'
                                    value={props.record.manuale.dataUltimoAggiornamento === null ? "" : moment(props.record.manuale.dataUltimoAggiornamento).format(dateFormat)}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    style={styles.textfield}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Grid container direction="column" alignItems="center" style={{ paddingBottom: '10px' }} >
                                <TextField
                                    label="Titolo del manuale"
                                    name='titolo'
                                    value={props.record.manuale.titolo}
                                    onChange={props.onChange}
                                    style={styles.textfield}
                                    disabled={props.disabledForm}
                                />
                                <TextField
                                    label="Regolamenti"
                                    name='regolamenti'
                                    value={props.record.manuale.regolamenti}
                                    onChange={props.onChange}
                                    style={styles.textfield}
                                    disabled={props.disabledForm}
                                />
                                <TextField
                                    label="Numero di revisione"
                                    name="indiceRevisione"
                                    value={props.record.indiceRevisione}
                                    onChange={props.onChange}
                                    style={styles.textfield}
                                    disabled={props.disabledForm}
                                />
                                <TextField
                                    label="Motivo della revisione"
                                    value={props.record.motivoRevisione}
                                    name="motivoRevisione"
                                    onChange={props.onChange}
                                    style={styles.textfield}
                                    disabled={props.disabledForm}
                                />
                                <TextField
                                    label="Stato Pubblicazione"
                                    disabled
                                    name='stato'
                                    value={props.record.stato}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    style={styles.textfield}
                                />
                                <Typography 
                                variant="body2" 
                                style={{ width: '250px', padding: '20px 20px 12px 20px' }} >
                                    Esercizio commerciale:
                                </Typography>
                                <FormControl style={styles.formcontrol}>
                                    <Select
                                        input={<BootstrapInput></BootstrapInput>}
                                        value={props.record.esercizioCommercialeId === null? "" : props.record.esercizioCommercialeId}
                                        name="esercizioCommercialeId"
                                        onChange={props.onChange}
                                        MenuProps={MenuProps}
                                        disabled={props.disabledForm}
                                    >
                                        <MenuItem value={''} >
                                            <em>Selezionare un esercizio</em>
                                        </MenuItem>
                                        {props.esercizi.map(esercizio => 
                                            <MenuItem 
                                                key={esercizio.id} 
                                                value={esercizio.id}
                                            >
                                                {esercizio.nome === null ? "" : esercizio.nome}
                                            </MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container justify="center" alignItems="center" style={{ padding: '10px 0px 0px 0px'}} >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                    <Typography
                        variant="body1"
                        style={{
                            color: theme.palette.utilPalette.primary,
                            //visibility: props.saveSuccessMessageVisible ? 'inherit' : 'hidden'
                            display: props.saveSuccessMessageVisible ? 'inherit' : 'none'
                        }}
                    >
                        Dati aggiornati con successo.
                        </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'right' }} >
                    <Button
                        type="button"
                        onClick={props.onSubmit}
                        variant="contained"
                        size="medium"
                        disabled={props.disabledSubmitButton}
                        style={{
                            color: theme.palette.secondary.main,
                            margin: '10px',
                            borderRadius:30,
                            backgroundColor: props.disabledSubmitButton ? theme.palette.disabled.main : theme.palette.utilPalette.primary,
                        }}
                    >
                        Salva modifiche
                    </Button>
                </Grid>
            </Grid>
        </form>
    );

}

FormManuale.propTypes = {
    record: PropTypes.object.isRequired,
    esercizi: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    disabledForm: PropTypes.bool.isRequired,
    disabledSubmitButton: PropTypes.bool.isRequired,
    saveSuccessMessageVisible: PropTypes.bool.isRequired,
}