import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
import { Button } from "@material-ui/core";

import theme from '../theme.js';

export default function AquistaButton(props) {
  let history = useHistory();
  return (
    <Button
      type="button"
      variant="contained"

      onClick={() => {     
        history.push(`/abbonamenti?id=${props.abbonamentoId}`);
      }}
      size="medium"
      style={{
        color: theme.palette.secondary.main,
        borderRadius:30,
        backgroundColor: theme.palette.utilPalette.secondary
      }}
    >
    Acquista
  </Button>
  );
}

AquistaButton.propTypes = {
  abbonamentoId: PropTypes.number.isRequired,
}