import React, { Component, Fragment } from "react";
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Box from '@material-ui/core/Box';

import ActionButton from "../../../components/ActionButton";
import ComponenteSelezioneBrand from "./ComponenteSelezioneBrand";
import SpinnerComponent from '../../../components/SpinnerComponent';
import ModalWithTextfield from '../components/ModalWithTextfield';
import ErrorDialog from '../../../components/ErrorDialog';
import GoToButton from '../components/GoToButton';
import Background from '../../../background.png';
import Background2 from '../../../background2.png';

import { createBrand, getBrands } from '../../../utils/api/brand_api';
import { registerGruppo } from '../../../utils/api/gruppi_api';
import { clearToken } from '../../../utils/storage';

const styles = {
    root: {
        textAlign: 'center',
        color: 'black',
        paddingTop: '26px',
        minHeight: '86vh',
    },
    texts: {
        marginTop:20,
        marginBottom: '20px'
    },
    sectioncontainer: {
        border: '1px solid #e0e0e0',
        borderRadius: '20px',
        marginTop: '10px',
        marginBottom: '10px',
        paddingTop: '10px',
        paddingBottom: '40px',
        width:"50%",
        alignSelf:"center"
    },
    textfield: {
        marginLeft: '20px',
        marginRight: '20px',
        marginBottom: '12px',
        width:"35%"
    },
    buttonContainer: {
        margin: '38px 8px 8px 8px',
    },
}

export default class NuovoGruppoPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            record: {
                id: null,
                nome: '',
            },
            brand: {
                id: 8780600,
                nome: 'BS-UTILITY'
            },
            validationErrors: {
                nome: '',
                brandId: ''
            },
            brands: [],
            newBrandSaved: false,
            changesNotSaved: false,
            disableSaveButton: true,
            registraBrandModalOpen: false,
            errorDialogVisible: false,
            errorDialogTitle: null,
            errorDialogMessage: '',
            recordSaved: false,
            width:window.innerWidth,
            height:window.innerHeight,
        };
    }
    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
      };
      componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
      }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
        this.fetchBrands();
    }

    fetchBrands = () => {
        getBrands(0, 1000, "id")
        .then(result => {
            this.setState({
                loading: false,
                brands: result
            });
            if (this.state.newBrandSaved) {
                this.setState({
                    newBrandSaved: false
                });
                this.displayMessage('Operazione effettuata', "Il nuovo brand è stato registrato con successo.");
            }
        })
        .catch(error => {
            this.setState({
                loading: false,
            });
            if (error.status === 403) {
                this.handleInvalidToken();
            } else {
                this.openErrorDialog(error.message);
            } 
        });
    }

    openErrorDialog = (errorMessage) => {
        this.setState({
            errorDialogTitle: null,
            errorDialogVisible: true,
            errorDialogMessage: errorMessage
        });
    }

    closeErrorDialog = () => {
        this.setState({
          errorDialogVisible: false
        });
    }

    handleInvalidToken = () => {
        const errorMessage = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
        this.openErrorDialog(errorMessage);
        clearToken();
        window.setTimeout(function () {
            window.location.href = "/";
        }, 4000);
    }

    // Using the ErrorDialog to display a message if operation is successful.
    displayMessage = (title, message) => {
        this.setState({
            errorDialogTitle: title,
            errorDialogVisible: true,
            errorDialogMessage: message
        });
    }

    validateForm = () => {
        this.validateField('nome', this.state.record.nome);
        this.validateField('brandId', this.state.brand.id);
        let hasErrors = false;
        let validationErrors = this.state.validationErrors;
        Object.values(validationErrors).forEach(val => {
            hasErrors = hasErrors || (val.length > 0 ? true : false);
        });
        return hasErrors;
    }

    validateField = (key, value) => {
        let validationErrors = this.state.validationErrors;
        switch (key) {
            case "nome":
                if (value === null || value.length === 0) {
                    validationErrors.nome = "Inserire almeno un carattere.";
                } else {
                    validationErrors.nome = "";
                }
                break;
            case "brandId":
                if (value === null || value === -1 || value === undefined) {
                    validationErrors.brandId = "Selezionare un brand";
                } else {
                    validationErrors.brandId = "";
                }
                break;
            default:
                break;
        }
        this.setState({
            validationErrors
        });
    }

    onTextChanged = (event) => {
        let record = this.state.record;
        record.nome = event.target.value;
        //Reset validation :
        let validationErrors = this.state.validationErrors;
        validationErrors.nome = "";
        this.setState({
            record,
            validationErrors,
            changesNotSaved: true,
            disableSaveButton: false
        });
    }

    onBrandSelected = (event) => {
        if (event.target.value === '') {
            this.setState({
                registraBrandModalOpen: true
            });
        } else {
            let selectedBrand = {};
            for (let i=0; i<this.state.brands.length; i++) {
                if (this.state.brands[i].id === event.target.value) {
                    selectedBrand = Object.assign({}, this.state.brands[i]);
                    break;
                }
            }
            let validationErrors = this.state.validationErrors;
            validationErrors.brandId = "";
            this.setState({
                brand: selectedBrand,
                validationErrors,
                changesNotSaved: true,
                disableSaveButton: false
            });
        }
    }

    onSubmit = () => {
        const invalidForm = this.validateForm();
        if (invalidForm) {
            this.setState({
                disableSaveButton: true
            });
        } else {
            this.setState({
                loading: true
            });
            registerGruppo(this.state.record.nome, this.state.brand)
            .then(result => {
                let record = result;
                this.setState({
                    record,
                    loading: false,
                    recordSaved: true,
                    disableSaveButton: true
                });
                this.displayMessage('Registrazione effettuata', "Il nuovo gruppo è stato registrato con successo.");
            })
            .catch(error => {
                this.setState({
                    loading: false
                });
                if (error.status === 403) {
                    this.handleInvalidToken();
                } else {
                    this.openErrorDialog(error.message);
                }
            });
        }
    }

    closeBrandModal = () => {
        this.setState({
            registraBrandModalOpen: false
        });
    }

    registerBrand = (inputValue) => {
        this.setState({
            registraBrandModalOpen: false,
            loading: true
        });
        createBrand(inputValue)
        .then(result => {
            this.setState({
                newBrandSaved: true
            });
            this.fetchBrands();
        })
        .catch(error => {
            this.setState({
                loading: false
            });
            if (error.status === 403) {
                this.handleInvalidToken();
            } else {
                this.openErrorDialog(error.message);
            }
        });
    }

    render () {
        return(
            <Fragment>
                <CssBaseline />
                <div style={{
                     textAlign: 'center',
        color: 'black',
        paddingTop: '26px',
        minHeight: '100vh',
        display:"flex",
        flexDirection:"column",
        backgroundImage: this.state.width > 1299  ? `url("${Background}"),url("${Background2}")` : `url("")`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '',
        backgroundAttachment: 'fixed',
        backgroundPositionY:"50%",
        backgroundPositionX:this.state.width > 1899 ? "110%, -10%" : this.state.width > 1799 ? "110%, -10%" : this.state.width > 1599 ? "110%, -10%" : this.state.width > 1399 ? "110%, -10%" : this.state.width > 1299 ? "120%, -20%" : null,
                }}>
                    <Typography variant="h4" style={styles.texts} >Registra nuovo gruppo</Typography>
                    { this.state.loading ?
                        <SpinnerComponent size={24} />
                    :
                    <Fragment>
                        <Grid container justify="center" alignItems="center" style={styles.sectioncontainer}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Typography variant="h5" style={{marginBottom: 20,marginTop:20}} >
                                    Nome gruppo
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Typography variant="body1" style={{marginTop: '5px',marginBottom:15, fontFamily: 'Verdana',}} >
                                    Inserisci il nome del nuovo gruppo:
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextField
                                    label="Nome gruppo"
                                    name="nome"
                                    value={this.state.record.nome}
                                    onChange={this.onTextChanged}
                                    disabled={this.state.recordSaved}
                                    style={styles.textfield}
                                    helperText={this.state.validationErrors.nome}
                                    error={this.state.validationErrors.nome.length > 0 ? true : false}
                                />
                            </Grid>
                        </Grid>
                        {/*<Grid container justify="center" style={styles.sectioncontainer} >
                        <ComponenteSelezioneBrand
                                    title="Brand"
                                    description="Selezionare il brand da associare al nuovo gruppo"
                                    brands={this.state.brands}
                                    brandId={this.state.brand.id}
                                    disabled={this.state.recordSaved}
                                    onSelectionChanged={this.onBrandSelected}
                                    validationMessage={this.state.validationErrors.brandId}
                                />
                        </Grid>*/}
                        <Grid container justify="center" style={{ paddingTop:'10px', paddingBottom:'10px',width:"50%",alignSelf:"center" }} >
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ }} >
                            <ActionButton 
                                    isRight
                                    label="Registra"
                                    disabled={!this.state.changesNotSaved || this.state.disableSaveButton}
                                    onClick={this.onSubmit}
                                    grayVersion={false}
                                />
                            <GoToButton
                                isRight
                                showAlert={this.state.changesNotSaved} 
                                path="/amministrazione/gruppi"
                                label="Indietro"
                                disabled={false}
                                isGreen
                                grayVersion={false}
                            />
                            </Grid>
                        </Grid>
                        {/*<Box p={1} style={styles.buttonContainer}>
                            <GoToButton
                                showAlert={this.state.changesNotSaved} 
                                path="/amministrazione/gruppi"
                                label="Torna alla tabella dei gruppi"
                                disabled={false}
                                grayVersion={false}
                            />
                            <GoToButton
                                showAlert={false}
                                path={"/amministrazione/brand/" + this.state.brand.id}
                                label="Gestisci i loghi del brand selezionato"
                                disabled={this.state.brand.id === null || this.state.brand.id === '' || this.state.brand.id === -1}
                                grayVersion={false}
                            />
                            <GoToButton
                                showAlert={false}
                                path={"/amministrazione/gruppi/" + this.state.record.id}
                                label="Vai alla pagina di modifica del gruppo"
                                disabled={!this.state.recordSaved}
                                grayVersion={false}
                            />
                        </Box>*/}
                    </Fragment> 
                    }
                    { this.state.registraBrandModalOpen ? 
                        <ModalWithTextfield
                            open={this.state.registraBrandModalOpen}
                            onClose={this.closeBrandModal}
                            onSubmit={this.registerBrand}
                            title="Crea un nuovo brand"
                            description="Inserisci il nome del nuovo brand:"
                            submitButtonLabel="Registra brand"
                            textfieldLabel="Nome del brand"
                        />
                        :
                        null            
                    }
                    <ErrorDialog
                        open={this.state.errorDialogVisible} 
                        title={this.state.errorDialogTitle}
                        message={this.state.errorDialogMessage} 
                        onCloseButtonClicked={this.closeErrorDialog} 
                    />
                </div>
            </Fragment>
        );
    }

}