import React, { Component, Fragment } from "react";
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import ActionButton from '../../../components/ActionButton';
import PropTypes from 'prop-types';

import theme from '../../../theme.js';

const styles = {
    modalcontainer: {
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 540,
        height: 264,
        textAlign: 'center',
        backgroundColor: 'white',
        color: 'black',
        border: '2px solid ' +  theme.palette.utilPalette.primary,
        boxShadow: 24,
        padding: '20px',
    },
    text: {
        padding: '0px 0px 10px 0px'
    },
    textfield: {
        marginLeft: '20px',
        marginRight: '20px',
        marginBottom: '12px',
        width: '360px'
    }
}

export default class ModalWithTextfield extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inputText: '',
            submitButtonDisabled: true,
        }
    }

    handleChange = (event) => {
        let newvalue = event.target.value;
        let btnDisabled = true;
        if (newvalue !== null && newvalue.length > 0) {
            btnDisabled = false;
        }
        this.setState({
            inputText: newvalue,
            submitButtonDisabled: btnDisabled,
        });
    }

    handleSubmit = (event) => {
        this.props.onSubmit(this.state.inputText);
    }

    render () {
        return(
            <Modal
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
                open={this.props.open}
                onClose={this.props.onClose}
            >
            <div style={styles.modalcontainer}>
                <Fragment>
                    <Typography variant="h6" style={styles.text} >
                        {this.props.title}
                    </Typography>
                    <Typography variant="body1" style={styles.text} >
                        {this.props.description}
                    </Typography>
                    <form 
                        autoComplete="off" 
                        onSubmit={this.handleSubmit}
                    >
                        <Grid container 
                            spacing={2}
                            style={{ padding: '10px 0px 0px 0px' }}
                        >
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextField
                                    label={this.props.textfieldLabel}
                                    value={this.state.inputText}
                                    name="inputText"
                                    onChange={this.handleChange}
                                    style={styles.textfield}
                                />
                            </Grid>
                        </Grid>
                        <Container style={{ textAlign: 'center' }}  >
                            <ActionButton
                                label="Indietro"
                                onClick={this.props.onClose}
                                disabled={false}
                                grayVersion={true}
                            />
                            <ActionButton
                                label={this.props.submitButtonLabel}
                                onClick={this.handleSubmit}
                                disabled={this.state.submitButtonDisabled}
                                grayVersion={false} 
                            />
                        </Container>
                    </form>
                </Fragment>
            </div>
        </Modal>
        );
    }
}

ModalWithTextfield.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    submitButtonLabel: PropTypes.string.isRequired,
    textfieldLabel: PropTypes.string.isRequired,
}