import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import PropTypes from 'prop-types';
import { InputBase, styled } from '@material-ui/core';

const styles = {
    root: {
        minHeight: '126px',
        margin: '10px 0px 10px 0px',
    },
    texts: {
        marginTop:10,
        marginBottom: '25px',
        fontFamily: 'Verdana',
    },
    formcontrol: {
        marginBottom: '30px',
        marginTop:10,
        width: '50%',
    },
}

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: '146px',
            width: '320px',
        },
    },
};
const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "& .MuiInputBase-input": {
      background:
        "linear-gradient(white, white) padding-box, linear-gradient(to right,#194074, #438c5a,#194074) border-box;",
      borderRadius: "50em",
      border: "1px solid transparent",
      paddingTop: "10px",
    },
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  }));

export default function ComponenteSelezioneBrand(props) {
    return (
        <Container style={styles.root}>
            <Typography variant="h5" style={styles.texts} >{props.title}</Typography>
            <Typography variant="body1" style={styles.texts} >
                {props.description}
            </Typography>
            <FormControl
                style={styles.formcontrol}
                error={props.validationMessage.length > 0 ? true : false}
            >
                <Select
                    value={props.brandId}
                    name="id"
                    onChange={props.onSelectionChanged}
                    disabled={props.disabled}
                    MenuProps={MenuProps}
                    input={<BootstrapInput></BootstrapInput>}
                >
                    <MenuItem disabled value={-1}>
                        <em>SELEZIONA UN BRAND</em>
                    </MenuItem>
                    <MenuItem value={''} >
                        <em>Crea un nuovo brand</em>
                    </MenuItem>
                    { props.brands.map((value, index) => 
                        (<MenuItem key={index} value={value.id}>{value.nome}</MenuItem>))
                    }
                </Select>
                {/* <FormHelperText>{props.validationMessage}</FormHelperText> */}
            </FormControl>
        </Container>
    );
}

ComponenteSelezioneBrand.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    brands: PropTypes.array.isRequired,
    brandId: PropTypes.number,
    disabled: PropTypes.bool.isRequired,
    validationMessage: PropTypes.string.isRequired,
    onSelectionChanged: PropTypes.func.isRequired,
}