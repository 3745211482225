import React from "react";
import { Container, Grid, IconButton, MenuItem, Modal, Select, TextField, Typography } from "@material-ui/core";
import ReactTable from "react-table";
import NonConformitaTable from "./NonConformitaTable";
import ControlliNonConformita from "../manualecomponents/dettaglio_procedura/ControlliNonConformita";
import ActionButton from "../../../components/ActionButton";
import { getLocaliManuale } from "../../../utils/api/locali_e_attrezzature_api";

const styles = {
    mainContainer: {
      textAlign: "center",
      color: "black",
      padding: "26px 10px",
      minHeight: "90vh",
    },
    typo: {
        align: "center",
    },
    modalcontainer: {
        flexDirection: "column",
        alignItems: 'center',
        justifyContent: 'space-evenly',
        textAlign: 'center',
        backgroundColor: 'white',
        color: 'black',
        //paddingTop: '26px',
        //paddingBottom: '26px',
        paddingTop: '6px',
        paddingBottom: '6px',
        paddingLeft: '10px',
        paddingRight: '10px',
        marginTop: '26px',
        marginBottom: '26px',
        //boxShadow: '0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px #194074',
        marginLeft: '20%',
        marginRight: '20%',
        //height: '85%'
        overflowY: 'scroll',
        height: '600px',
        display:"flex",
    },
    expandedModalcontainer: {
        flexDirection: "column",
        alignItems: 'center',
        justifyContent: 'space-evenly',
        textAlign: 'center',
        backgroundColor: 'white',
        color: 'black',
        //paddingTop: '26px',
        //paddingBottom: '26px',
        paddingTop: '6px',
        paddingBottom: '6px',
        paddingLeft: '10px',
        paddingRight: '10px',
        marginTop: '26px',
        marginBottom: '26px',
        //boxShadow: '0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px #194074',
        marginLeft: '20%',
        marginRight: '20%',
        //height: '85%'
        overflowY: 'auto',
        height: '900px',
        display:"flex",
    },
    miniModalContainer:{
      flexDirection: "column",
        alignItems: 'center',
        justifyContent: 'space-evenly',
        textAlign: 'center',
        backgroundColor: 'white',
        color: 'black',
        //paddingTop: '26px',
        //paddingBottom: '26px',
        paddingTop: '6px',
        paddingBottom: '6px',
        paddingLeft: '10px',
        paddingRight: '10px',
        marginTop: '26px',
        marginBottom: '26px',
       // boxShadow: '0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px #194074',
        marginLeft: '20%',
        marginRight: '20%',
        //height: '85%'
        overflowY: 'scroll',
        height: '300px',
        display:"flex",
    },
    modalsectioncontainer: {
        flex: 1,
        display: "flex",
        marginTop: 0,
        //marginBottom: 20,
        flexDirection: "column",
        justifyContent: 'space-evely',

    },
    buttoncontainer: {
      justifyContent: 'center',
      padding: '10px 0px',
    },
    modalTitle:{
      fontSize:20,
      alignSelf:"center",
    }
}

export default class ModalSelezioneLimiti extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            controlloconformita:this.props.controlloconformita,
            nuoviControlliNonConformita:this.props.controlloconformita.nonConformita,
            expandedAccordion:false,
            arrayTabella: [],
            temperaturaMinima:this.props.controlloconformita.temperaturaMinima,
            temperaturaMassima:this.props.controlloconformita.temperaturaMassima,
            temperaturaConsigliata:this.props.controlloconformita.temperaturaConsigliata,
            TPM_Minimo:this.props.controlloconformita.TPM_Minimo,
            TPM_Massimo:this.props.controlloconformita.TPM_Massimo,
            TPM_Standard:this.props.controlloconformita.TPM_Standard
        }
    }

    handleAccordionChange = (event) => {
        const flag = !this.state.expandedAccordion;
        this.setState({
          expandedAccordion: flag,
        });
      };

      createTableRecords = (controlliNonConformita) => {
        let arrayTabella = [];
        if (controlliNonConformita.length > 0) {
          let controlloNonConf = {};
          let index = 0;
          for (let i = 0; i < controlliNonConformita.length; i++) {
            if (controlliNonConformita[i].controlliCause.length === 0) {
              controlloNonConf = {
                indice: index,
                id: controlliNonConformita[i].id,
                controlloConformitaId: this.state.controlloconformita.id,
                limite: controlliNonConformita[i].limite,
                controlloCausaId: null,
                limiteCausa: null,
                azioneCorrettivaId: null,
                descrizioneAzione: null,
              };
              arrayTabella.push(controlloNonConf);
              index = index + 1;
            } else {
              let controllicause = controlliNonConformita[i].controlliCause;
              for (let j = 0; j < controllicause.length; j++) {
                if (controllicause[j].azioniCorrettive.length === 0) {
                  controlloNonConf = {
                    indice: index,
                    id: controlliNonConformita[i].id,
                    controlloConformitaId: this.state.controlloconformita.id,
                    limite: controlliNonConformita[i].limite,
                    controlloCausaId: controllicause[j].id,
                    limiteCausa: controllicause[j].limite,
                    azioneCorrettivaId: null,
                    descrizioneAzione: null,
                  };
                  arrayTabella.push(controlloNonConf);
                  index = index + 1;
                } else {
                  let azionicorrettive = controllicause[j].azioniCorrettive;
                  for (let k = 0; k < azionicorrettive.length; k++) {
                    controlloNonConf = {
                      indice: index,
                      id: controlliNonConformita[i].id,
                      controlloConformitaId: this.state.controlloconformita.id,
                      limite: controlliNonConformita[i].limite,
                      controlloCausaId: controllicause[j].id,
                      limiteCausa: controllicause[j].limite,
                      azioneCorrettivaId: azionicorrettive[k].id,
                      descrizioneAzione: azionicorrettive[k].descrizione,
                    };
                    arrayTabella.push(controlloNonConf);
                    index = index + 1;
                  }
                }
              }
            } //end else-if
          } //end loop on controlli non conformità
        } //end if
    
        return arrayTabella;
      };

      addControlloNonConformita = (nuovoControlloNonConformita) => {
        let arrayOfControlliNonConformita = [...this.state.nuoviControlliNonConformita];
        arrayOfControlliNonConformita.push(nuovoControlloNonConformita);
        this.setState({
          nuoviControlliNonConformita:arrayOfControlliNonConformita
        })
        let arrayTabella = this.state.arrayTabella.map(a => Object.assign({}, a));
        let index = arrayTabella.length;
        let controlloNonConf = {};
        if (nuovoControlloNonConformita.controlliCause.length === 0) {
          controlloNonConf = {
            indice: index,
            id: nuovoControlloNonConformita.id,
            controlloConformitaId: this.state.controlloconformita.id,
            limite: nuovoControlloNonConformita.limite,
            controlloCausaId: null,
            limiteCausa: null,
            azioneCorrettivaId: null,
            descrizioneAzione: null,
          };
          arrayTabella.push(controlloNonConf);
          index = index + 1;
        } else {
          let controllicause = nuovoControlloNonConformita.controlliCause.map(a => Object.assign({}, a));
          for (let j = 0; j < controllicause.length; j++) {
            if (controllicause[j].azioniCorrettive.length === 0) {
              controlloNonConf = {
                indice: index,
                id: nuovoControlloNonConformita.id,
                controlloConformitaId: this.state.controlloconformita.id,
                limite: nuovoControlloNonConformita.limite,
                controlloCausaId: null,
                limiteCausa: controllicause[j].limite,
                azioneCorrettivaId: null,
                descrizioneAzione: null,
              };
              arrayTabella.push(controlloNonConf);
              index = index + 1;
            } else {
              let azionicorrettive = controllicause[j].azioniCorrettive.map(a => Object.assign({}, a));
              for (let k = 0; k < azionicorrettive.length; k++) {
                controlloNonConf = {
                  indice: index,
                  id: nuovoControlloNonConformita.id,
                  controlloConformitaId: this.state.controlloconformita.id,
                  limite: nuovoControlloNonConformita.limite,
                  controlloCausaId: null,
                  limiteCausa: controllicause[j].limite,
                  azioneCorrettivaId: null,
                  descrizioneAzione: azionicorrettive[k].descrizione,
                };
                arrayTabella.push(controlloNonConf);
                index = index + 1;
              }
            }
          }
        } //end else-if
    
        this.setState({
          expandedAccordion: false,
          hasChanges: true,
          arrayTabella:arrayTabella,
        });
      };
      deleteControlloNonConformita = (record) => {
        let arrayTabella = [...this.state.arrayTabella];
        for (let i = 0; i < arrayTabella.length; i++) {
          if (arrayTabella[i].indice === record.indice) {
            arrayTabella.splice(i, 1);
          }
        }
        let newArrayOfControlliNonConformita = [...this.state.nuoviControlliNonConformita];
        let controlloIndex = null;
        let controlloCausaIndex = null;
        let azioneCorrettivaIndex = null;
        newArrayOfControlliNonConformita.map((item,index) => {
          if(record.id === item.id){
            controlloIndex = index;
            item.controlliCause.map((item2,index2) => {
              if(item2.id === record.controlloCausaId){
                controlloCausaIndex = index2;
                item2.azioniCorrettive.map((item3,index3) => {
                  azioneCorrettivaIndex = index3;
                })
              }
            })
          }
        })
        if(controlloIndex !== null && controlloCausaIndex !== null && azioneCorrettivaIndex !== null){
          newArrayOfControlliNonConformita[controlloIndex].controlliCause[controlloCausaIndex].azioniCorrettive.splice(azioneCorrettivaIndex,1);
        }
        if(controlloIndex !== null && controlloCausaIndex !== null && azioneCorrettivaIndex === null){
          newArrayOfControlliNonConformita[controlloIndex].controlliCause.splice(controlloCausaIndex,1);
        }
        if(controlloIndex !== null && controlloCausaIndex === null && azioneCorrettivaIndex !== null){
          newArrayOfControlliNonConformita.splice(controlloIndex,1);
        }
        if(controlloIndex !== null && controlloCausaIndex === null && azioneCorrettivaIndex === null){
          newArrayOfControlliNonConformita.splice(controlloIndex,1);
        }
        this.setState({
           nuoviControlliNonConformita:newArrayOfControlliNonConformita
        })

        if(arrayTabella.length === 0){
          this.setState({
            nuoviControlliNonConformita:[],
          })
        }
        this.setState({
          hasChanges: true,
          arrayTabella:arrayTabella,
          //nuoviControlliNonConformita:newArrayOfControlliNonConformita
        });
      };
    

      fetchLocali = (tipologiaStrumentazioneId) => {
        getLocaliManuale(this.props.manualeId, 0, 300, "indiceOrdinamento")
          .then((result) => {
            this.setState({
              locali: result,
            });
            this.fetchAttrezzatureForTipologia(tipologiaStrumentazioneId);
          })
          .catch((error) => {
            if (error.status === 403) {
              this.handleInvalidToken();
            }
            this.setState({
              loading: false,
              errorDialogVisible: true,
              errorDialogMessage: error.message,
            });
          });
      };

    componentDidMount = () => {
        let repartiControllo = [];
    let reparti = this.props.reparti;
    for (let i = 0; i < reparti.length; i++) {
      if (reparti[i].repartoDefault) {
        repartiControllo.push(reparti[i].id);
        break;
      }
    }
    let checkboxSelected = false;
    if (reparti.length === 1) {
      checkboxSelected = true;
    }
    let tipologieStrumentazioni = this.props.tipologieStrumentazioni;
    let controlloConformita = this.state.controlloconformita;
    if (this.props.controlloconformita === null) {
      controlloConformita.proceduraHaccpId = this.props.proceduraId;
      controlloConformita.reparti = repartiControllo;
      //controlloConformita.orarioNotifica = moment(controlloConformita.orarioNotifica, [moment.ISO_8601, 'HH:mm:ss']);
      this.setState({
        controlloConformita,
        tipologieStrumentazioni,
        isNewRecord: true,
        checkboxSelected,
      });
    } else {
      const arrayTabella = this.createTableRecords(
        this.props.controlloconformita.nonConformita
      );
      let repartiIsNullOrEmpty =
        this.props.controlloconformita.reparti === null ||
        this.props.controlloconformita.reparti === undefined ||
        this.props.controlloconformita.reparti.length === 0;
      controlloConformita = {
        indice: this.props.controlloconformita.indice,
        id: this.props.controlloconformita.id,
        proceduraHaccpId: this.props.controlloconformita.proceduraHaccpId,
        descrizioneOsservabile:
          this.props.controlloconformita.descrizioneOsservabile !== null
            ? this.props.controlloconformita.descrizioneOsservabile
            : "",
        categoriaControllo: this.props.controlloconformita.categoriaControllo !== null ? this.props.controlloconformita.categoriaControllo : "CONTROLLO_GENERICO",
        categoriaOsservabile: this.props.controlloconformita.categoriaOsservabile,
        tipologiaStrumentazioneId:
          this.props.controlloconformita.tipologiaStrumentazioneId === null
            ? -2
            : this.props.controlloconformita.tipologiaStrumentazioneId,
        cadenza:
          this.props.controlloconformita.cadenza === null
            ? "ALTRO"
            : this.props.controlloconformita.cadenza,
        altraCadenza: this.props.controlloconformita.altraCadenza,
        limiti:
          this.props.controlloconformita.limiti === null
            ? ""
            : this.props.controlloconformita.limiti,
        bloccante: this.props.controlloconformita.bloccante === null ? false : this.props.controlloconformita.bloccante,
        periodico: this.props.controlloconformita.periodico,
        controlliNonConformita: [],
        cadenze:  this.props.controlloconformita.cadenze === null ||
        this.props.controlloconformita.cadenze === undefined
          ? []
          : this.props.controlloconformita.cadenze,
        attrezzature:
          this.props.controlloconformita.attrezzature === null ||
          this.props.controlloconformita.attrezzature === undefined
            ? []
            : this.props.controlloconformita.attrezzature,
        reparti: repartiIsNullOrEmpty
          ? repartiControllo
          : this.props.controlloconformita.reparti,
        temperaturaMinima: this.props.controlloconformita.temperaturaMinima === null ? "" : this.props.controlloconformita.temperaturaMinima,
        temperaturaMassima:this.props.controlloconformita.temperaturaMassima === null ? "" : this.props.controlloconformita.temperaturaMassima,
        temperaturaConsigliata:this.props.controlloconformita.temperaturaConsigliata === null ? "" : this.props.controlloconformita.temperaturaConsigliata,
      };
      // if (controlloConformita.cadenza === null) {
      //     controlloConformita.cadenza = 'ALTRO';
      // }
      // if (controlloConformita.tipologiaStrumentazioneId === null) {
      //     controlloConformita.tipologiaStrumentazioneId = 1;
      // }
      // if (controlloConformita.orarioNotifica === null) {
      //     controlloConformita.orarioNotifica = '08:00:00';
      // }
      // if (controlloConformita.secondoOrarioNotifica === null) {
      //     controlloConformita.secondoOrarioNotifica = '18:00:00';
      // }
      // if (controlloConformita.giornoNotifica === null) {
      //     controlloConformita.giornoNotifica = 'MONDAY';
      // }
      this.setState({
        controlloConformita,
        tipologieStrumentazioni,
        isNewRecord: false,
        arrayTabella:arrayTabella,
        checkboxSelected,
      });
    }
    this.fetchLocali(controlloConformita.tipologiaStrumentazioneId);
    }

    render(){
        return(
            <Modal 
            open={this.props.open}
            onClose={this.props.onClose}>
                <div style={this.state.expandedAccordion ? styles.expandedModalcontainer : styles.modalcontainer}>
                {this.props.mode === "TPM" && <div style={styles.modalsectioncontainer}>
                <div style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                    <div style={{flexDirection:"row",display:"flex"}}>
                    <Typography style={{margin:10}}>Valore minimo di conformità</Typography><TextField style={{margin:10}} value={this.state.TPM_Minimo} onInput={(e) => {
                      const re = /^\d*(\.\d{0,2})?$/;
                      if (e.target.value === '' || re.test(e.target.value)) {
                      this.setState({
                        TPM_Minimo:e.target.value
                      })
                      }
                    }} size="small" type="number"></TextField><Typography style={{margin:10}}>g</Typography>
                    </div>
                    <div style={{flexDirection:"row",display:"flex"}}>
                    <Typography style={{margin:10}}>Valore massimo di conformità</Typography><TextField onInput={(e) => {
                       const re = /^\d*(\.\d{0,2})?$/;
                      if (e.target.value === '' || re.test(e.target.value)) {
                      this.setState({
                        TPM_Massimo:e.target.value
                      })
                      }
                    }} style={{margin:10}}  value={this.state.TPM_Massimo} size="small" inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}  type="number"  ></TextField><Typography style={{margin:10}}>g</Typography>
                    </div>
                    <div style={{flexDirection:"row",display:"flex",justifyContent:"center"}}>
                    <Typography style={{margin:10}}>Valore Standard</Typography><TextField style={{margin:10}} onInput={(e) => {
                      const re = /^\d*(\.\d{0,2})?$/;
                      if (e.target.value === '' || re.test(e.target.value)) {
                      this.setState({
                       TPM_Standard:e.target.value
                      })}
                    }} value={this.state.TPM_Standard}  type="number" size="small"  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}></TextField><Typography style={{margin:10}}>g</Typography>
                    </div>
                    </div>
                </div>}
                {this.props.mode === "CONTROLLO_TEMPERATURE" &&  <div style={styles.modalsectioncontainer}>
                   <div style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                    <div style={{flexDirection:"row",display:"flex"}}>
                    <Typography style={{margin:10}}>Temperatura minima di conformità</Typography><TextField style={{margin:10}} value={this.state.temperaturaMinima} onInput={(e) => {
                      const re = /^-?\d+$/;
                      if (e.target.value === '' || re.test(e.target.value)) {
                      this.setState({
                        temperaturaMinima:e.target.value
                      })
                      }
                    }} size="small" type="number"></TextField><Typography style={{margin:10}}>°C</Typography>
                    </div>
                    <div style={{flexDirection:"row",display:"flex"}}>
                    <Typography style={{margin:10}}>Temperatura massima di conformità</Typography><TextField onInput={(e) => {
                       const re = /^-?\d+$/;
                      if (e.target.value === '' || re.test(e.target.value)) {
                      this.setState({
                        temperaturaMassima:e.target.value
                      })
                      }
                    }} style={{margin:10}}  value={this.state.temperaturaMassima} size="small" inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}  type="number"  ></TextField><Typography style={{margin:10}}>°C</Typography>
                    </div>
                    <div style={{flexDirection:"row",display:"flex",justifyContent:"center"}}>
                    <Typography style={{margin:10}}>Temperatura Standard</Typography><TextField style={{margin:10}} onInput={(e) => {
                      const re = /^-?\d+$/;
                      if (e.target.value === '' || re.test(e.target.value)) {
                      this.setState({
                        temperaturaConsigliata:e.target.value
                      })}
                    }} value={this.state.temperaturaConsigliata}  type="number" size="small"  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}></TextField><Typography style={{margin:10}}>°C</Typography>
                    </div>
                    </div>
                    </div>}
                    <div style={{overflowY:"auto"}}>
                    <ControlliNonConformita
                    controlliNonConformita={this.state.arrayTabella}
                    controlloConformitaId={this.state.controlloconformita.id}
                    expandedAccordion={this.state.expandedAccordion}
                    onChangeAccordion={this.handleAccordionChange}
                    onAggiungiButtonClicked={this.addControlloNonConformita}
                    onDeleteButtonClicked={this.deleteControlloNonConformita}
                    />
                    </div>
                    <ActionButton label="Salva" onClick = {() => {
                    this.props.mode === "TPM" ? this.props.passLimitiTPM(this.state.nuoviControlliNonConformita,this.state.TPM_Minimo,this.state.TPM_Massimo,this.state.TPM_Standard) :
                    this.props.passLimiti(this.state.nuoviControlliNonConformita,this.state.temperaturaMinima,this.state.temperaturaMassima,this.state.temperaturaConsigliata)}}></ActionButton>
                </div>
            </Modal>
        )
    }
}