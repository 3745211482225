import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import PropTypes from 'prop-types';

import theme from '../../../theme.js';

const DialogTitle = withStyles(theme => ({
    root: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
  }))(props => {
    const { children, classes, onClose } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={onClose}
          >
            {" "}
            <CloseIcon />{" "}
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
});
  
const DialogContent = withStyles(theme => ({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    }
  }))(MuiDialogContent);
  
const DialogActions = withStyles(theme => ({
    root: {
      borderTop: `1px solid ${theme.palette.divider}`,
      margin: 0,
      padding: theme.spacing(2)
    }
  }))(MuiDialogActions);


export default function ModalAvvisoReparti (props) {
  return(
    <Dialog
        open={props.open}
        onClose={props.onIndietroSelected}
    >
      <DialogTitle >ATTENZIONE</DialogTitle>
      <DialogContent>
          <DialogContentText >
          Uno o più responsabili sicurezza dell'esercizio commerciale scelto non risultano associati ad un reparto.
          </DialogContentText>
          <DialogContentText >
          Senza un reparto assegnato, il responsabile sicurezza non potrà accedere ai controlli da effettuare tramite l'app mobile.
          </DialogContentText>
          <DialogContentText >
            Per sospendere l'operazione di pubblicazione e correggere il contenuto del paragrafo di definizione dei reparti cliccare su Indietro.
            Per pubblicare il manuale ignorando questo messaggio cliccare su "Pubblica comunque".
            {/* Nel paragrafo 4 del manuale sono presenti reparti a cui non è stato associato un responsabile sicurezza.
            Il responsabile sicurezza dell'esercizio commerciale a cui non è stato assegnato alcun reparto non potrà ricevere notifiche push né registrare i controlli effettuati tramite l'app mobile.
            Per sospendere l'operazione di pubblicazione e tornare a modificare il contenuto del manuale cliccare su Indietro.
            Altrimenti cliccare su "Pubblica comunque" per eseguire l'operazione di pubblicazione.  */}
          </DialogContentText>
      </DialogContent>
      <DialogActions>
          <Button 
            onClick={props.onIndietroSelected} 
            style={{ color: theme.palette.utilPalette.primary}}
          >
            Indietro
          </Button>
          <Button 
            onClick={props.onOkSelected} 
            style={{ color: theme.palette.utilPalette.primary}}
          >
            Pubblica comunque
          </Button>
      </DialogActions>
    </Dialog>
  );
}

ModalAvvisoReparti.propTypes = {
  open: PropTypes.bool.isRequired,
  onIndietroSelected: PropTypes.func.isRequired,
  onOkSelected: PropTypes.func.isRequired,
}