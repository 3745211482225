import React from 'react';
import { Component } from 'react';
import { Fragment } from 'react';
import { TableCell, TableRow, Checkbox, IconButton } from '@material-ui/core';
import { ExpandLess, ExpandMore, Delete, Edit } from '@material-ui/icons';
import moment from 'moment';
import { TableHead, TableBody } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { Table } from '@material-ui/core';
import SottoparagrafoParti from './SottoparagrafoParti';
import Add from '@material-ui/icons/Add';
import {Collapse} from '@material-ui/core';
import { Box } from "@material-ui/core";

class ParagrafoParti extends Component{
    constructor(props){
        super(props);
        this.state ={
            isTabOpen:this.props.item3.isTabOpen,
            titoloCapitolo:this.props.titoloCapitolo,
        }
    }

    handleParagrafoOpening = () => {
        this.setState({
            isTabOpen:!this.state.isTabOpen,
            titoloCapitolo:this.props.titoloCapitolo
        })
      }
      shouldComponentUpdate(nextProps,nextState){
        if(this.props.titoloCapitolo !== this.state.titoloCapitolo){
          return false;
        }
        return true;
      }

    render(){
        return <Fragment key = {this.props.index3}>
        <div style={{marginTop:50}}>
      <TableHead>
      <TableRow>
      <TableCell align="center" style={{border:0}}><IconButton size="medium" onClick = {() => {
          this.handleParagrafoOpening(this.props.index,this.props.index2,this.props.index3);
        }}>{this.state.isTabOpen ? <ExpandMore htmlColor="#194074"></ExpandMore> : <ChevronRight htmlColor="#194074"></ChevronRight>}</IconButton></TableCell>
      <TableCell style={{border:"2px solid lightgray" ,borderLeft:"2px solid lightgray",borderRight:"2px solid lightgray"}} align="center">Paragrafo</TableCell>
      <TableCell style={{border:"2px solid lightgray",borderLeft:0,width:"13%"}} align="center">Titolo</TableCell>
      <TableCell style={{border:"2px solid lightgray",borderLeft:0}} align="center">Base&nbsp;</TableCell>
      <TableCell style={{border:"2px solid lightgray",borderLeft:0}} align="center">SV</TableCell>
      <TableCell style={{border:"2px solid lightgray",borderLeft:0}} align="center">Da editare&nbsp;</TableCell>
      <TableCell style={{border:"2px solid lightgray",borderLeft:0}} align="center">Data&nbsp;</TableCell>
      <TableCell style={{border:"2px solid lightgray",borderLeft:0,width:"1%"}} align="center">Modifica Contenuto</TableCell>
      <TableCell style={{border:0}}></TableCell>
      </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
        <TableCell style={{border:"0"}} align="center"><Checkbox onClick = {() => {
          this.props.handleAggiuntaAlManualeParagrafo(this.props.index,this.props.index2,this.props.index3);
        }} value={this.props.item3.isSelectedForPDF}></Checkbox></TableCell>
          <TableCell style={{border:"2px solid lightgray" ,borderLeft:"2px solid lightgray",borderRight:"2px solid lightgray"}} align="center" component="th" scope="row">
          {this.props.item3.posizione}
          </TableCell>
          <TableCell style={{border:"2px solid lightgray",borderLeft:0}} align="center">{this.props.item3.titolo}</TableCell>
          <TableCell style={{border:"2px solid lightgray",borderLeft:0}} align="center">{this.props.item3.base}</TableCell>
          <TableCell style={{border:"2px solid lightgray",borderLeft:0}} align="center">{this.props.item3.sv}</TableCell>
          <TableCell style={{border:"2px solid lightgray",borderLeft:0}} align="center">{this.props.item3.daEditare ? "Si" : "No"}</TableCell>
          <TableCell style={{border:"2px solid lightgray",borderLeft:0}} align="center">{moment(this.props.item3.dataEditing).format("DD/MM/YYYY")}</TableCell>
          <TableCell style={{border:"2px solid lightgray",borderLeft:0}} align="center"><IconButton disabled={this.props.activateMovement} onClick = {() => {
            this.props.handleParagrafoRedirect(this.props.item3);
          }}><Edit htmlColor={this.props.activateMovement ? '#e0e0e0' :"#194074"}></Edit></IconButton></TableCell>
          <TableCell style={{border:0}}>
          <div style={{display:"flex",flexDirection:"row"}}>
          <IconButton size="medium" disabled={this.props.activateMovement} onClick = {() => {
            this.props.handleAddSottoparagrafo(this.props.item3,this.props.index,this.props.index2,this.props.index3);
          }}><Add htmlColor={this.props.activateMovement ? '#e0e0e0' :"#194074"}></Add></IconButton>
          <div style={{display:"flex",flexDirection:"column"}}>
          <IconButton size="small"  disabled={!this.props.activateMovement} onClick = {() => {
           this.props.handleParagrafoMovementUpward(this.props.index,this.props.index2,this.props.index3);
          }}><ExpandLess htmlColor={this.props.activateMovement ? "#194074" : '#e0e0e0'}></ExpandLess></IconButton>
          <IconButton  disabled={!this.props.activateMovement} onClick = {() => {
            this.props.handleParagrafoMovementDownward(this.props.index,this.props.index2,this.props.index3);
          }} size="small"><ExpandMore htmlColor={this.props.activateMovement ? "#194074" : '#e0e0e0'}></ExpandMore></IconButton>
          </div>
          <IconButton disabled={this.props.activateMovement} onClick = {() => {
            this.props.handleParagrafoDelete(this.props.item3,this.props.index,this.props.index2,this.props.index3);
          }} size="medium"><Delete htmlColor={this.props.activateMovement ? '#e0e0e0' :"#194074"}></Delete></IconButton>
          </div>
          </TableCell>
        </TableRow>
        <TableRow>
        <TableCell style={this.state.isTabOpen && this.props.item3.sottoparagrafi.length > 0 ? { paddingBottom: 10, paddingTop: 50,borderBottom:0,borderTop:0 } : {paddingBottom:0, paddingTop:0,borderBottom:0,borderTop:0}} colSpan={10}>
          <Collapse style={{
          marginLeft: "10%"
        }} in = {this.state.isTabOpen}>
          {this.props.item3.sottoparagrafi.length > 0 ? <Box sx={{ margin: 1 }}>
          <Table style={{
            
        }}   aria-label="sottoparagrafi">
          <TableHead>
      <TableRow>
      <TableCell style={{border:0}} align="center"></TableCell>
      <TableCell style={{border:"2px solid lightgray", borderRight:0,width:"15%"}} align="center">Sottoparagrafo</TableCell>
      <TableCell style={{border:"2px solid lightgray", borderRight:0,width:"35%"}} align="center">Titolo</TableCell>
      <TableCell style={{border:"2px solid lightgray", borderRight:0}} align="center">Base&nbsp;</TableCell>
      <TableCell style={{border:"2px solid lightgray", borderRight:0,width:"10%"}} align="center">Da editare&nbsp;</TableCell>
      <TableCell style={{border:"2px solid lightgray",width:"10%"}} align="center">Data&nbsp;</TableCell>
      <TableCell style={{border:"2px solid lightgray",width:"1%"}} align="center">Modifica Contenuto</TableCell>    
      <TableCell style={{border:0}}></TableCell>
      </TableRow>
      </TableHead>
      <TableBody>
      {this.props.item3.sottoparagrafi.map((item4,index4) => {
        return <SottoparagrafoParti
        key={index4}
        titolo={item4.titolo}
        base={item4.base}
        daEditare={item4.daEditare}
        data={item4.data}
        id={item4.id}
        isSelectedForPDF={item4.isSelectedForPDF}
        index={this.props.index}
        index2={this.props.index2}
        index3={this.props.index3}
        index4={index4}
        item3={this.props.item3}
        posizione={item4.posizione}
        handleSottoparagrafoDelete={this.props.handleSottoparagrafoDelete}
        handleSottoparagrafoMovementUpward={this.props.handleSottoparagrafoMovementUpward}
        handleSottoparagrafoMovementDownward={this.props.handleSottoparagrafoMovementDownward}
        activateMovement={this.props.activateMovement}
        handleAggiuntaAlManualeSottoparagrafo={this.props.handleAggiuntaAlManualeSottoparagrafo}
        handleParagrafoRedirect={this.props.handleParagrafoRedirect}
        >

        </SottoparagrafoParti>
      })}
      </TableBody>
      </Table>
      </Box> : null}
          </Collapse>
          </TableCell>
        </TableRow>
        </TableBody>
        </div>
        </Fragment>
    }
}
export default ParagrafoParti;