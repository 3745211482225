import React from "react";
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";

import theme from '../../../theme.js';

import PropTypes from 'prop-types';
import { getNumeroPartiManuale, getPartiManuale } from "../../../utils/api/parti_api.js";
import { getCapitoliCount } from "../../../utils/api/capitoli_api.js";

export default function GoToPaginaCostruzioneManuale(props) {
  let history = useHistory();
  return (
    <Button
      disabled={props.disabled}
      variant="contained"
      style={{
        color: theme.palette.secondary.main,
        borderRadius:30,
        backgroundColor: props.disabled ? theme.palette.disabled.main : theme.palette.utilPalette.primary,
        margin: '10px',
      }}
      onClick={() => { 
        getCapitoliCount(props.manualeId).then((result) => {
          if(result > 0){
            history.push("/amministrazione/manuali/nuovomanuale/" + props.manualeId + "/manuale/true");
          }
          else{
            history.push("/amministrazione/manuali/nuovomanuale/" + props.manualeId + "/manuale/false")
          }
        }).catch((error) => {
          console.log(error);
        })
        //history.push("/amministrazione/manuali/nuovomanuale/" + props.manualeId + "/manuale") 
      }}
    >
      Modifica contenuto
    </Button>
  );
}

GoToPaginaCostruzioneManuale.propTypes = {
  manualeId: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired
}